import React from 'react';

const PureArticle = ({ content, theme, bgColor, mergeHero }) => {
  return (
    <div className="pure-article-wrapper">
      {/* Title Element */}
      <div className="title-wrapper">
        <div className="decorated-title-wrapper col-12 col-md-10 col-lg-9 col-xl-10 offset-md-1 offset-lg-2">
          {content.decorImg ? (
            <div className="nml-10 mb-5 dsm-none">
              <img className="img-w" src={content.decorImg} alt="drop" />
            </div>
          ) : null}
          {content.decoratedTitle ? <h2 className={content.textLight ? 'heading-2 decorated text-light' : 'heading-2 decorated'}>{content.decoratedTitle}</h2> : null}
        </div>
        {content.regularTitle ? <h2 className={content.textLight ? 'heading-1 regular text-light' : 'heading-1 regular'}>{content.regularTitle}</h2> : null}
      </div>
      {/* Article Element */}
      <div className="article-wrapper">
        <div className="article-left-col col-12 col-md-6">
          {/* <div className="side-text-wrapper"> */}
            {content.sideText ? (
              <div className="side-text-wrapper">
                {content.sideText.map((p, i) => {
                  return (
                    <p key={i} className={content.textLight ? 'side-text-light' : 'side-text'}>
                      {p}
                    </p>
                  );
                })}
              </div>
            ) : null}
            {/* {content.sideText
              ? content.sideText.map((p, i) => (
                  <p key={i} className={content.textLight ? 'side-text-light' : 'side-text'}>
                    {p}
                  </p>
                ))
              : null} */}
          {/* </div> */}
          {content.pLeft
            ? content.pLeft.map((p, i) =>
                typeof p === 'string' ? (
                  <p key={i} className={content.textLight ? 'text-block-2 text-light' : 'text-block-2'}>
                    {p}
                  </p>
                ) : (
                  p
                )
              )
            : null}
        </div>
        {content.pRight ? (
          <div className="article-right-col col-12 col-md-6">
            {content.pRight.map((p, i) => {
              if (typeof p === 'string') {
                return (
                  <p key={i} className={content.textLight ? 'text-block-2 text-light' : 'text-block-2'}>
                    {p}
                  </p>
                );
              } else {
                return p;
              }
            })}
          </div>
        ) : null}
        {/* Tail Text*/}
        {content.tailText
          ? content.tailText.map((p, i) =>
              typeof p === 'string' ? (
                <p key={i} className={content.textLight ? 'text-block-2 text-light tail-text' : 'text-block-2 tail-text'}>
                  {p}
                </p>
              ) : (
                p
              )
            )
          : null}
      </div>
    </div>
  );
};

export default PureArticle;
