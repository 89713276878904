import { createGlobalStyle } from 'styled-components';
import device from './mediaSizes';

const NavPageLinks = createGlobalStyle`

  #our-company{
    top: 150px;
    @media ${device.sm}{
      top: -50px;
    }
  }

  #meet-the-team, 
  #write-us-a-message{
    top:-50px;
    @media ${device.sm}{
      top: -150px;
    }
  }
`;

export default NavPageLinks;
