import React from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

const ThankYouPage = ({ msg }) => {
  const scrollOptions = {
    animateIn: 'fadeInUp',
    animateOut: 'fadeInOut',
    animateOnce: true,
  };
  return (
    <div className='container d-flex justify-content-center align-items-center flex-column' style={{ height: '50vh', width: '90%', textAlign: 'center' }}>
      <ScrollAnimation {...scrollOptions}>
        <h1 className='heading-1'>Thank You!</h1>
      </ScrollAnimation>
      <ScrollAnimation {...scrollOptions}>
        <p className='text-block-2 mb-5'>
          {msg ? msg : 'Our patient service specialist team will be reaching out to you as soon as possible. If you have any questions please call us at +1 (863) 606-6655'}
        </p>
      </ScrollAnimation>
      <ScrollAnimation {...scrollOptions}>
        <Link className='btn-default' to='/'>
          Back to Homepage
        </Link>
      </ScrollAnimation>
    </div>
  );
};

export default ThankYouPage;
