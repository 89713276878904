import React from 'react';
import BuyTestBtn from './components/BuyTestBtn';
import { StyledChooseMethod } from './components/styles/StyledChooseMethod';
import Layout from '../../partials/Layout';
import PageScrollTop from '../../utils/PageScrollTop';

const ChooseMethod = () => {
  return (
    <Layout noFooter={true}>
      <PageScrollTop>
        <StyledChooseMethod>
          <div className="rn-section-gap no-footer" style={{ backgroundColor: '#f4f5f9' }}>
            <div className="container">
              <div className="row no-footer justify-content-center align-items-center">
                <div className="col-12 col-md-4 order-2 order-md-1">
                  <h1 className="heading-1">Order Tests</h1>
                  {/* <h2 className="title">Something’s not right.</h2> */}
                  <p className="text-block-2">
                    Welcome to iPath Life Sciences, please choose a method to Order a test.
                  </p>
                  <BuyTestBtn
                    text="Patients"
                    theme={{ btnColor: '#4557ce', secColor: '#363b77' }}
                    link="/order-test/individuals"
                  />
                  <BuyTestBtn
                    text="Providers"
                    theme={{ btnColor: '#348623', secColor: '#287319' }}
                    link="/order-test/providers"
                  />
                  <a href="tel:+18665711383" className="text-button text-block-2">
                    Special Case? Call Us!
                  </a>
                </div>
                <div className="counter-image-decor offset-md-1 col-12 col-md-6 order-1 order-md-2">
                  <img className="img-round-corners" src="/images/pages/choose-test-lab-girl.jpeg" />
                </div>
              </div>
            </div>
          </div>
        </StyledChooseMethod>
      </PageScrollTop>
    </Layout>
  );
};

export default ChooseMethod;
