import styled from 'styled-components';
import device from '../../../../styles/mediaSizes';

// 1-SECTION
export const HeroBannerPharmacogenomics = styled.div`
  & {
    border-bottom: 4px solid #348623;
  }
`;

// 2-SECTION
export const PicTextCTOSpecial = styled.div`
  @media ${device.sm} {
    .rn-section-gap {
      padding: 0 !important;
      .container {
        margin: 0;
        padding: 0;
        max-width: 100%;
      }
    }
  }
  .pic-text-wrapper {
    background-color: ${({ theme }) => theme.forthColor};
    margin: auto;
    border-radius: 9px;
    .pic-col {
      width: 33%;
      .inner-img {
        padding-left: 0;
        border-radius: 0;
        width: 100%;
      }
      .regular-img {
        display: inline-block;
      }
      .mobile-img {
        display: none;
      }
    }
    .text-col {
      width: 64%;
      .text-group {
        .heading-1 {
        }
        .heading-2 {
          color: ${({ theme }) => theme.secColor};
          width: 90%;
        }
        .heading-4 {
          color: ${({ theme }) => theme.secColor};
          width: 90%;
        }
      }
    }
    @media ${device.sm} {
      & {
        padding-bottom: 60px;
        .pic-col {
          width: 100%;
          margin-bottom: 35px;
          .inner-img {
          }
          .regular-img {
            display: none;
          }
          .mobile-img {
            display: inline-block;
          }
        }
        .text-col {
          width: 80%;
          margin: 0 auto;
          .text-group {
            margin-bottom: 0;
            .title {
              margin: 0;
              padding: 0;
              width: 100%;
            }
          }
        }
      }
    }
  }
`;

// 3-SECTION
export const RiskOfCancer = styled.div``;

// 5-SECTION
export const PharmacogenomicsExplained = styled.div``;

// 6-SECTION
export const BenefitsMedication = styled.div``;

// 7-SECTION
export const WhoNeedsThis = styled.div`
  .section-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .col-1 {
      width: 40%;
    }
    .col-2 {
      width: 48%;
      margin-top: 1em;
    }
    @media ${device.lg} {
    }
    @media ${device.md} {
    }
    @media ${device.sm} {
      & {
        .col-1,
        .col-2 {
          width: 100%;
        }
      }
    }
  }
`;

// 8-SECTION
export const HowItWorks = styled.div`
  .section-title-wrapper {
    margin-bottom: 60px;
    .title {
      text-align: center;
    }
    .description {
      margin: 0 auto;
      text-align: center;
      width: 59%;
    }
    @media ${device.lg} {
    }
    @media ${device.md} {
      & {
        .description {
          width: 90%;
        }
      }
    }
    @media ${device.sm} {
    }
  }
`;

// 9-SECTION
export const PicTextPayZero = styled.div`
  .pic-text-wrapper {
    .pic-col {
      width: 41.6%;
      .inner-img {
        border-radius: 0;
        width: 100%;
      }
      .regular-img {
        display: inline-block;
      }
      .mobile-img {
        display: none;
      }
    }
    .text-col {
      .text-group {
        .heading-1 {
        }
        .heading-2 {
        }
        .heading-4 {
          color: #5f5a6a;
        }
      }
    }

    @media ${device.sm} {
      & {
        .pic-col {
          width: 100%;
          .inner-img {
          }
          .regular-img {
            display: inline-block;
          }
          .mobile-img {
            display: none;
          }
        }
        .text-col {
          width: 100%;
          .text-group {
            .heading-1 {
            }
            .heading-2 {
            }
            .heading-4 {
              color: #5f5a6a;
            }
          }
        }
      }
    }
  }
`;

// 10-SECTION
export const FaqArea = styled.div`
  .faq-wrapper {
    .section-title-wrapper {
      .title {
      }
    }
    .accordion {
      .accordion-item {
        .accordion-header {
          .accordion-button {
            background: ${({ bgColor }) => bgColor};
            &:after,
            &.collapsed:after {
            }
            &.collapsed:after {
              background-image: url(/images/decor/faq_open_${({ theme }) => theme.themeColor}.svg);
            }
            &:after {
              background-image: url(/images/decor/faq_close_${({ theme }) => theme.themeColor}.svg);
            }
            &:focus {
            }
            &:focus:not(.collapsed) {
            }
            &:not(.collapsed) {
              background: ${({ bgColor }) => bgColor};
              color: ${({ theme }) => theme.mainColor};
            }
          }
        }
        .accordion-body {
        }
      }
    }
  }
`;
