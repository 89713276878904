import React from 'react';
import { useParams } from 'react-router-dom';
import BlogClassicData from '../../data/blog/BlogList.json';
import CategoryListPost from './CategoryListPost';
import { slugify} from "../../utils"
import SEO from "../../partials/components/SEO";
import BreadcrumbOne from "../../elements/breadcrumb/BreadcrumbOne";

const CategoryList = () => {
  const {slug} = useParams();

    const data = BlogClassicData.map(blog => {
        return {
            ...blog,
            categories: blog.categories.filter(data => slugify(data) === slug)
        }
    }).filter(blog => blog.categories.length > 0);
    const categoryTitle = data[0].categories[0];

    return (
        <>
            <SEO title="Blog Category List || iPath Life Sciences" />
            <BreadcrumbOne 
                title={categoryTitle}
                rootUrl="/"
                parentUrl="Home"
                currentUrl="Blog List"
            />
            <div className="main-content">
                <div className="rn-blog-category-area rn-section-gap">
                    <div className="container">
                        <CategoryListPost Column="col-lg-4 mt--30" data={data} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default CategoryList;