export const pharmacogenomics = {
  pageTitle: 'Medication',
  sliderArea: {
    title: ['Medication ', 'Management'],
    subtitle:
      'Learn which medications you should and should not be taking with our highly accurate Medication Management DNA Test.',
    description: [
      'Approximately 50% of all patients do not respond well to first-line, doctor prescribed medication. This typically leads to a time-consuming and exhaustive trial and error process in order to pinpoint the optimal drug. We have a solution that addresses that problem—it is science-based and highly accurate',
    ],
    img: '/images/pages/medication-hero.svg',
  },

  PicTextCTOSpecial: {
    img: '/images/pages/medication-genomics.svg',
    imgMobile: '/images/pages/medication-genomics-mobile.svg',
    regularTitle:  'You’ll know with a high degree of accuracy how your body will respond to certain medications, based on your DNA.',
    largeTitle: null,
    subtitle: null,
    p: [null],
  },

  PicTextPayZero: {
    img: '/images/pages/medication-patient.png',
    imgMobile: '/images/pages/medication-patient.png',
    regularTitle: 'Most insured patients pay $0',
    largeTitle: null,
    specialTitle: null,
    subtitle: null,
    p: [
      'iPath Life Sciences tests are covered by Medicare and most major insurers.',
      'Only 4% of patients have insurance plans which do not have coverage for our services. More than 96% of iPath Life Sciences patients have no out-of-pocket cost for screening.',
    ],
  },

  PureArticle: {
    textLight: false,
    decorImg: '/images/decor/decor-drop-green.svg',
    decoratedTitle: 'Pharmacogenomics Explained',
    sideText: [
      '•99% of people have a genetic variant that could impact how they respond to prescribed medications.',
      '•1 in 4 people are being prescribed a drug for which pharmacogenomic testing results could lead to the changing of either their current dosage or the prescription itself.',
    ],
    pLeft: null,
    pRight: [
      'Pharmacogenomics is the study of how an individual’s genotype can influence their body’s ability to metabolize different medications and treatments. Pharmacogenomics can aid in the creation of a personalized treatment plan for various conditions. Medications for which dosage guidelines are available following pharmacogenomic testing include but are not limited to medications for cardiac health, cancer management, and treatment of psychiatric disorders.',
      <p key= "special 1"  className="text-block-2 bold-text">
        Use of pharmacogenomic testing to aid in treatment decisions:
      </p>,
      'Personalized medications and medication dosages for patients can enhance medication effectiveness and decrease adverse side effects by reducing treatment toxicity. By knowing a patient’s genotype, and the possible drug-gene interactions that can occur because of their genotype, a physician can avoid the trial-and-error approach required for so many prescriptions to be effective.',
      'Knowledge of an increased genetic risk enables you to work with your healthcare provider to create a personalized plan to prevent or detect cancer at an earlier and more treatable stage. It is typical to see a recurring pattern of cancer across two to three generations, with multiple individuals diagnosed with the same type of cancer(s).',
      <p key= "special 2"  className="text-block-2 bold-text">Pharmacogenomic testing and cancer treatment</p>,
      'Various factors influence the effectiveness of cancer treatment. Pharmacogenomic testing may provide relevant dosage and toxicity guidelines for patients. There are numerous chemotherapies for which pharmacogenomic guidelines are available.',
    ],
  },

  benefitsMedication: {
    title: 'Benefits of our Medication Management Test include:',

    list: [
      'World-Class 24/7 Customer Support',
      'Learning exactly which medications are or aren’t right for you',
      'Cost covered by many medical insurance plans',
      'Quick reporting turnaround times',
      'Clear, accurate, easy to understand reports',
      'Professional follow-up support with a genetic counsellor',
      'Our Medication Management Test helps prevent adverse drug reactions, maximize drug efficacy and lets your healthcare provider prescribe with confidence.',
    ],
  },

  whoNeedsThis:{
    sectionTitle:{
      bigTitle: 'Who needs this test?',
      subtitle: 'Within the United States alone, serious side effects from pharmaceutical drugs occur in two million people each year and account for as many as 100,000 deaths, according to the FDA.'
    },

    checkLists: {
      listOne: {
        title: null,
        intro:[
          'Within the United States alone, serious side effects from pharmaceutical drugs occur in two million people each year and account for as many as 100,000 deaths, according to the FDA.',
          'You qualify for our Medication Management Test if:'
        ],
        imgCheck: '/images/decor/single-thick-green.svg',
        content: [
          {
            checkText:'You are taking either pain, cardiac or psych medication',
            innerList: null,
          },
          {
            checkText: 'You are scheduled for surgery in the next 12 months and will be put under general anesthesia.',
            innerList: null,
          },
        ],
      },
      listTwo: {
        title: null,
        intro: ['Knowing how your body processes specific medication can empower your physician to:' ],
        imgCheck: '/images/decor/single-thick-green.svg',
        content: [
          {
            checkText: 'Tailor a prescription drug strategy that works best for you',
            innerList: null,
          },
          {
            checkText: 'Take the guessing game out of pharmacology, drug selection and dosage',
            innerList: null,
          },
        ],
      },
    },

    btn:{
      link: '/order-test',
    }
  },

  howItWorks: {
    sectionTitle: {
      bigTitle:'How it works',
      description: 'We’re driven by our commitment to deliver exceptional experiences to our customers. We value our strong customer relationships and are defined by them.',
    },
    steps: {
      counterTitle: 'Step',
      steps: [
        {
          stepTitle: 'Do you qualify?',
          stepDesc: 'Fill in our intake questionnaire, online or via phone. We will assess your request and share it with your Primary Care to conduct a consult with you. If the physician approves your request, we will send you a kit in the mail, at no charge.',
        },
        {
          stepTitle: 'Receive Your Test Kit',
          stepDesc: 'Once you receive your kit, follow the instructions inside and administer the test on yourself. You can also visit your local health care provider and have them conduct it for you.',
        },
        {
          stepTitle: 'Send Kit To Lab',
          stepDesc: 'Place your sample back in the kit. Make sure the pre-paid sticker is placed outside of the kit, and drop it off in your mailbox for pickup. ﻿',
        },
        {
          stepTitle: 'Processing & Report',
          stepDesc: 'Get results sent to you and your physician via email as well as a hard copy sent to your house.',
        },
      ],
    },
  },

  picTextPayZero: {
    img: '/images/pages/medication-patient.png',
    imgMobile: '/images/pages/medication-patient.png',
    regularTitle: 'Most insured patients pay $0',
    largeTitle: null,
    specialTitle: null,
    subtitle: null,
    p: [
      'iPath Life Sciences tests are covered by Medicare and most major insurers.',
      'Only 4% of patients have insurance plans which do not have coverage for our services. More than 96% of iPath Life Sciences patients have no out-of-pocket cost for screening.',
    ],
  },

  faqAreaNew: {
    sectionTitle: {
      bigTitle: 'Frequently Asked Questions'
    },
    accordionBS:{
      items: [
        {
          title: 'Does my doctor need to OK this test?',
          text: 'This test can be approved by your Primary Care Physician. We CAN NOT send any of our patients a kit unless a doctor signs off on it being Medically Necessary.',
        },
        {
          title: 'Is iPath Life Sciences’s Genetic Testing Covered by Health Insurance?',
          text: 'In most cases, your health insurance or medicare will cover the cost of testing if genetic testing is recommended to you by your doctor. If you are interested in ordering genetic tests, you should contact your insurance company beforehand and ask about coverage.',
        },
        {
          title: 'What is Genetic Testing?',
          text: 'Genetic testing sequences DNA to identify any different mutations, differences, or anomalies that may result in the development of hereditary conditions. Prior to ordering a genetic test, we recommend that you educate yourself about the procedure, benefits, limitations and possible consequences of a test result by reading about our tests here. When you’ve educated yourself about the tests and essentially obtained permission for testing, you’ve acquired informed consent. This means you can change your mind about wanting the process even after the test sample has already been collected.',
        },      
        {
          title: 'I made a mistake with my sample, can you resend a kit?',
          text: 'If you have made a mistake collecting your sample or we found that there was not enough DNA to accurately find a result, we will require you to reorder a test so that you can retake it and provide the necessary sample.',
        }
      ]
    },
  },


  theme: {
    themeColor: 'green',
    dropColor: '#73d45f',
    pageTitle: 'Medication',
    mainColor: '#348623',
    btnColor: '#348623',
    mainGrad:
      'linear-gradient(95deg, #080808 15%, #3f1c52 45%, #f01fff 75%, #44015f 100%) 95% / 200% 100%;',
    secColor: '#287319',
    thirdColor: '#e4fde1',
    forthColor: '#f3fdf2',
  },
};
