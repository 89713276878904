import styled from 'styled-components';
import device from '../../../styles/mediaSizes';

export const StyledHeroTitle = styled.div`
  & {
    position: relative;
    padding-top: 80px;
  }

  .heading-1 {
    text-align: center;
  }

  .heading-4 {
    width: 60%;
    text-align: center;
    margin: auto;
  }

  .about-us-hero-title {
    position: relative;
    padding-bottom: 200px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .img-wrapper {
    margin-top: 35px;
    margin: auto;
  }

  .about-us-hero-img {
    position: absolute;
    bottom: -150px;
    margin: auto;
  }

  .about-us-hero-img .main-img {
    display: block;
    position: static;
    max-height: 300px;
    border-radius: 6px;
    margin: auto;
  }

  .about-us-hero-img .decor-img {
    position: absolute;
    bottom: -32.5px;
    left: 50%;
    transform: translate(-50%);
    max-height: 75px;
    display: block;
    margin: auto;
  }

  @media ${device.lg} {
    & {
      position: relative;
      padding-top: 80px;
    }

    .about-us-hero-title {
      padding-bottom: 150px;
    }

    .about-us-hero-img {
      bottom: -150px;
    }

    .about-us-hero-img .main-img {
      max-height: 300px;
    }

    .about-us-hero-img .decor-img {
      position: absolute;
      bottom: -32.5px;
      max-height: 75px;
      display: block;
      margin: auto;
    }
  }

  @media ${device.md} {
    & {
      position: relative;
      padding-top: 30px;
    }
    .about-us-hero-title {
      padding-bottom: 180px;
    }

    .heading-4 {
      width: 70%;
    }

    .heading-1 {
      margin-bottom: 25px;
    }
  }

  @media ${device.sm} {
    & {
      position: relative;
      padding-top: 45px;
    }
    .about-us-hero-title {
      padding-bottom: 100px;
    }

    .about-us-hero-img {
      bottom: -85px;
    }

    .about-us-hero-img .main-img {
      max-height: 170px;
    }

    .about-us-hero-img .decor-img {
      max-width: 200px;
      bottom: -21.6px;
      /* left: 51px; */
    }

    .heading-4 {
      width: 90%;
    }

    .heading-1 {
      margin-bottom: 15px;
    }
  }
`;