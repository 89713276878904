import React from 'react';
import HeroBannerPicText from '../../components/HeroBannerPicText';
import PureArticle from '../../components/PureArticle';
import * as Styled from './styles/StyledPathology';


//01-SECTION
export const HeroBannerPathology = ({ content, theme, pageTitle, btn }) => {
  return (
    <Styled.HeroBannerPathology theme={theme} className="d-flex align-items-center">
      <div className="container">
        <HeroBannerPicText content={content} />
      </div>
    </Styled.HeroBannerPathology>
  );
};

//02-SECTION
export const WhatIsPathology = ({ content, theme, bgColor }) => {
  return (
    <Styled.WhatIsPathology theme={theme} style={{ backgroundColor: bgColor }}>
      <div className="rn-section-gap ">
        <div className="container">
          <PureArticle theme={theme} content={content} />
        </div>
      </div>
    </Styled.WhatIsPathology>
  );
};

