import React from 'react';
import PageHero from '../../components/PageHero';
import PureArticle from '../../components/PureArticle';
import NumberedList from '../../components/NumberedList';
import SectionTitle from '../../components/SectionTitle';
import CheckList from '../../components/CheckList';
import * as Styled from './styles/StyledProvPharmacogenomics';

export const PageHeroProvPharmacogenomics = ({ theme, content, bgColor }) => {
  return (
    <Styled.PageHeroProvPharmacogenomics theme={theme} style={{ backgroundColor: bgColor }}>
      <div className="container">
        <PageHero {...content.pageHero} />
      </div>
    </Styled.PageHeroProvPharmacogenomics>
  );
};

export const PharmacogenomicsResults = ({ theme, bgColor, content }) => {
  return (
    <Styled.PharmacogenomicsResults theme={theme} style={{ backgroundColor: bgColor }}>
      <div className="rn-section-gap">
        <div className="container">
          <div className="section-wrapper">
            <div className="section-col1">
              <PureArticle content={content.pureArticle} />
            </div>
            <div className="section-col2">
              <NumberedList {...content.numberedList} />
            </div>
          </div>
        </div>
      </div>
    </Styled.PharmacogenomicsResults>
  );
};

export const MedicationBenefits = ({ theme, bgColor, content }) => {
  return (
    <Styled.MedicationBenefits theme={theme} style={{ backgroundColor: bgColor }}>
      <div className="rn-section-gap">
        <div className="container">
          <SectionTitle {...content.sectionTitle} />
          <div className="section-wrapper">
            <div className="section-col1">
              <CheckList {...content.checkLists.listOne} />
            </div>
            <div className="section-col2">
              <div className="solo-image-wrapper">
                <img src={content.soloImage.src} alt={content.soloImage.alt} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styled.MedicationBenefits>
  );
};
