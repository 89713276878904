import React from 'react';
import SEO from '../../partials/components/SEO';

// components
import SliderArea from './sections/SliderArea';
import SliderArea2 from './sections/SliderArea2';
import TeamArea from './sections/TeamArea';
import CallToAction from './sections/CallToAction';
import IlluminaVideos from './sections/IlluminaVideos';
import BlogArea from './sections/BlogArea';
import Mission from './sections/Mission';
import LabHighlights from './sections/LabHighlights';
import Layout from '../../partials/Layout';
import PageScrollTop from '../../utils/PageScrollTop'

//content
import { homepage as content } from './content/homepage';

const IPathHome = () => {
  return (
    <Layout>
      <PageScrollTop>
        <SEO title="iPath Life Sciences" />
        <main className="page-wrapper">
          {/* <SliderArea2 content={content} /> */}
          <SliderArea content={content.bannerData} />
          <LabHighlights content={content.about} bgColor={'#f4f5f9'} />
          <Mission content={content.mission} bgColor={'#fff'} />
          {/* <TeamArea bgColor={'#f8f8f8'} /> */}
          <CallToAction bgColor={'#fff'} />
          <IlluminaVideos bgColor={'#f4f5f9'} content={content.illuminaVideos} />
          {/* <BlogArea bgColor={"#f4f5f9"}/> */}
        </main>
      </PageScrollTop>
    </Layout>
  );
};
export default IPathHome;
