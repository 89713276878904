import styled from 'styled-components';
import device from '../../../pages/styles/mediaSizes';

export const StyledFooter = styled.footer`
  .footer-top {
    padding: 100px 0;
    padding-bottom: 12px;
  }
  .link-hover {
    list-style: none;
    padding: 0;
  }

  ul li a {
    text-decoration: none;
    color: #4557ce;
  }

  ul li {
    font-size: 1.3rem;
    line-height: 1.2;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 400;
  }

  .footer-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media ${device.sm} {
      & .footer-item {
        width: 50%;
      }
    }
  }

  .footer-logo-wrapper {
    justify-self: center;

    @media ${device.md} {
      width: 100%;
      margin-bottom: 60px;
      & .logo a img {
        max-height: 55px;
      }
    }
  }

  .copyright-style-one {
    .ft-menu {
      li {
        @media ${device.sm} {
          width: 100%;
        }
        a {
          font-size: 1.3rem;
          line-height: 1.8;
        }
      }
    }
    .copyright-text {
      @media ${device.sm} {
        margin-top: 8px;
        text-align: left;
      }
    }
  }
`;
