import React from 'react';
import SEO from '../../partials/components/SEO';
import Layout from '../../partials/Layout';
import PageScrollTop from '../../utils/PageScrollTop';
import * as Section from './sections/individuals/Pharmacogenomics'
import { GeneralComponentsStyle } from '../styles/GeneralComponentsStyle';

//CONTENT
import { pharmacogenomics as content } from './sections/individuals/content/pharmacogenomics';

const HereditaryMedication = () => {
  const { theme, pageTitle } = content;
  return (
    <Layout>
      <PageScrollTop>
        <GeneralComponentsStyle theme={theme} className={pageTitle}>
          <SEO title="Pharmacogenomics" />
          <Section.HeroBannerPharmacogenomics theme={theme} content={content.sliderArea} />
          <Section.PicTextCTOSpecial theme={theme} content={content.PicTextCTOSpecial} bgColor={'#fff'} btn={false} />
          <Section.PharmacogenomicsExplained theme={theme} content={content.PureArticle} bgColor={'#ededf1'} mergeHero="" />
          <Section.BenefitsMedication theme={theme} content={content.benefitsMedication} bgColor={'#f4f5f9'} />
          <Section.WhoNeedsThisNew theme={theme} content={content.whoNeedsThis} pageTitle={pageTitle} btn={true} />
          <Section.HowItWorks theme={theme} content={content.howItWorks} bgColor={'#f4f5f9'} />
          <Section.PicTextPayZero theme={theme} content={content.picTextPayZero} bgColor={'#fdfdfe'} btn={true} />
          <Section.FaqArea theme={theme} content={content.faqAreaNew} bgColor={'#f4f5f9'} />
        </GeneralComponentsStyle>
      </PageScrollTop>
    </Layout>
  );
};

export default HereditaryMedication;
