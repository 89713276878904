import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { tocContent } from './ComplianceContent';
import { StyledPolicies } from './styles/StyledPolicies';
import Layout from '../../partials/Layout';

const Compliance = () => {
  useEffect(() => {
    document.title = 'Compliance Mandate';
  }, []);

  return (
    <Layout>
      <StyledPolicies className="tco">
        <Container className="tco-s-1 pt-xs-2p5 pt-md-2p5 pb-xl-10">
          <Row>
            <Col className="pb-5" xs={12} xl={4}>
              <h3 className="pb-3 toUpperCase">
                <strong>iPath Life Sciences, LLC</strong>
              </h3>
              <h2 className="pb-5">
                <strong>Compliance Mandate</strong>
              </h2>
              <p className="toUpperCase pb-xs-1p0 pb-md-2p0">
                OUR COMPANY’S MISSION IS TO OFFER MEDICAL PROFESSIONALS AND THEIR PATIENTS
                INNOVATION AND QUALITY TECHNICAL LABORATORY COMPETENCE. WE SUCCEED WHEN PATIENTS AND
                THEIR TREATING PHYSICIAN ACHIEVE BETTER HEALTHCARE OUTCOMES.{' '}
              </p>
              <p className="toUpperCase pb-xs-1p0 pb-md-1p0">
                WE SUCCEED BY DOING OUR PART IN PROMOTING A HEALTHIER SOCIETY AND A BETTER LIFE FOR
                THE PEOPLE WE SERVE. THESE ARE OUR COMMITMENTS TO MEDICAL PROFESSIONALS, PATIENTS
                AND ULTIMATELY, OUR COMMUNITIES. TO ACHIEVE OUR COMMITMENTS, OUR COMPANY OPERATES ON
                TWO CARDINAL PRINCIPLES: TO PROVIDE QUALITY DIAGNOSTIC TESTING SERVICES AND PRODUCTS
                AND TO COMPLY WITH ALL LAWS AND REGULATIONS (OUR “COMPLIANCE MANDATE”).
              </p>
              <p className="fc-primary_medium">
                <small className="fw-500">Last Revision: 20-Aug-2023</small>
              </p>
            </Col>
            <Col className="" xs={12} xl={{ span: 6, offset: 1 }}>
              {tocContent.map(text => {
                return (
                  <>
                    {text?.paragraphs &&
                      text.paragraphs.map(p => {
                        return <p className="pb-2p0">{p}</p>;
                      })}
                  </>
                );
              })}
            </Col>
          </Row>
        </Container>
      </StyledPolicies>
    </Layout>
  );
};

export default Compliance;
