import axios from 'axios';

export const axiosEndPointCall = async (config) =>{
  try{
    const res = await axios.post(config.url, config.data)
    if(res.data.statusCode !== 200){
      return res;
    } else {
      return res.data.statusCode;
    }
  } catch(error){
    console.log(error)
    return error;
  }
}