import React from 'react';
import PicTextArea from '../components/PicTextArea';
import AbideLogos from '../components/AbideLogos';
import { StyledIPathologyAffordable } from './styles/StyledIPathologyAffordable';

const IPathologyAffordable = ({ content, bgColor }) => {
  return (
    <StyledIPathologyAffordable className="rn-section-gap" style={{ backgroundColor: bgColor }}>
      <div className="container">
        <PicTextArea
          content={content.picTextArea}
          btn={{ link: content.picTextArea.btn.link, text: content.picTextArea.btn.text }}
          type={content.picTextArea.type}
        />
        <AbideLogos content={content.abideLogos} />
      </div>
    </StyledIPathologyAffordable>
  );
};

export default IPathologyAffordable;
