import React from 'react';
import GeneralBtn from './GeneralBtn';

const PicTextArea = ({ content, theme, btn, bgColor, mergeHero }) => {
  const renderTitle = () => {
    if (content.regularTitle) {
      return <h2 className="heading-2">{content.regularTitle}</h2>;
    }
    if (content.largeTitle) {
      return <h2 className="heading-1">{content.largeTitle}</h2>;
    }
  };

  const special = content.specialTitle;
  const especialPic = special ? 'col-md-4 col-sm-6 flex-sm-fill' : null;
  const especialText = special ? 'col-md-8 col-sm-6' : null;
  const especialWrapper = special ? 'justify-content-md-between pic-text-special' : null;
  const flexWrapper = `${
    especialWrapper || 'justify-content-md-between'
  } d-flex flex-wrap align-items-start align-items-sm-center justify-content-sm-center`;

  return (
    <div
      className= "rn-section-gap"
      style={{ backgroundColor: bgColor }}
      theme={theme}
    >
      <div className='container'>
        <div className={flexWrapper}>
          <div className= {`${especialPic || 'col-12 col-lg-6 col-md-6'} r-m-img-wrapper`}>
            <img
              className={`inner-img r-img`}
              src={content.img}
            />
            <img
              className={`inner-img m-img`}
              src={content.imgMobile}
            />
          </div>
          <div className='col-12 col-md-6'>
            <div className="body">
              {renderTitle()}
              {content.subtitle ? (
                <h3 id="pic" className="heading-4">
                  {content.subtitle}
                </h3>
              ) : null}

              {content.p.map((p, i) => (
                <p key={i} className="text-block-2">
                  {p}
                </p>
              ))}
              {btn ? (
                <GeneralBtn
                  text={btn.text}
                  link={btn.link}
                  className="center btn"
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PicTextArea;
