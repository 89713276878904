import styled from 'styled-components';
import device from '../../../../styles/mediaSizes';

export const PageHeroProvPathology = styled.div``;

export const OurServices = styled.div`
  .rn-section-gap {
    padding-top: ${135 + 220}px !important;
    @media ${device.lg} {
      padding-top: ${135 + 185}px !important;
    }
    @media ${device.md} {
      padding-top: ${80 + 160}px !important;
    }
    @media ${device.sm} {
      padding-top: ${60 + 85}px !important;
    }
  }

  .section-main-article {
    display: flex;
    justify-content: space-between;
    .section-col-1 {
      width: 35%;
    }
    .section-col-2 {
      width: 60%;
    }

    @media ${device.sm} {
      & {
        flex-direction: column;
        .section-col-1 {
          width: 100%;
          margin-bottom: 40px;
        }
        .section-col-2 {
          width: 100%;
        }
      }
    }
  }

  .check-list-wrapper {
    &:last-child {
    }
    .check-list-title {
    }
    .check-list-intro {
    }
    .check-list-item {
      margin-bottom: 25px;
      &:last-child {
      }
      .check-list-main {
        .check-list-item-img {
          /* width: 22px; */
          margin: 0;
          margin-right: 12px;
        }
        .check-list-text {
          font-weight: 600;
          color: #342a47;
        }
      }
    }

    @media ${device.lg} {
      & {
        margin-bottom: 30px;
        &:last-child {
        }
        .check-list-title {
        }
        .check-list-intro {
        }
        .check-list-item {
          &:last-child {
          }
          .check-list-main {
            .check-list-item-img {
            }
          }
          .check-list-text {
          }
          .inner-list {
            &.list-border {
            }
            .inner-list-item {
              &:before {
              }
              &:last-child {
              }
            }
          }
        }
      }
    }
    @media ${device.md} {
      & {
        .check-list-intro {
        }
        .check-list-item {
          .check-list-item-img {
          }
          .check-list-text {
          }
          .inner-list {
            &.list-border {
            }
            .inner-list-item {
              &:before {
              }
            }
          }
        }
      }
    }
    @media ${device.sm} {
      & {
        margin-bottom: 30px;
        &:last-child {
        }
        .check-list-title {
        }
        .check-list-intro {
        }
        .check-list-item {
          margin-bottom: 15px;
          &:last-child {
          }
          .check-list-main {
            .check-list-item-img {
              /* width: 16px; */
              margin: 0;
              margin-right: 12px;
            }
          }
          .check-list-text {
          }
          .inner-list {
            margin-bottom: 30px;
            &.list-border {
            }
            .inner-list-item {
              &:before {
                padding-right: 12px;
                font-size: 5px;
              }
              &:last-child {
              }
            }
          }
        }
      }
    }
  }

  .numbered-list-wrapper {
    margin-bottom: 0;
    .numbered-list-title {
      margin-top: 15px;
      margin-bottom: 45px;
    }
    .numbered-list-intro {
    }
    .numbered-list {
      .numbered-list-item {
        &:last-child {
        }
        p {
          &:last-child {
          }
        }
        .numbered-list-item-title {
        }
        .numbered-list-item-circle {
          .numbered-list-item-number {
          }
        }
        .numbered-list-item-text {
        }
      }
    }

    @media ${device.lg} {
      & {
        .numbered-list-title {
        }
        .numbered-list-intro {
        }
        .numbered-list {
          .numbered-list-item {
            &:last-child {
              margin-bottom: 0;
            }
            p {
              &:last-child {
              }
            }
            .numbered-list-item-title {
            }
            .numbered-list-item-circle {
              width: 28px;
              height: 28px;
              max-height: 28px;
              max-width: 28px;
              min-height: 28px;
              min-width: 28px;
              margin-right: 15px;
              .numbered-list-item-number {
              }
            }
            .numbered-list-item-text {
            }
          }
        }
      }
    }
    @media ${device.md} {
      & {
        .numbered-list-title {
        }
        .numbered-list-intro {
        }
        .numbered-list {
          .numbered-list-item {
            &:last-child {
              margin-bottom: 0;
            }
            p {
              &:last-child {
              }
            }
            .numbered-list-item-title {
            }
            .numbered-list-item-circle {
              width: 25px;
              height: 25px;
              max-height: 25px;
              max-width: 25px;
              min-height: 25px;
              min-width: 25px;
              margin-right: 15px;
              .numbered-list-item-number {
                font-size: 16px;
              }
            }
            .numbered-list-item-text {
            }
          }
        }
      }
    }
    @media ${device.sm} {
      & {
        .numbered-list-title {
        }
        .numbered-list-intro {
        }
        .numbered-list {
          .numbered-list-item {
            &:last-child {
              margin-bottom: 0;
            }
            p {
              &:last-child {
              }
            }
            .numbered-list-item-title {
            }
            .numbered-list-item-circle {
              width: 21px;
              height: 21px;
              max-height: 21px;
              max-width: 21px;
              min-height: 21px;
              min-width: 21px;
              margin-right: 10px;
              .numbered-list-item-number {
                font-size: 14px;
              }
            }
            .numbered-list-item-text {
            }
          }
        }
      }
    }
  }
`;

export const BeginWorkingTogether = styled.div`
  .ordered-list-wrapper {
    @media ${device.md} {
      & {
        .ordered-list-title {
          max-width: 60%;
        }
      }
    }
    @media ${device.sm} {
      & {
        .ordered-list-title {
          max-width: 100%;
        }
      }
    }
  }
`;

export const InOfficePathologyModels = styled.div`
  .section-main-article {
    display: flex;
    justify-content: space-between;
    .section-col1 {
      width: 40%;
    }
    .section-col2 {
      width: 58%;
    }

    @media ${device.md} {
      & {
        flex-direction: column;
        .section-col1 {
          width: 100%;
          margin-bottom: 40px;
        }
        .section-col2 {
          width: 100%;
        }
      }
    }
  }

  .pure-article-wrapper {
    .title-wrapper {
      .heading-2.regular {
      }
    }
    .article-wrapper {
      .article-left-col {
        width: 100%;
        .text-block-2 {
          margin-bottom: 25px;
        }
      }
    }
  }

  .check-list-wrapper {
    &:last-child {
    }
    .check-list-title {
    }
    .check-list-intro {
    }
    .check-list-item {
      margin-bottom: 25px;
      &:last-child {
      }
      .check-list-main {
        .check-list-item-img {
          /* width: 22px; */
          margin: 0;
          margin-right: 12px;
        }
        .check-list-text {
          font-weight: 600;
          color: #342a47;
        }
      }
    }

    @media ${device.lg} {
      & {
        margin-bottom: 30px;
        &:last-child {
        }
        .check-list-title {
        }
        .check-list-intro {
        }
        .check-list-item {
          &:last-child {
          }
          .check-list-item-img {
            padding-top: 0.2em;
          }
          .check-list-text {
          }
          .inner-list {
            &.list-border {
            }
            .inner-list-item {
              &:before {
              }
              &:last-child {
              }
            }
          }
        }
      }
    }
    @media ${device.md} {
      & {
        .check-list-intro {
        }
        .check-list-item {
          .check-list-item-img {
          }
          .check-list-text {
          }
          .inner-list {
            &.list-border {
            }
            .inner-list-item {
              &:before {
              }
            }
          }
        }
      }
    }
    @media ${device.sm} {
      & {
        margin-bottom: 30px;
        &:last-child {
        }
        .check-list-title {
        }
        .check-list-intro {
        }
        .check-list-item {
          &:last-child {
          }
          .check-list-main {
            .check-list-item-img {
              /* width: 16px; */
              margin: 0;
              margin-right: 12px;
            }
          }
          .check-list-text {
          }
          .inner-list {
            margin-bottom: 30px;
            &.list-border {
            }
            .inner-list-item {
              &:before {
                padding-right: 12px;
                font-size: 5px;
              }
              &:last-child {
              }
            }
          }
        }
      }
    }
  }

  .tail-text-wrapper {
    margin-top: 40px;
    .tail-text-title {
    }
    .tail-text-list-item {
    }
  }
`;
