import styled from 'styled-components';
import device from '../../../styles/mediaSizes';

export const StyledFormPage = styled.div`
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  input,
  select,
  textarea {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    height: auto;
    padding: 0 20px;
    outline: none;
    border: 2px solid hsla(0, 0%, 100%, 0.1);
    border-radius: 6px;
    transition: 200ms;

    &:focus {
      border-color: #800a8899 !important;
      box-shadow: 0 0 0.3rem #800a88;
      background-image: linear-gradient(#ffedfc, #fffaff 6%, #fff 50%, #fffaff 78%, #ffedfc);
    }

    &::placeholder {
      color: #5f5a6a;
      opacity: 1;
    }
  }

  h1,
  p {
    flex-basis: 100%;
  }

  input,
  select {
    height: 70px;
    width: 100%;
    @media ${device.sm} {
      height: 50px;
      width: 100%;
    }
  }

  input[type='checkbox'] {
    height: fit-content;
    width: 20%;
  }

  .marketing_utm_data[campaign_ad]{
    display: none;
  }

  .form-group {
    flex-basis: 100%;
    margin-bottom: 10px;
  }

  .form-img-1 {
    display: block;
  }
  .form-img-2 {
    display: none;
  }

  a {
    color: #4557ce;
    font-weight: 700;
    text-decoration: none;
    outline: none;
    transition: 0.3s;
    &:hover{
      color:#ca8ceb
    }
  }

  .marketing_hidden{
    display: none;
  }

  @media ${device.lg} {
    .form-img-2 {
      display: block;
    }
    .form-img-1 {
      display: none;
    }
  }

  .subject,
  .message {
    flex-basis: 45%;
  }

  label {
    font-family: 'Inter', sans-serif;
    color: #342a47;
    display: block;
    margin-bottom: 6px;
    margin-left: 3px;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 500;
  }

  select:invalid {
    color: #5f5a6a;
  }

  .error-msg {
    color: #cc0000;
    padding-left: 18px;
    margin-top: 8px;
  }

  .error-msg svg {
    margin-right: 5px;
    margin-bottom: 3px;
  }

  .spinner {
    display: none;
    opacity: 0;
    margin-left: 9px;
  }

  .btn-loading.spinner {
    display: inline-block;
    opacity: 1;
    transition: 0.2ms;
    animation: btn-spinner-anime 1s ease infinite;
  }

  .text-block-2 {
    margin-bottom: 29px;
  }

  @keyframes btn-spinner-anime {
    from {
      transform: rotate(0turn);
    }

    to {
      transform: rotate(1turn);
    }
  }

  @media ${device.sm} {
    & {
      justify-content: center;
    }
    .form-group {
      flex-basis: 95%;
    }
  }
`;

// box-shadow: 0 0 .2rem #fff,
// 0 0 .2rem #fff,
// 0 0 0.5rem #800a88,
// 0 0 0.2rem #800a88,
// 0 0 0.5rem #800a88,
// inset 0 0 0.3rem #800a88;
