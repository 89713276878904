import React from 'react';

const PageHero = ({ title, subtitle, mainImg, decorImg }) => {
  return (
    <div className="page-hero-wrapper">
      {title?<div className="page-hero-title-wrapper">
        <h1 className="title heading-1 fadeInUp">{title}</h1>
        {subtitle?<h4 className="subtitle heading-4 fadeInUp">{subtitle}</h4>:null}
      </div>:null}
      <div className="page-hero-img-wrapper">
        <img className="page-hero-main-img fadeIn" src={mainImg} />
        {decorImg?<img className="page-hero-decor-img" src={decorImg} />:null}
      </div>
    </div>
  );
};

export default PageHero;
