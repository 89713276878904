import styled from 'styled-components';
import device from '../../../../styles/mediaSizes';

export const PageHeroProvCancer = styled.div``;

export const WhatIsGeneticTesting = styled.div`
  .rn-section-gap {
    padding-top: ${135 + 220}px !important;
    @media ${device.lg} {
      padding-top: ${135 + 185}px !important;
    }
    @media ${device.md} {
      padding-top: ${80 + 160}px !important;
    }
    @media ${device.sm} {
      padding-top: ${60 + 85}px !important;
    }
  }
  .pic-text-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: start;
    .pic-col {
      width: 46.6%;
      .inner-img {
        border-radius: 9px;
      }
      .regular-img {
        display: inline-block;
      }
      .mobile-img {
        display: none;
      }
    }
    .text-col {
      width: 50%;
      .text-group {
        margin-bottom: 35px;
        .heading-1 {
        }
        .heading-2 {
        }
        .heading-4 {
          color: #5f5a6a;
        }
      }
    }
    @media ${device.sm} {
      & {
        margin: 0;
        .pic-col {
          width: 100%;
          margin-bottom: 35px;
          .inner-img {
          }
          .regular-img {
            display: none;
          }
          .mobile-img {
            display: inline-block;
          }
        }
        .text-col {
          width: 100%;
          .text-group {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
`;

export const HereditaryCancerTesting = styled.div`
  .section-title-wrapper {
    margin-bottom: 60px;
    .title {
      text-align: center;
    }
    .description {
      margin: 0 auto;
      text-align: center;
      width: 59%;
    }
    @media ${device.lg} {
    }
    @media ${device.md} {
      & {
        .description {
          width: 90%;
        }
      }
    }
    @media ${device.sm} {
    }
  }
  .steps-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .step {
      width: 30%;
      .step-title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 60px;
        .step-counter-title {
          color: ${({ theme }) => theme.mainColor};
          width: auto;
          margin: 0;
        }
        .counter-last-line,
        .step-counter-title-line {
          height: 1px;
          width: 80%;
          background-color: ${({ theme }) => theme.mainColor};
        }
      }
      .step-title {
      }
      .step-description {
        width: 100%;
      }
    }
    @media ${device.lg} {
      & {
        .step {
          .step-title-wrapper {
            .step-counter-title {
            }
            .step-counter-title-line {
            }
          }
          .step-title {
          }
          .step-description {
          }
        }
      }
    }
    @media ${device.md} {
      & {
        .step {
          width: 48%;
          margin-bottom: 30px;
          &:nth-last-of-type(-n + 2) {
            margin-bottom: 0;
          }
          .step-title-wrapper {
            margin-bottom: 30px;
            .step-counter-title {
            }
            .step-counter-title-line {
              display: none;
            }
          }
          .step-title {
          }
          .step-description {
            width: 85%;
          }
        }
      }
    }
    @media ${device.sm} {
      & {
        .step {
          width: 100%;
          &:nth-last-of-type(n + 2) {
            margin-bottom: 30px;
          }
          .step-title-wrapper {
            margin-bottom: 15px;
            .step-counter-title {
            }
            .step-counter-title-line {
            }
          }
          .step-title {
          }
          .step-description {
            width: 100%;
          }
        }
      }
    }
  }
`;
