import React from 'react';

export const provPharmacogenomics = {
  pageTitle: 'Pharmacogenomics',

  pageHeroPharmacogenomics: {
    pageHero: {
      title: 'Pharmacogenomics',
      subtitle: null,
      mainImg: '/images/pages/medication-hero.svg',
      decorImg: null,
    },
  },

  pharmacogenomicsResults: {
    pureArticle: {
      textLight: false,
      decorImg: null,
      decoratedTitle: null,
      regularTitle: 'Pharmacogenomics Results',
      sideText: null, //array
      pLeft: [
        'This testing looks at the different genes that are responsible for how a person’s body breaks down certain prescribed medications. The test explains what type of metabolizer your patient may be for each gene analyzed. Once a patient’s genotype is determined, professional guidelines such as the Pharmacogenomics Knowledge Base and the FDA’s Table of Pharmacogenetic Associations can be used to aid a treatment plan.',
        '** Overall, physicians are encouraged to use their professional judgment when making decisions regarding their patients’ prescriptions and treatments. Pharmacogenomic test results can be a resource for current and future decision making.',
      ],
      pRight: null,
      tailText: null,
    },
    numberedList: {
      title: 'The four types of results that are possible include:',
      items: [
        {
          title: 'Extensive (normal) metabolizer: ',
          desc: 'If a patient receives this result for a specific genotype, it means that when you take a medication that this gene is responsible for breaking down, their body is expected to be able to break down that medication at “normal” or “standard” doses. These patients are unlikely to require alterations in relevant medications.',
        },
        {
          title: 'Poor metabolizers:',
          desc: 'If a patient receives this result for a specific genotype, it means that when they take a medication that this gene is responsible for breaking down, their body is likely to do so very slowly compared to a person with a “normal” version of the gene. A slow breakdown process can lead to severe medication side effects. Knowing a patient is a poor metabolizer may lead a physician to  consider avoiding prescribing certain medications, or to consider adjusting the medication dosage to decrease the risk of bad reactions in a patient.',
        },
        {
          title: 'Intermediate metabolizer:',
          desc: 'If a patient receives this result for a specific genotype, it means that when they take a medication that this gene is responsible for breaking down, their body breaks down the medication slower than “normal metabolizers” but not as slow as a “poor metabolizer.” Being an intermediate metabolizer may cause a patient to have more side effects than others but not as much complications as a poor metabolizer. With this information, dosage or type of medication prescribed, can be changed to decrease adverse reactions, or stay the same if the reactions do not outweigh the effectiveness of treatment.',
        },
        {
          title: 'Ultrarapid metabolizer:',
          desc: 'If a patient receives this result for a specific genotype, it means that when a patient takes a medication that this gene is responsible for breaking down, they are likely to break down the medication more quickly than “normal metabolizers.” If this occurs, a patient may need a higher dose prescribed to be effective.',
        },
      ],
    },
  },

  medicationBenefits: {
    sectionTitle: {
      smallTitle: 'Medications for which the benefit of pharmacogenomic testing results are well documented',
      subtitle:
        'Guidelines and vetted information for the gene-drug interactions relevant to this panel can be found on the FDA’s Table of Associations, in the Pharmacogenomics Knowledge Base, or by using the Clinical Pharmacogenetics Implementation Consortium (CPIC®).',
    },
    checkLists: {
      listOne: {
        title: null,
        intro: null,
        imgCheck: '/images/decor/single-thick-green.svg',
        content: [
          {
            checkText: 'warfarin - CYP2C9 genotype and risk of hemorrhage or stroke',
            innerList: null,
          },
          {
            checkText: 'tamoxifen - CYP2D6 genotype and risk of therapeutic failure',
            innerList: null,
          },
          {
            checkText: 'clopidogrel - CYP2C19 genotype and risk of thrombotic cardiovascular outcomes',
            innerList: null,
          },
          {
            checkText: 'irinotecan - UGT1A1 genotype and risk of myelosuppression',
            innerList: null,
          },
          {
            checkText: 'thiopurines - TPMT genotype and/or phenotype and risk of myelosuppression',
            innerList: null,
          },
        ],
      },
    },
    soloImage:{
      src:'/images/pages/choose-test-lab-girl.jpeg',
      alt: 'choose-test-lab-girl'
    }
  },

  picTextRobust: {
    title: 'medication documented',
    sectionTitle: 'Medications for which the benefit of pharmacogenomic testing results are well documented',
    subtitle:
      'Guidelines and vetted information for the gene-drug interactions relevant to this panel can be found on the FDA’s Table of Associations, in the Pharmacogenomics Knowledge Base, or by using the Clinical Pharmacogenetics Implementation Consortium (CPIC®).',
    img: '/images/pages/choose-test-lab-girl.jpeg',
    showCheckList: '2',
    checkLists: [
      {
        position: '1',
        title: null,
        intro: null,
        imgCheck: '/images/decor/single-thick-green.svg',
        content: [
          {
            checkText: 'warfarin - CYP2C9 genotype and risk of hemorrhage or stroke',
            innerList: null,
          },
          {
            checkText: 'tamoxifen - CYP2D6 genotype and risk of therapeutic failure',
            innerList: null,
          },
          {
            checkText: 'clopidogrel - CYP2C19 genotype and risk of thrombotic cardiovascular outcomes',
            innerList: null,
          },
          {
            checkText: 'irinotecan - UGT1A1 genotype and risk of myelosuppression',
            innerList: null,
          },
          {
            checkText: 'thiopurines - TPMT genotype and/or phenotype and risk of myelosuppression',
            innerList: null,
          },
        ],
      },
    ],

    numberedList: null,
  },

  faqArea: {
    questions: [
      'Lorem ipsum dolor, sit amet consectetur adipisicing elit?',
      'Lorem ipsum dolor, sit amet consectetur adipisicing elit?',
      'Lorem ipsum dolor, sit amet consectetur adipisicing elit?',
      'Lorem ipsum dolor, sit amet consectetur adipisicing elit?',
    ],
    answers: [
      'sit amet consectetur adipisicing elit. Sed quod autem sequi reprehenderit labore consequuntur excepturi voluptatibus omnis similique qui unde eligendi tempora.',
      'sit amet consectetur adipisicing elit. Sed quod autem sequi reprehenderit labore consequuntur excepturi voluptatibus omnis similique qui unde eligendi tempora.',
      'sit amet consectetur adipisicing elit. Sed quod autem sequi reprehenderit labore consequuntur excepturi voluptatibus omnis similique qui unde eligendi tempora.',
      'sit amet consectetur adipisicing elit. Sed quod autem sequi reprehenderit labore consequuntur excepturi voluptatibus omnis similique qui unde eligendi tempora.',
    ],
  },

  theme: {
    themeColor: 'green',
    pageTitle: 'Pharmacogenomics',
    mainColor: '#348623',
    btnColor: '#348623',
    mainGrad: 'linear-gradient(95deg, #080808 15%, #3f1c52 45%, #f01fff 75%, #44015f 100%) 95% / 200% 100%;',
    secColor: '#73d45f',
    thirdColor: '#e4fde1',
    forthColor: '#f3fdf2',
    dropColor: '#73d45f',
  },
};

// orderedList: {
//   title: ['Once We Begin Working Together,', 'Your Office Will Benefit From:'],

//   list: [
//     '24-Hour Turnaround on All Results.',
//     'Our 9-Step Quality Assurance Program ensures your slides and reports in the least amount of time, without any error.',
//     'Transcription Services, to record all of your diagnoses.',
//     'Completely Customizable Requisitions, Reports and the ability to “Go Paperless.”',
//     'A Selection of Renowned Board-Certified Dermatopathologists available for consultations.',
//     'A comprehensive menu of over 75 special stains which are stored in-house.',
//     'Personal Laboratory Consultant available 24 hours a day, 7 days a week.',
//     'As well as additional ancillary services, such as Mobile Frozen Section Units available upon your request',
//   ],
// },

// sliderArea: {
//   h1: ["Pathology"],
//   desc: "Understand how inherited heart conditions can affect your health.",
//   p: [
//     "Heart disease is often the result of poor lifestyle choices, but genetic heart disease is , to changes in genes, which we inherit from our parents and can pass down to our children.",
//     "With genetic testing we can help you by determining your risk factors based on your DNA, so you can take steps now, to reduce complications in the future."
//   ],
//   img: "/images/pages/heart-hero.svg"
// },

// picTextArea: {
//   img:"/images/pages/heart-genomics-2.svg",
//   imgMobile:"/images/pages/heart-genomics-mobile.svg",
//   regularTitle: null,
//   largeTitle: null,
//   specialTitle: "Managing a heart condition is possible if you know about it.",
//   subtitle: "Finding out early if you have or are prone to a chronic or mild heart condition could save your life.",
//   p:[null]
// },

// orderedList: {

//   title: ["Our Services"],

//   list: [
//     'Dermatopathology',
//     'Surgical Histopathology',
//     'Gastrointestinal Pathology',
//     'Urologic Pathology',
//     'Gynecological Pathology',
//     'Podiatric Pathology',
//     'Immunohistochemistry',
//     'FNA-Cytology',
//     'Molecular pathology'
//   ]
// },

// picTextArea2: {
//   img:"/images/pages/heart-patient.png",
//   imgMobile:"/images/pages/heart-patient.png",
//   regularTitle: "Most insured patients pay $0",
//   largeTitle: null,
//   specialTitle: null,
//   subtitle: null,
//   p:[
//     "iPath Life Sciences tests are covered by Medicare and most major insurers.",
//     "Only 4% of patients have insurance plans which do not have coverage for our services. More than 96% of iPath Life Sciences patients have no out-of-pocket cost for screening."
//   ]
// },
// howItWorks: {
//   title: 'How it works',
//   subtitle:
//     'We’re driven by our commitment to deliver exceptional experiences to our customers. We value our strong customer relationships and are defined by them.',
//   steps: [
//     {
//       stepTitle: 'Do you qualify?',
//       stepDesc:
//         'Fill in our intake questionnaire, online or via phone. We will assess your request and share it with your Primary Care to conduct a consult with you. If the physician approves your request, we will send you a kit in the mail, at no charge.',
//     },
//     {
//       stepTitle: 'Receive Your Test Kit',
//       stepDesc:
//         'Once you receive your kit, follow the instructions inside and administer the test on yourself. You can also visit your local health care provider and have them conduct it for you.',
//     },
//     {
//       stepTitle: 'Send Kit To Lab',
//       stepDesc:
//         'Place your sample back in the kit. Make sure the pre-paid sticker is placed outside of the kit, and drop it off in your mailbox for pickup.',
//     },
//     {
//       stepTitle: 'Processing & Report',
//       stepDesc:
//         'Get results sent to you and your physician via email as well as a hard copy sent to your house.',
//     },
//   ],
// },
