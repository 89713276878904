import React from 'react';
import SEO from '../../partials/components/SEO';
import Layout from '../../partials/Layout';
import PageScrollTop from '../../utils/PageScrollTop';
import * as Section from './sections/individuals/HereditaryHeart';
import { GeneralComponentsStyle } from '../styles/GeneralComponentsStyle';
//CONTENT
import { hereditaryHeart as content } from './sections/individuals/content/hereditaryHeart';

const HereditaryHeart = () => {
  const { theme, pageTitle } = content;

  return (
    <Layout>
      <PageScrollTop>
        <GeneralComponentsStyle theme={theme} className={pageTitle}>
          <SEO title="Hereditary Heart Test" />
          <Section.HeroBannerHeart theme={theme} content={content.sliderArea} />
          <Section.PicTextCTOSpecial theme={theme} content={content.PicTextCTOSpecial} bgColor={'#fff'} btn={false} />
          <Section.BenefitsHeart theme={theme} content={content.benefitsHeart} bgColor={'#f4f5f9'} />
          <Section.WhoNeedsThis theme={theme} content={content.whoNeedsThis} pageTitle={pageTitle} btn={true} />
          <Section.HowItWorks theme={theme} content={content.howItWorks} bgColor={'#fff'} />
          <Section.PicTextPayZero theme={theme} content={content.picTextPayZero} bgColor={'#fdfdfe'} btn={true} />
          <Section.FaqArea theme={theme} content={content.faqArea} bgColor={'#f4f5f9'} />
        </GeneralComponentsStyle>
      </PageScrollTop>
    </Layout>
  );
};

export default HereditaryHeart;
