import React from 'react';

const Steps = ({ counterTitle, steps }) => {
  return (
    <div className="steps-wrapper">
      {steps.map((step, index) => {
        return (
          <div key={index} className="step">
            <div className={`step-title-wrapper step${index + 1}`}>
              <h2 className="step-counter-title heading-2">{`${counterTitle} ${index + 1}`}</h2>
              <div className={`${steps.length === index + 1 ? 'counter-last-line' : 'step-counter-title-line'}`}></div>
            </div>
            <h3 className="step-title heading-5">{step.stepTitle}</h3>
            <p className="step-description text-block-3"> {step.stepDesc}</p>
          </div>
        );
      })}
    </div>
  );
};

export default Steps;
