import React from "react";
import SectionTitle from "./components/SectionTitle";
import BlogList from "./components/BlogList";
import BlogClassicData from "../../../data/blog/BlogList.json";

const BlogListData = BlogClassicData.slice(0, 3);

const BlogArea = ({ bgColor }) => {
  return (
    <div className="blog-area rn-section-gap" style={{ backgroundColor: bgColor }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <SectionTitle
              textAlign="text-center"
              radiusRounded=""
              subtitle="Latests News"
              title="Our Latest News."
              description="iPath Life Sciences in the News."
            />
          </div>
        </div>
        <div className="row row--15">
          {BlogListData.map((item) => (
            <div key={item.id} className="col-lg-4 col-md-6 col-12 mt--30">
              <BlogList StyleVar="box-card-style-default" data={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogArea;
