import React from 'react';

const AbideLogos = ({ content, bgColor }) => {
  return (
    <div className="abide-wrapper">
      <h2 className="heading-2">{content.title}</h2>
      <div className="logos-wrapper">
        {content.logos.map((logosRow, i) => {
          return (
            <div key={i} className="row row-wrapper">
              {logosRow.map((img, i) => {
                return (
                  <div key={i+10} className="img-wrapper col-md-3">
                    <img  src={img.img} alt={img.alt} />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AbideLogos;
