import styled from 'styled-components';
import device from '../../../styles/mediaSizes';

export const StyledIPathologyAffordable = styled.div`
  .rn-section-gap {
    padding: 0 !important;
  }

  .heading-4 {
    color: #5f5a6a;
  }

  img {
    border-radius: 6px;
  }

  .r-m-img-wrapper {
    height: 628px;
  }

  .r-img {
    height: 100%;
    width: 90%;
    object-fit: cover;
  }

  .r-img {
    display: inline-block;
  }

  .m-img {
    display: none;
  }

  .button-group {
    margin-top: 25px;
    a,
    button {
      margin: 10px;
    }
    .general-btn {
      margin-top: 10px;
      display: inline-block;
      border-radius: 8px;
      background: #4557ce;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      text-align: center;
      letter-spacing: 0.5px;
      width: auto;
      height: 65px;
      line-height: 63px;
      padding: 0 40px;
      transition-duration: 300ms;
      &:hover {
        box-shadow: 0 0 20px 5px rgba(251, 83, 67, 0.2);
        color: #fff;
        background-color: #363b77 !important;
        text-decoration: none;
      }
      .icon {
        position: relative;
        top: -1px;
        padding-left: 4px;
      }
      .icon svg {
        vertical-align: middle;
        width: 20px;
        height: 20px;
      }
    }
  }

  .abide-wrapper {
    margin-top: 75px;
    .heading-2 {
      margin-bottom: 60px;
    }
    .logos-wrapper {
      .row-wrapper {
        margin-bottom: 45px;
        .img-wrapper {
          img {
            max-height: 80px;
            width: auto;
          }
        }
        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  @media ${device.lg} {
    .r-m-img-wrapper {
      height: 597px;
    }

    .abide-wrapper {
      margin-top: 75px;
      .heading-2 {
        margin-bottom: 60px;
      }
      .logos-wrapper {
        .row-wrapper {
          margin-bottom: 45px;
          .img-wrapper {
            img {
              max-height: 55px;
              width: auto;
            }
          }
          &:last-child {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }

  @media ${device.md} {
    .heading-1 {
      margin-bottom: 15px;
    }

    .button-group {
      .general-btn {
        width: 100%;
        height: 45px;
        font-size: 12px;
        line-height: 42px;
        padding: 0 15px;
        .icon svg {
          width: 15px;
          height: 15px;
        }
      }
    }

    .r-m-img-wrapper {
      height: 565px;
    }

    .abide-wrapper {
      margin-top: 75px;
      .heading-2 {
        margin-bottom: 60px;
      }
      .logos-wrapper {
        .row-wrapper {
          margin-bottom: 45px;
          .img-wrapper {
            img {
              max-height: 40px;
              width: auto;
            }
          }
          &:last-child {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }

  @media ${device.sm} {
    .r-m-img-wrapper {
      height: 250px;
      margin-bottom: 35px;
    }

    .r-img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: right top;
    }

    .inner-img {
      border-radius: 6px;
    }

    .r-img {
      display: inline-block;
    }

    .button-group {
      .general-btn {
        height: 45px;
        font-size: 12px;
        line-height: 42px;
        padding: 0 15px;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        .icon svg {
          width: 15px;
          height: 15px;
        }
      }
    }

    .abide-wrapper {
      margin-top: 60px;
      .heading-2 {
        margin-bottom: 30px;
      }
      .logos-wrapper {
        .row-wrapper {
          margin-bottom: 25px;
          flex-wrap: nowrap;
          width: 35%;
          .img-wrapper {
            img {
              max-height: 30px;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
          &:last-child {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
`;
