import React from 'react';
import Layout from '../../partials/Layout';
import { StyledFormPage } from './components/styles/StyledFormPage';
import IndividualForm from './components/IndividualForm';
import PageScrollTop from '../../utils/PageScrollTop';


const IndividualTest = () => {
  return (
    <Layout noFooter={true}>
      <PageScrollTop>
        <StyledFormPage className="container rn-section-gap">
          <div className="row no-footer align-items-center">
            <div className="col-12 col-lg-7">
              <IndividualForm />
            </div>
            <div className='col-12 col-lg-5 d-none d-lg-block'>
              <img className='form-img-1' src="/images/decor/decor-form-individual-order.svg" />
              <img className='form-img-2' src="/images/decor/decor-form-individual-order-tablet.svg" />
            </div>
          </div>
        </StyledFormPage>
      </PageScrollTop>
    </Layout>
  );
};

export default IndividualTest;
