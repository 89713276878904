import styled from 'styled-components';
import { Link } from 'react-router-dom';
import device from '../../../styles/mediaSizes';

const StyledBuyTestBtn = styled(Link)`

  & {
    display: inline-block;
    border-radius: 8px;
    background: ${({ bg }) => bg};
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    text-align: center;
    letter-spacing: 0.5px;
    width: 100%;
    height: 65px;
    line-height: 63px;
    padding: 0 40px;
    transition-duration: 300ms;
  }

  &:hover {
    box-shadow: 0 0 20px 5px rgba(251, 83, 67, 0.2);
    color: #fff;
    background-color: ${({ hover }) => hover} !important;
    background: ${({ hover }) => hover} !important;
    text-decoration: none;
  }

  .button-group a,
  .button-group button {
    margin: 10px;
  }

  .icon {
    position: relative;
    top: -1px;
    padding-left: 4px;
  }

  .icon svg {
    width: 20px;
    height: 20px;
  }

  @media ${device.md} {
    & {
      height: 45px;
      font-size: 12px;
      line-height: 42px;
      padding: 0 15px;
    }
  }
`;

export default StyledBuyTestBtn;
