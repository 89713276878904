import React from 'react';

const CheckList = ({ checkList, title, intro, imgCheck, content }) => {
  if (checkList) {
    return (
      <>
        {checkList.title ? <h3 className="title heading-5"> {checkList.title} </h3> : null}
        {typeof checkList.intro === 'string' ? <p className="check-list-intro text-block-2">{checkList.intro}</p> : checkList.intro}
        {checkList.content
          ? checkList.content.map((checkEl, iEl) => {
              return (
                <div key={iEl + 10} className="check-list-item">
                  {checkEl.checkText ? (
                    <div className="check-list-item-img-wrapper align-self-start">
                      <img src={checkList.imgCheck} loading="lazy" alt="" className="check-list-item-img" />
                    </div>
                  ) : null}
                  <div>
                    {checkEl.checkText ? <p className="check-list-text text-block-2">{checkEl.checkText}</p> : null}
                    {/* INNER LIST OR BULLET LIST (IF NO checkTest) IF THERE'S ONE */}
                    {checkEl.innerList ? (
                      <ul className={checkEl.checkText ? 'list-border inner-list' : 'inner-list'}>
                        {checkEl.innerList.map((item, i) => {
                          return (
                            <li className="d-flex align-items-center text-block-2" key={i + 20}>
                              {item}
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}
                  </div>
                </div>
              );
            })
          : null}
      </>
    );
  }
  return (
    <div className="check-list-wrapper">
      {title ? <h3 className="check-list-title heading-5"> {title} </h3> : null}

      {intro
        ? intro.map((text, i) => {
            if (typeof text === 'string') {
              return (
                <p key={i} className="check-list-intro text-block-2">{text}</p>
              );
            } else {
              return text;
            }
          })
        : null}

      {content
        ? content.map((checkEl, iEl) => {
            return (
              <div key={iEl + 10} className="check-list-item">
                <div className='check-list-main'>
                  {checkEl.checkText ? <img src={imgCheck} loading="lazy" alt="" className="check-list-item-img" /> : null}
                  {checkEl.checkText ? <p className="check-list-text text-block-2">{checkEl.checkText}</p> : null}
                </div>
                {/* INNER LIST OR BULLET LIST (IF NO checkTest) IF THERE'S ONE */}
                {checkEl.innerList ? (
                  <ul className={checkEl.checkText ? 'list-border inner-list' : 'inner-list'}>
                    {checkEl.innerList.map((item, i) => {
                      return (
                        <li className="inner-list-item text-block-2" key={i + 20}>
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                ) : null}
              </div>
            );
          })
        : null}
    </div>
  );
};

export default CheckList;
