import styled from 'styled-components';
import device from '../../../pages/styles/mediaSizes';

export const StyledHeader = styled.div`
  /*--------------------------
    Header Styles  
--------------------------*/

  nav ul,
  nav ol {
    list-style: none;
    list-style-image: none;
  }

  .logo-dark {
    display: none;
  }

  .header-default {
    max-width: 100%;
    box-shadow: 0 8px 4px -4px rgb(38 57 77 / 10%);
    font-size: 12px;
    z-index: 99;
    position: relative;

    .logo {
      a {
        display: flex;
        height: 80px;
        line-height: 80px;
        align-items: center;
        img {
          max-height: 45px;
        }
      }
    }

    .btn-default {
      display: flex;
      align-items: center;
      width: 53%;
      padding: 5px 20px;
      height: 35px;
    }

    .btn-default:hover {
      width: 65%;
    }

    .btn-wrapper {
      padding-left: 15px;
    }

    .header-right {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    &.header-relative {
      position: relative;
    }

    .header-right {
      display: flex;
      justify-content: flex-end;
      .header-btn {
        margin-left: 25px;
        @media ${device.md} {
          margin-left: 0;
        }
        @media ${device.sm} {
          margin-left: 0;
        }
      }
    }

    a,
    button {
      &.btn-default {
        @media ${device.sm} {
          height: 33px;
          line-height: 30px;
          padding: 0 9px;
          font-size: 12px;
        }
        &.btn-small {
          @media ${device.sm} {
            height: 33px;
            line-height: 30px;
            padding: 0 9px;
            font-size: 12px;
          }
        }
      }
    }

    &.header-transparent {
      position: absolute;
      left: 0;
      right: 0;
      width: auto;
      background: transparent !important;
      backdrop-filter: inherit;
    }
  }

  .header-transparent-with-topbar {
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
    z-index: 99;

    @media ${device.md} {
      position: relative;
    }

    @media ${device.sm} {
      position: relative;
    }

    .header-default {
      background-color: transparent;
      background-image: none;

      @media ${device.md} {
        background-color: rgba(6, 6, 6, 0.7);
      }

      @media ${device.sm} {
        background-color: rgba(6, 6, 6, 0.7);
      }
    }
  }

  .hamburger-button {
    background: #ece4faff;
    border: 0 none;
    color: #fff;
    width: 40px;
    height: 40px;
    font-size: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;
    svg {
      position: relative;
      z-index: 2;
      stroke-width: 2px;
      stroke: #4557ce;
    }
  }

  .header-left-align {
    .mainmenu-nav {
      margin-left: 30px;
    }
  }

  .rn-header.sticky {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #060606 !important;
    width: 100%;
    height: 80px;
    animation: headerSticky 0.95s ease forwards;
    z-index: 9;
  }

  /*--------------------------
    Navigation Styles 
----------------------------*/

  .mainmenu-nav {
    .mainmenu {
      display: flex;
      margin: 0;
      padding: 0;
      > li {
        > a {
          background-color: transparent;
          color: #342a47 !important;
          font-weight: 400;
          font-family: Prompt, sans-serif;
          line-height: 10px;
          font-size: 16px;
          padding: 0 17px;
          display: block;
          height: 80px;
          line-height: 80px;
          transition: none;
          &:hover {
            border-bottom: 3px solid #b3bcfb;
            box-shadow: 0 8px 4px -4px rgba(38, 57, 77, 0.1);
            font-weight: 500;
          }
        }
      }

      li {
        margin-top: 0;
        margin-bottom: 0;
        &.has-dropdown {
          .submenu {
            width: 100%;
            height: 25vh;
            min-width: 240px;
            position: absolute;
            top: 90%;
            left: 50%;
            transform: translate(-50%);
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            text-align: left;
            padding: 12px 0;
            transition: 0.2s;
            border-top: 1px solid #b3bcfb;
            border-radius: 0 0 10px 10px;
            background-color: #fff;
            box-shadow: 0 8px 4px -4px rgb(38 57 77 / 20%);
            .menu-wrapper {
              height: auto;
              ul {
                list-style: none;
                margin-top: 0.83rem;
              }
            }

            .call-section-wrapper {
              position: absolute;
              right: 0;
              bottom: 0;
              display: flex;
              align-items: center;
              justify-content: end;
              @media ${device.lg} {
                width: 450px;
              }
              .call-section-content {
                display: flex;
                justify-content: end;
                align-items: center;
                position: absolute;
                color: #fff;
                p {
                  margin: 0;
                  margin: 0 8px;
                  color: #fff;
                }
                .assist-text {
                  font-size: 15px;
                }
                .phone-number {
                  font-size: 26px;
                }
                .phone-image.hover {
                  display: none;
                }
                .phone-lady {
                  max-height: 64px;
                }
                @media ${device.lg} {
                  .assist-text {
                    font-size: 12px;
                  }
                  .phone-number {
                    font-size: 20px;
                  }
                  .phone-lady {
                    max-height: 50px;
                  }
                }
              }
            }

            li {
              a {
                /* font-weight: 500; */
                padding: 5px 20px;
                font-size: 14px;
                color: #342a47;
                margin: 0 10px;
                border-radius: 3px;
                &:hover {
                  color: #4557ce;
                  font-size: 18px;
                  transform: translateX(15px);
                }
              }
            }
            @media ${device.lg} {
              .btn-wrapper {
                width: 360px;
                .btn-default {
                  span {
                    display: inline-block;
                    /* font-size: 10px; */
                  }
                }
              }
            }
          }
          .open {
            opacity: 1;
            visibility: visible;
            top: 100%;
            transition: 0.5s;
          }

          /* &:hover {
            .submenu {
              opacity: 1;
              visibility: visible;
              top: 100%;
              transition: 0.5s;
            }
          } */
        }

        &.with-megamenu {
          position: static;

          /* .rn-megamenu {
            position: absolute;
            transition: 0.3s;
            top: 90%;
            width: 100%;
            left: 0;
            right: 0;
            padding: 0 15px;
            visibility: hidden;
            opacity: 0;
            .wrapper {
              border-radius: 0 0 10px 10px;
              background-color: #0f0f11;
              box-shadow: 4px 4px 20px 20px rgba(0, 0, 0, 0.01);

              .mega-menu-item {
                padding: 22px 10px;
                border-right: 1px solid #1a1a1a;
                height: 100%;

                li {
                  a {
                    font-weight: 500;
                    padding: 5px 14px;
                    font-size: 15px;
                    display: block;
                    color: #acacac;
                    margin: 0 10px;
                    border-radius: 4px;
                    transition: 0.3s;
                    display: flex;
                    align-items: center;

                    .rn-badge-card {
                      margin-left: 10px;
                    }

                    &:hover {
                      color: #059dff;
                      background: rgba(6, 6, 6, 0.7);
                    }
                  }
                }
              }
            }
          } */

          .single-mega-item {
            &:last-child {
              .mega-menu-item {
                border-right: 0 none;
              }
            }
          }

          &:hover {
            .rn-megamenu {
              opacity: 1;
              visibility: visible;
              top: 100%;
            }
          }
        }
      }
    }
  }

  /*------------------------------
    Popup Menu Styles  
--------------------------------*/
  .popup-mobile-menu {
    z-index: 9999;
    position: fixed;
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease-out;
    ul {
      list-style: none;
      padding: 0;
    }
    ul > li {
      margin: 0;
      border: none;
    }
    .inner {
      width: 320px;
      z-index: 999;
      position: absolute;
      background-color: var(--color-blackest);
      height: 100%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      opacity: 0;
      left: -150px;
      transition: all 0.5s ease-out;
      .call-to-action-mobile {
        display: flex;
        justify-content: start;
        align-items: center;
        background-color: #4557ce;
        width: 90%;
        height: 100px;
        margin: 0 auto;
        margin-top: 25px;
        padding-left: 5%;
        /* padding-top: 3%; */
        border-radius: 9px;
        .content {
          width: 100%;
          p {
            color: #fff;
            margin-bottom: 0;
          }
          .assist-text {
            font-size: 10px;
            margin-bottom: 12px;
          }
          .phone {
            display: flex;
            justify-content: start;
            align-items: center;
            width: 75%;
            font-size: 20px;
            img {
              margin-right: 12px;
            }
          }
        }
      }

      .header-top {
        display: flex;
        border-bottom: 1px solid var(--color-border);
        align-items: center;
        justify-content: space-between;
        padding: 15px 20px;
        .logo {
          a {
            img {
              max-height: 45px;
            }
          }
        }
        .close-menu {
          .close-button {
            background: #ece4faff;
            border: 0 none;
            color: #4557ce;
            width: 40px;
            height: 40px;
            font-size: 21px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            cursor: pointer;
            svg {
              position: relative;
              z-index: 2;
              stroke-width: 2px;
              stroke: #4557ce;
            }
          }
        }
      }
    }

    &.active {
      visibility: visible;
      opacity: 1;
      .inner {
        opacity: 1;
        left: 0;
        overflow-y: auto;
      }
    }

    .mainmenu {
      list-style: none;
      padding: 0;
      margin: 0;
      padding: 15px 20px;
      li {
        margin: 0;
        a {
          padding: 8px 0;
          /* display: block; */
          font-size: 16px;
          font-weight: 500;
        }
        & + li {
          border-top: 1px solid var(--color-border);
        }
      }

      .has-dropdown {
        .submenu {
          height: 0;
          transition: all 0.3s;
          opacity: 0;
          visibility: hidden;
          padding: 0;
          max-width: 100%;
          list-style: none;
          padding-left: 14px;
          li {
            a {
              font-size: 15px;
            }
          }
          &.open {
            height: 100%;
            opacity: 1;
            visibility: visible;
            padding-bottom: 12px;
          }
        }
      }

      .rn-megamenu {
        height: 0;
        transition: all 0.3s;
        opacity: 0;
        visibility: hidden;
        padding: 0;
        max-width: 100%;
        list-style: none;
        padding-left: 14px;
        &.active {
          height: 100%;
          opacity: 1;
          visibility: visible;
          padding-bottom: 12px;
        }
      }

      .mega-menu-item {
        list-style: none;
        padding: 0;
        margin: 0;
        padding-left: 0;
        li {
          a {
            font-size: 15px;
          }
          &:last-child {
            border-bottom: 1px solid var(--color-border);
          }
        }
      }

      .single-mega-item {
        &:last-child {
          .mega-menu-item {
            li {
              &:last-child {
                border-bottom-color: transparent;
              }
            }
          }
        }
      }
    }
  }
  @media ${device.md} {
    .btn-wrapper {
      .btn-default {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 5px 20px;
        height: 35px;
      }
    }
  }

  @media ${device.sm} {
    .btn-wrapper {
      .btn-default {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 5px 20px;
        height: 35px;
      }
    }
  }
`;
