import React from 'react';
import SEO from '../../partials/components/SEO';
import FormArea from './sections/FormArea';
import ContactInfo from './sections/ContactArea';
import Layout from '../../partials/Layout';
import {content} from './content/contactPage';

const Contact = () => {
  return (
    <Layout>
      <SEO title="Contact || iPath Life Sciences || Genetic Testing" />
      <ContactInfo content={content.infoCards} bgColor='#fdfdfe' />
      <FormArea id='write-us-a-message' content={content.formAreaContent} bgColor='#f4f5f9' />
    </Layout>
  );
};
export default Contact;
