import React, { useState } from 'react';
//FORM MODULES
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { contactSchema } from '../../../../validations/ContactValidation';
import { axiosEndPointCall } from '../../../../utils/axiosEndPointCall';
// COMPONENTS REQUIRED
import ThankYouPage from './ThankYouPage';
import ApiError from './ApiError';
import { RiErrorWarningLine } from 'react-icons/ri';
import { FaSpinner } from 'react-icons/fa';
// FORM CONTROL
import { content } from '../../content/formControl.js';
// STYLES
import { StyledFormArea } from '../styles/StyledFormArea';

function ContactForm({ formStyle }) {
  const [submitted, setSubmitted] = useState({status: false, msg: null});
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [apiError, setApiError] = useState({ status: false, error: null });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onBlur', resolver: yupResolver(contactSchema) });

  const onSubmit = async values => {

    setLoadSpinner(true);

    const serverResponse = await axiosEndPointCall({
      url: 'https://www.ipathlifesciences.com/api/email/contact-us',
      data: values,
    });

    if (serverResponse === 'error') {
      return setApiError({
        status: true,
        error: 'Please, try back later or contact us at (863) 606-6655',
      });
    }

    reset();
    
    setSubmitted({status: true, msg: 'Our patient service specialist team will be reaching out to you as soon as possible. If you have any questions please call us at +1 (863) 606-6655'});

    return;
  };

  if (submitted.status) return <ThankYouPage msg={submitted.msg}/>;
  if (apiError.status) return <ApiError error={apiError.error} />;

  return (
    <>
      <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
        {content.formStructure.map((field, index) => {
          switch (field.el) {
            case 'input':
              return (
                <div key={index} className={`${field.attributes.name} form-group `}>
                  <label className='text-block-2' name={field.attributes.name} id={field.attributes.name}>
                    {field.label}
                  </label>
                  <input {...register(field.attributes.name)} {...field.attributes} />
                  <div className="error-msg">
                    {errors[field.attributes.name] ? <RiErrorWarningLine /> : null}
                    {errors[field.attributes.name]?.message}
                  </div>
                </div>
              );
            case 'textarea':
              return (
                <div key={index} className={`${field.attributes.name} form-group `}>
                  <label className='text-block-2' name={field.attributes.name} id={field.attributes.name}>
                    {field.label}
                  </label>
                  <textarea {...register(field.attributes.name)} {...field.attributes} />
                  <div className="error-msg">
                    {errors[field.attributes.name] ? <RiErrorWarningLine /> : null}
                    {errors[field.attributes.name]?.message}
                  </div>
                </div>
              );
            case 'select':
              return (
                <div key={index} className={`${field.attributes.name} form-group `}>
                  <label className='text-block-2' name={field.attributes.name} id={field.attributes.name}>
                    {field.label}
                  </label>
                  <select defaultValue={''} {...register('referer')} {...field.attributes}>
                    {field.options.map((option) => {
                      return (
                        <option key={option.value} value={option.value} disabled={option.disabled}>
                          {option.content}
                        </option>
                      );
                    })}
                  </select>
                  <div className="error-msg">
                    {errors[field.attributes.name] ? <RiErrorWarningLine /> : null}
                    {errors[field.attributes.name]?.message}
                  </div>
                </div>
              );
            default:
              return null;
          }
        })}

        <div className="form-group">
          <button className="btn-default">
            Submit Form
            <span className={loadSpinner ? 'btn-loading spinner' : 'spinner'}>
              <FaSpinner />
            </span>
          </button>
        </div>

        <div className="form-group"></div>
      </form>
    </>
  );
}

export default ContactForm;
