import React from 'react';
import Typed from 'react-typed';
import { HashLink as Link } from 'react-router-hash-link';
import { FiArrowRight } from 'react-icons/fi';
import { StyledMission } from './styles/StyledMission'

const typedOptions = {
  strings: ['Accuracy.', 'Efficacy.', 'Reliability.'],
  typeSpeed: 80,
  backSpeed: 5,
  backDelay: 1000,
  loop: true,
};

const mission = ({ bgColor, content }) => {

  return (
    <div className="rn-company-mission-are rn-section-gap" style={{ backgroundColor: bgColor }}>
      <div className="container">
        <StyledMission className="row row--30">
          <div className="col-lg-6">
            <div className="mission-title">
              <h2 className="title">
                {content.title} <br />
                <Typed className="theme-gradient" {...content.typedOptions} />
              </h2>
              {content.desc.map((desc, i) => (
                <p key={i}>{desc}</p>
              ))}
              <div className="read-more-btn mt--50">
                <Link className="btn-default btn-icon" to="/about-us#top">
                  Learn More
                  <i className="icon">
                    <FiArrowRight />
                  </i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mt_md--30 mt_sm--30">
            {content.serviceList
              ? content.serviceList.map((item, i) => (
                  <p key={i}>
                    <span className="theme-gradient">{item}</span>
                  </p>
                ))
              : null}
          </div>
        </StyledMission>
      </div>
    </div>
  );
};
export default mission;
