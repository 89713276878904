import React from 'react';
import PageHero from '../../components/PageHero';
import PicText from '../../components/PicText';
import SectionTitle from '../../components/SectionTitle';
import Steps from '../../components/Steps';
import * as Styled from './styles/StyledProvHereditaryCancer';

export const PageHeroProvCancer = ({ theme, content, bgColor }) => {
  return (
    <Styled.PageHeroProvCancer theme={theme} style={{ backgroundColor: bgColor }}>
      <div className="container">
        <PageHero {...content.pageHero} />
      </div>
    </Styled.PageHeroProvCancer>
  );
};

export const WhatIsGeneticTesting = ({ theme, bgColor, content }) => {
  return (
    <Styled.WhatIsGeneticTesting theme={theme} style={{ backgroundColor: bgColor }}>
      <div className="rn-section-gap">
        <div className="container">
          <PicText content={content.picText} />
        </div>
      </div>
    </Styled.WhatIsGeneticTesting>
  );
};

export const HereditaryCancerTesting = ({ theme, bgColor, content }) => {
  return (
    <Styled.HereditaryCancerTesting theme={theme} style={{ backgroundColor: bgColor }}>
      <div className="rn-section-gap">
        <div className="container">
          <SectionTitle {...content.sectionTitle} />
          <Steps {...content.steps} />
        </div>
      </div>
    </Styled.HereditaryCancerTesting>
  );
};
