import React from 'react';
import ContactForm from './components/ContactForm';
import GoogleMapStyle from './components/GoogleMap';
import { StyledFormArea } from './styles/StyledFormArea';

const sectionProps = {
  textAlign: 'text-center',
  radiusRounded: '',
  // subtitle: "Contact Form",
  title: 'Our Contact Address Here.',
  description: '',
};

const FormArea = ({bgColor, content, id}) => {
  return (
    <StyledFormArea>
      <div className="rn-section-gap" style={{backgroundColor: bgColor, position: 'relative'}}>
        <div className="container">
          <div className="row">
            <div id= {id} style={{ position: 'absolute' }}></div>
            <h1 className="heading-2">{content.title}</h1>
            <div className="col-lg-7">
              <ContactForm />
            </div>
            <div className="col-lg-5">
              <GoogleMapStyle />
            </div>
          </div>
        </div>
      </div>
    </StyledFormArea>
  );
};

export default FormArea;
