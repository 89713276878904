import { FiHeadphones, FiMail, FiMapPin, FiPhoneCall } from 'react-icons/fi';
import { HiOutlineOfficeBuilding, HiOutlineMail } from 'react-icons/hi';
import { TbReportMoney } from 'react-icons/tb';
import { FaFax } from 'react-icons/fa';
import {contactInfo} from '../../GLOBAL_INFO'

export const content = {
  infoCards: [
    {
      icon: <FiHeadphones />,
      title: 'For Immediate Access to Reports, to Order Supplies, or Specimen Pickups',
      info: [
        {
          type: <FiPhoneCall />,
          data: contactInfo.MAIN_PHONE,
          href: contactInfo.MAIN_PHONE_LINK,
        },
        {
          type: <FaFax />,
          data: contactInfo.FAX_NUMBER,
          href: null,
        },
        {
          type: <HiOutlineMail />,
          data: contactInfo.MAIN_EMAIL,
          href: contactInfo.MAIN_EMAIL_LINK,
        },
      ],
      desc: null,
    },
    {
      icon: <TbReportMoney />,
      title: 'For Billing Inquiries or Concerns:',
      info: [
        {
          type: <FiPhoneCall />,
          data: contactInfo.MAIN_PHONE,
          href: contactInfo.MAIN_PHONE_LINK,
        },
        {
          type: <HiOutlineMail />,
          data: contactInfo.MAIN_EMAIL,
          href: contactInfo.MAIN_EMAIL_LINK,
        },
      ],
      desc: '',
    },
    {
      icon: <FiMapPin />,
      title: 'iPath Life Sciences',
      info: [
        {
          type: null,
          data: '6850 New Tampa Hwy, Suite 500 Lakeland FL 33815',
          href: 'https://www.google.com/maps/place/6850+New+Tampa+Hwy+%23500,+Lakeland,+FL+33815/@28.0289964,-82.0469036,17z/data=!3m1!4b1!4m5!3m4!1s0x88dd378410116749:0xfdf755f19ba46dcd!8m2!3d28.0289964!4d-82.0469036?entry=ttu',
          target: '_blank'
        },
      ],
      desc: 'Monday-Friday 9am - 7pm (EST)',
    }
  ],

  formAreaContent: {
    title: 'Write Us a Message'
    // To change data on the form, go to /content/formControl.js
  },
};