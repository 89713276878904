import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { BannerActivation } from '../../../utils/script';
import { StyledSliderArea } from './styles/StyledSliderArea';

const SliderArea = ({ content }) => {
  return (
    <StyledSliderArea>
      <Slider className="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow" {...BannerActivation}>
        {content.map((data, index) => {
          return (
            <div key={index} className="single-slide">
              <div
                className="height-950 bg-overlay bg_image"
                style={{
                  backgroundImage: `url(${data.image})`,
                }}
              >
                <div className="container">
                  <div className="row row--30 align-items-center">
                    <div className="order-2 order-lg-1 col-lg-7">
                      <div className="inner text-start">
                        <h1 style={{ color: data.colorTitle }} className="title">
                          {data.title}
                        </h1>
                        <p className="description">{data.description}</p>
                        <div className="button-group mt--30">
                          <Link
                            className="btn-default"
                            // target='_blank'
                            to={data.href}
                          >
                            Learn More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </StyledSliderArea>
  );
};

export default SliderArea;
