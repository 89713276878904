import React from 'react';
import HeroBannerPicText from '../../components/HeroBannerPicText';
import Video from '../../components/Video';
import PureArticle from '../../components/PureArticle';
import PicText from '../../components/PicText';
import SectionTitle from '../../components/SectionTitle';
import Divider from '../../components/Divider';
import TableList from '../../components/TableList';
import NumberedList from '../../components/NumberedList';
import CheckList from '../../components/CheckList';
import TailText from '../../components/TailText';
import Steps from '../../components/Steps';
import AccordionBS from '../../components/AccordionBS';
import * as Styled from './styles/StyledHereditaryCancer';


//01-SECTION
export const HeroBannerCancer = ({ content, theme, pageTitle, btn }) => {
  return (
    <Styled.HeroBannerCancer theme={theme}>
      <div className="container">
        <HeroBannerPicText content={content} />
      </div>
    </Styled.HeroBannerCancer>
  );
};

//02-SECTION
export const ArticleVideo = ({ content, theme, bgColor }) => {
  return (
    <Styled.ArticleVideo theme={theme} style={{ backgroundColor: bgColor }}>
      <Video />
      <div className="rn-section-gap">
        <div className="container">
          <PureArticle content={content} />
        </div>
      </div>
    </Styled.ArticleVideo>
  );
};

//02-SECTION
export const PicTextCTO = ({ content, theme, btn, bgColor }) => {
  return (
    <Styled.PicTextCTO className="rn-section-gap" style={{ backgroundColor: bgColor }} theme={theme}>
      <div className="container">
        <PicText content={content} btn={btn} theme={theme} />
      </div>
    </Styled.PicTextCTO>
  );
};

//04-SECTION
export const RisksOfCancer = ({ content, theme, bgColor, mergeHero }) => {
  return (
    <Styled.RiskOfCancer theme={theme} style={{ backgroundColor: bgColor }}>
      <div className="rn-section-gap ">
        <div className="container">
          <PureArticle theme={theme} content={content} />
        </div>
      </div>
    </Styled.RiskOfCancer>
  );
};

//05-SECTION
export const RunInYourFamily = ({ theme, content, bgColor }) => {
  return (
    <Styled.RunInYourFamily theme={theme} bgColor={bgColor} style={{ backgroundColor: bgColor }}>
      <div className="rn-section-gap">
        <div className="container">
          <Divider {...content.divider} />
          <SectionTitle {...content.sectionTitle} />
          <TableList {...content.tableList} />
        </div>
      </div>
    </Styled.RunInYourFamily>
  );
};

//06-SECTION
export const PicTextHelpOfGenetics = ({ content, theme, btn, bgColor, mergeHero }) => {
  return (
    <Styled.PicTexHelpOfGenetics style={{ backgroundColor: bgColor }} theme={theme}>
      <div className="rn-section-gap">
        <div className="container">
          <PicText content={content} btn={btn} theme={theme} />
        </div>
      </div>
    </Styled.PicTexHelpOfGenetics>
  );
};

//07-SECTION
export const BenefitsOfTesting = ({ content, theme, bgColor }) => {
  return (
    <Styled.BenefitsOfTesting theme={theme}>
      <div className="rn-section-gap" style={{ backgroundColor: bgColor }}>
        <div className="container">
          <div className="section-wrapper">
            {/* Column 1 */}
            <div className="col-1">
              <SectionTitle {...content.sectionTitle} />
              <CheckList {...content.checkLists.listOne} checkList={null} />
              <CheckList {...content.checkLists.listTwo} checkList={null} />
            </div>
            {/* Column 2 */}
            <div className="col-2">
              <NumberedList {...content.numberedList} />
              <TailText {...content.tailText} />
            </div>
          </div>
        </div>
      </div>
    </Styled.BenefitsOfTesting>
  );
};

//08-SECTION
export const HowItWorks = ({ theme, content, bgColor }) => {
  return (
    <Styled.HowItWorks theme={theme} style={{ backgroundColor: bgColor }}>
      <div className="rn-section-gap">
        <div className="container">
          <SectionTitle {...content.sectionTitle} />
          <Steps {...content.steps} />
        </div>
      </div>
    </Styled.HowItWorks>
  );
};

//09-SECTION
export const PicTextPrivacy = ({ content, theme, btn, bgColor, mergeHero }) => {
  return (
    <Styled.PicTextPrivacy style={{ backgroundColor: bgColor }} theme={theme}>
      <div className="rn-section-gap">
        <div className="container">
          <PicText content={content} theme={theme} btn={btn} />
        </div>
      </div>
    </Styled.PicTextPrivacy>
  );
};

//10-SECTION
export const FaqArea = ({ content, bgColor, theme }) => {
  return (
    <Styled.FaqArea style={{ backgroundColor: bgColor }} theme={theme} bgColor={bgColor}>
      <div className="rn-section-gap">
        <div className="container">
          <div className="faq-wrapper">
            <SectionTitle {...content.sectionTitle} />
            <AccordionBS {...content.accordionBS} bgColor={bgColor} />
          </div>
        </div>
      </div>
    </Styled.FaqArea>
  );
};
