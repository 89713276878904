import React from 'react';
import BlogClassicData from '../../data/blog/BlogList.json';
import BlogDetailsContent from './BlogDetailsContent';
import { useParams } from 'react-router-dom';
import SEO from "../../partials/components/SEO";
// import Layout from "../partials/Layout";

//{match: {params: {id}}}
const BlogDetails = () => {
    const {id} = useParams()
    const blogId = parseInt(id, 10)
    const data = BlogClassicData.filter(blog => blog.id === blogId);
    return (
        <>
            <SEO title="Blog Details || iPath Life Sciences" />
            <div className="rn-blog-details-area">
                <BlogDetailsContent data={data[0]}  />
            </div>
        </>
    )
}
export default BlogDetails;