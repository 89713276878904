import React from 'react';
import Typed from 'react-typed';
import { HashLink as Link } from 'react-router-hash-link';

const LabHighlights = ({ bgColor, content }) => {
  return (
    <div className="rn-section-gap" style={{ backgroundColor: bgColor }}>
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-5">
            <div className="thumbnail">
              <img className="w-100" src={content.img} alt="About Images" />
            </div>
          </div>
          <div className="col-lg-7 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="section-title">
                <h2 className="title">
                  {content.title} <br />
                  <Typed className="theme-gradient" {...content.typedOptions} />
                </h2>
                <ul>
                  {content.desc.map((desc, i) => (
                    <li key={i}>{desc}</li>
                  ))}
                </ul>
                <div className="read-more-btn mt--40">
                  <Link className="btn-default" to="/about-us#top">
                    <span>More About Us</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LabHighlights;
