import React from 'react';
import Layout from '../../partials/Layout';
import PageScrollTop from '../../utils/PageScrollTop';
import SEO from '../../partials/components/SEO';
import * as Section from './sections/individuals/Pathology';
import { GeneralComponentsStyle } from '../styles/GeneralComponentsStyle';

//CONTENT
import { hereditaryHeart as content } from './sections/individuals/content/pathology';

const Pathology = () => {
  const { theme, pageTitle } = content;

  return (
    <Layout>
      <PageScrollTop>
        <GeneralComponentsStyle theme={theme} className={pageTitle}>
          <SEO title="Pathology" />
          <Section.HeroBannerPathology theme={theme} content={content.sliderArea} btn={false} />
          <Section.WhatIsPathology content={content.pureArticle} theme={content.theme} bgColor={'#f4f5f9'} />
        </GeneralComponentsStyle>
      </PageScrollTop>
    </Layout>
  );
};

export default Pathology;
