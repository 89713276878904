import React from 'react';
import SectionTitle from './components/SectionTitle';
import Cards from './components/Cards';
import { StyledIlluminaVideos } from './styles/StyledIlluminaVideos';

const IlluminaVideos = ({ content, bgColor }) => {
  return (
    <StyledIlluminaVideos className="rn-section-gap" style={{ backgroundColor: bgColor }}>
      <div className="container">
        <SectionTitle {...content.titleSection} />
        <Cards content={content.cards} />
      </div>
    </StyledIlluminaVideos>
  );
};

export default IlluminaVideos;
