import { createGlobalStyle, styled } from 'styled-components';
import device from './mediaSizes';

const GlobalStyles = createGlobalStyle`
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  body {
    background-color: #fff;
    color: #342a47;
    font-family: 'Prompt',sans-serif;
    /* font-size: 16px; */
  }

  a {
    text-decoration: none;
    outline: none;
  }

  .img-round-corners {
    border-radius: 9px;
  }

  img-w {
    width: 20px !important;
  }

  select {
    background: url(/images/decor/decor-form-select-chevron.svg) right 1.75rem center/13px 10px no-repeat;
    background-color: #fff;
  }

  .nmr-10 {
    margin-right: -10px !important;
  }

  .nml-10 {
    margin-left: -10px !important;
  }

  .container {
    width: 100%;
    max-width: 1213px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
  }

  .rn-section-gap {
    padding: 135px 0 !important;
    @media ${device.md} {
      padding: 80px 0 !important;
    }
    @media ${device.sm} {
      padding: 60px 0 !important;
    }
  }

  .rn-section-gap-special {
    padding: 135px 0 !important;
    @media ${device.md} {
      padding: 80px 0 !important;
    }
    @media ${device.sm} {
      padding: 60px 0 0 0 !important;
    }
  }

  .no-footer {
    /* height: auto; */
    @media ${device.sm} {
      height: auto;
    }
  }

  .heading-1 {
    margin-top: 0;
    margin-bottom: 29px;
    font-family: Lora, sans-serif;
    font-size: 65px;
    line-height: 80px;
    font-weight: 400;
  }

  .heading-1-modified {
    font-size: 52px;
  }

  .heading-2 {
    margin-top: 0;
    margin-bottom: 29px;
    font-family: Lora, sans-serif;
    font-size: 45px;
    line-height: 58px;
    font-weight: 400;
  }

  .heading-3 {
    font-family: Prompt, sans-serif;
    color: #342a47;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
  }

  .heading-4 {
    color: #5f5a6a;
    font-size: 24px;
    font-weight: 300;
    font-family: Prompt, sans-serif;
    line-height: 34px;
    width: auto;
  }

  .heading-5 {
    margin-bottom: 14px;
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
  }

  .text-block-1 {
    font-size: 20px;
  }

  .text-block-2 {
    font-size: 16px;
  }

  .text-block-3 {
    font-size: 14px;
  }

  .text-block-light {
    color: #fff !important;
  }

  p{
    margin-bottom: 15px;
    @media ${device.lg}{

    }
    @media ${device.md}{
      margin-bottom: 12px;
    }
    @media ${device.sm}{
      margin-bottom: 12px;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }

  .theme-gradient {
     /* background: -moz-linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98% / 200% 100%;
     background: -webkit-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98% / 200% 100%;
     background: -webkit-linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98% / 200% 100%;
     background: -o-linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98% / 200% 100%;
     background: -ms-linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98% / 200% 100%; */
     /* text-transform: capitalize; */
     background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98% / 200% 100%;
     background-clip: text;
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
  }

  .inner-pad {
    padding-top: 40px;
  }

  // BUTTON DEFAULT
  a,
  button {
    &.btn-default {
      padding: 0 28px;
      border-style: solid;
      border-width: 2px;
      border-color: transparent;
      border-radius: 8px;
      background-color: transparent;
      /* background: #800a88; */
      background: #4557ce;
      transition-duration: 300ms;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      text-decoration: none;
      text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
      display: inline-block;
      height: 50px;
      line-height: 47px;
      letter-spacing: 0.5px;
      width: auto;

      &.btn-icon {
        .icon {
          position: relative;
          top: -1px;
          padding-left: 4px;
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
      &:hover {
        box-shadow: 0 0 20px 5px rgba(251, 83, 67, 0.2);
        -webkit-filter: brightness(125%);
        filter: brightness(125%);
        color: #fff;
        text-decoration: none;
      }
      &.btn-small {
        height: 40px;
        line-height: 36px;
        padding: 0 20px;
        font-size: 14px;
        font-weight: 500;
      }
      &.btn-medium {
        height: 65px;
        line-height: 63px;
        padding: 0 40px;
        @media ${device.sm} {
          padding: 0 30px;
        }
      }
      &.btn-large {
        height: 60px;
        line-height: 56px;
        padding: 0 37px;
        font-size: 17px;
        @media ${device.sm} {
          padding: 0 30px;
        }
      }
      &.btn-extra-large {
        height: 90px;
        line-height: 90px;
        padding: 0 70px;
        font-size: 20px;
        @media ${device.sm} {
          padding: 0 44px;
        }
      }
      &.btn-border {
        background-image: inherit;
        border-color: hsla(0, 0%, 100%, 0.1);
        border-width: 2px;
        border-style: solid;
        &:hover {
          background-color: #fb5343;
          border-color: #fb5343;
        }
      }
      &.text-underline {
        background-image: inherit;
        border-color: #fff;
        span {
          position: relative;
          display: inline-block;
          line-height: 30px;

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            background-color: currentColor;
            transition: background-color 0.25s ease-in-out,
              width 0.36s cubic-bezier(0.51, 0.5, 0.07, 0.99);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            width: 0px;
            bottom: 3px;
          }
        }

        &:hover {
          box-shadow: none;

          span {
            &::after {
              width: 100%;
            }
          }
        }
      }

      &.round {
        border-radius: 100px;
      }

      &.square {
        border-radius: 0;
      }

      &.rounded-player {
        border-radius: 100%;
        width: 100px;
        height: 100px;
        padding: 0;
        line-height: 100px;
        font-size: 30px;
        display: inline-block;
        @media ${device.sm} {
          width: 70px;
          height: 70px;
          line-height: 70px;
        }
        span {
          svg {
            position: relative;
            top: -5px;
            left: 2px;
          }
        }

        &.border {
          background-image: inherit;
          border-color: #fff;
        }

        &.sm-size {
          width: 70px;
          height: 70px;
          line-height: 70px;
        }
      }
    }
  }

  // SPECIAL CLASSES

  .merge-hero {
    padding-top: 220px;
    @media ${device.lg} {
      padding-top: 185px;
    }
    @media ${device.md} {
      padding-top: 160px;
    }
    @media ${device.sm} {
      padding-top: 85px;
    }
  }

  .popup-content {
    animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  }

  .popup-overlay {
    background: rgba(0, 0, 0, 0.5);
  }

  .text-button {
    padding-right: 0;
    padding-left: 0;
    border: none;
    font-weight: 500 !important;
    color: #800a88 !important;
    text-decoration: underline 3px solid #b3bcfb;
    /* border-bottom: 3px solid #b3bcfb; */
    background-color: transparent;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-family: Prompt, sans-serif;
    line-height: 10px;
    font-weight: 400;
    &:hover {
      text-decoration: underline 3px solid #7c8cfb;
      /* border-bottom-color: #7c8cfb; */
      color: #4557ce;
    }
    @media ${device.md}{
      text-decoration: underline 2px solid #b3bcfb;
      /* border-bottom: 1.5px solid #b3bcfb; */
      &:hover{
        text-decoration: underline 1px solid #7c8cfb;
      }
    }
  }


  @media ${device.lg} {
    .container {
      width: 100%;
      max-width: 960px;
      margin-right: auto;
      margin-left: auto;
      padding-right: 20px;
      padding-left: 20px;
    }

    .heading-1 {
      font-size: 42px;
      line-height: 65px;
    }

    .heading-1-modified {
      font-size: 36px;
    }

    .heading-2 {
      font-size: 35px;
      line-height: 2;
    }

    .heading-4 {
      color: #5f5a6a;
      font-size: 18px;
      font-weight: 300;
      font-family: Prompt, sans-serif;
      line-height: 27px;
      width: 80%;
    }

    .heading-5 {
      margin-bottom: 14px;
      font-size: 17px;
      line-height: 30px;
      font-weight: 600;
    }

    .text-block-1 {
      font-size: 18px;
    }

    .text-block-2 {
      font-size: 14px;
    }

    .text-block-3 {
      font-size: 12px;
    }
  }

  @media ${device.md} {
    .container {
      width: 100%;
      max-width: 698px;
      margin-right: auto;
      margin-left: auto;
      padding-right: 15px;
      padding-left: 15px;
    }

    .heading-1 {
      margin-top: 0;
      margin-bottom: 29px;
      font-size: 34px;
      line-height: 52px;
    }

    .heading-1-modified {
      font-size: 29px;
    }

    .heading-2 {
      margin-top: 0;
      margin-bottom: 15px;
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
    }

    .heading-4 {
      color: #5f5a6a;
      font-size: 14px;
      font-weight: 300;
      font-family: Prompt, sans-serif;
      line-height: 24px;
      width: auto;
      min-width: 0;
    }

    .heading-5 {
      margin-bottom: 14px;
      font-size: 14px;
      line-height: 22px;
      font-weight: 600;
    }

    .text-block-1 {
      font-size: 14px;
    }

    .text-block-2 {
      font-size: 12px;
      line-height: 20px;
    }

    .text-block-3 {
      font-size: 10.5px;
    }
  }

  @media ${device.sm} {
    br {
      // display: none !important;
    }

    .container {
      width: 100%;
      max-width: 350px;
      margin-right: auto;
      margin-left: auto;
      padding-right: 15px;
      padding-left: 15px;
    }

    .heading-2 {
      margin-top: 0;
      margin-bottom: 15px;
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
    }

    .text-block-1 {
      font-size: 12px;
    }

    .sm-col-p {
      padding-bottom: 40px;
    }
  }
`;

export default GlobalStyles;
