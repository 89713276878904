import { createGlobalStyle } from 'styled-components';

const AnimationStyles = createGlobalStyle`

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .fadeIn {
    animation: fadeIn 2s ease-out;
  }

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }

  .fadeInUp {
    animation: fadeInUp 1s ease-out;
  }

  // MENU ANIMATION
  @keyframes headerSticky {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
 }

  // POPUP ANIMATION
  @keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }
`;

export default AnimationStyles;
