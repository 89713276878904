import React from 'react';
import SEO from '../../partials/components/SEO';
import Layout from '../../partials/Layout';
import PageScrollTop from '../../utils/PageScrollTop';
import * as Section from './sections/providers/ProvHereditaryCancer';
import { GeneralComponentsStyle } from '../styles/GeneralComponentsStyle';

//CONTENT
import { provHereditaryCancer as content } from './sections/providers/content/provHereditaryCancer';

const ProvHereditaryCancer = () => {
  const { theme, pageTitle } = content;
  return (
    <Layout>
      <PageScrollTop>
        <GeneralComponentsStyle className='general-components' theme={theme}>
          <SEO title="Hereditary Cancer Test" />
          <Section.PageHeroProvCancer content={content.pageHeroProvCancer} bgColor={'#fff'} />
          <Section.WhatIsGeneticTesting theme={theme} content={content.whatIsGeneticTesting} bgColor={'#f4f5f9'} pageTitle={pageTitle} btn={false} mergeHero="merge-hero" />
          <Section.HereditaryCancerTesting theme={theme} content={content.hereditaryCancerTesting} bgColor={''} mergeHero="" />
        </GeneralComponentsStyle>
      </PageScrollTop>
    </Layout>
  );
};

export default ProvHereditaryCancer;
