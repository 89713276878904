const phoneNumberFormatter = e => {
  const x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
};

export const content = {
  title: 'Write Us A Message',
  formStructure: [
    //SELECTS
    {
      el: 'select',
      label: 'Refer to me as',
      attributes: {
        name: 'referer',
        id: 'referer',
        required: true,
      },
      options: [
        {
          value: '',
          content: 'Select an option...',
          selected: true,
          disabled: true,
        },
        {
          value: 'An Individual or Patient',
          content: 'Patient',
        },
        {
          value: 'Physician',
          content: 'Physician',
        },
        {
          value: 'Provider or Clinic',
          content: 'Provider or Clinic',
        },
      ],
    },
    // INPUTS
    {
      el: 'input',
      label: 'Enter Your Name',
      attributes: {
        type: 'text',
        name: 'full_name',
        id: 'full_name',
        placeholder: 'Full name',
        required: true,
      },
    },
    {
      el: 'input',
      label: 'E-mail address',
      attributes: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'john@example.com',
        required: true,
      },
    },
    {
      el: 'input',
      label: 'Phone Number:',
      attributes: {
        type: 'text',
        name: 'phone',
        id: 'phone',
        placeholder: 'Enter just numbers',
        onChange: phoneNumberFormatter,
        required: false,
      },
    },
    {
      el: 'input',
      label: 'Subject:',
      attributes: {
        type: 'text',
        name: 'subject',
        id: 'subject',
        placeholder: 'What is the topic?',
        required: true,
      },
    },
    // TEXTAREA
    {
      el: 'textarea',
      label: 'Message:',
      attributes: {
        name: 'message',
        id: 'message',
        placeholder: 'Write your message here...',
        required: true,
      },
    },
  ],
};
