import React from 'react';

export const provPathology = {
  pageTitle: 'ProvPathology',

  pageHeroProvPathology: {
    pageHero: {
      title: 'Pathology',
      subtitle:
        'iPath Life Sciences is an industry leader in assessing the specific requirements of your practice, in order to develop an in-office pathology model that optimizes patient care and ancillary revenue opportunities for you and your practice.',
      mainImg: '/images/pages/pathology-hero.svg',
      decorImg: null,
    },
  },

  pageHero: {
    title: 'Pathology',
    subtitle:
      'iPath Life Sciences is an industry leader in assessing the specific requirements of your practice, in order to develop an in-office pathology model that optimizes patient care and ancillary revenue opportunities for you and your practice.',
    // mainImg: 'https://media.istockphoto.com/id/1210627236/photo/scientist-looking-through-the-microscope.jpg?s=612x612&w=0&k=20&c=KdcLp8XW2qKtmVnO0LZMaBHug8aPWB2N1gjKwK3cLyk=',
    mainImg: '/images/pages/pathology-hero.svg',
    decorImg: null,
  },

  ourServices: {
    sectionTitle: {
      bigTitle: 'Our Services',
    },
    checkLists: {
      listOne: {
        title: null,
        intro: null,
        imgCheck: '/images/decor/single-thick-orange.svg',
        content: [
          {
            checkText: 'Dermatopathology',
            innerList: null,
          },
          {
            checkText: 'Surgical Histopathology',
            innerList: null,
          },
          {
            checkText: 'Gastrointestinal Pathology',
            innerList: null,
          },
          {
            checkText: 'Urologic Pathology',
            innerList: null,
          },
          {
            checkText: 'Gynecological Pathology',
            innerList: null,
          },
          {
            checkText: 'Podiatric Pathology',
            innerList: null,
          },
          {
            checkText: 'Immunohistochemistry',
            innerList: null,
          },
          {
            checkText: 'FNA-Cytology',
            innerList: null,
          },
          {
            checkText: 'Molecular Pathology',
            innerList: null,
          },
        ],
      },
    },
    numberedList: {
      title: 'Special offerings:',
      items: [
        {
          title: 'Slide Preparations:',
          desc: 'iPath Life Sciences helps facilitate physicians who prefer to read their own slides, or who have an in-house pathologist, but not their own processing laboratory. These physicians can have their specimens sent to iPath Life Sciences and have slides prepared for them within 24 hours.This service includes complimentary diagnostic reports that are customized and preprinted to your specifications.',
        },
        {
          title: 'Special Stains and immunohistochemistry:',
          desc: ' iPath Life Sciences keeps a menu of over 75 special stains and immunohistochemistry in-house to ensure accuracy in our diagnoses. Any stain that we don’t have in-house can be obtained within 24 hours.In house stains and immunohistochemistry',
        },
        {
          title: 'Dedicated and Personable Consultant:',
          desc: 'Each patient of iPath Life Sciences is assigned a Personal Laboratory Consultant that will spend time learning about your practice and what is particularly important to you. We customize our services to provide time-saving solutions to make sending your specimens and receiving your diagnoses quicker and easier than ever before. iPath Life Sciences Consultants are available to you and your staff 24 hours a day, 7 days a week. We will be glad to supply you with references, Curriculum Vitae, or any other information that may help you in your pathology decisions.',
        },
        {
          title: 'Courier Service (Florida):',
          desc: 'iPath Life Sciences employs a full staff of in-house couriers that drive to our Florida based patients in company-owned Mini Coopers. Through having a fleet of in-house couriers, iPath Life Sciences can ensure a timely and personalized pick up of specimens and drop off of results and supplies.',
        },
      ],
    },
  },

  beginWorkingTogether: {
    orderedList: {
      title: 'Once We Begin Working Together, Your Office Will Benefit From:',

      list: [
        '24-Hour Turnaround on All Results.',
        'Our 9-Step Quality Assurance Program ensures your slides and reports in the least amount of time, without any error.',
        'Transcription Services, to record all of your diagnoses.',
        'Completely Customizable Requisitions, Reports and the ability to “Go Paperless.”',
        'A Selection of Renowned Board-Certified Dermatopathologists available for consultations.',
        'A comprehensive menu of over 75 special stains which are stored in-house.',
        'Personal Laboratory Consultant available 24 hours a day, 7 days a week.',
        'As well as additional ancillary services, such as Mobile Frozen Section Units available upon your request',
      ],
    },
  },

  inOfficePathologyModels: {
    pureArticle: {
      textLight: false,
      decorImg: null,
      decoratedTitle: null,
      regularTitle: 'In Office Pathology Models',
      sideText: null, //array
      pLeft: [
        'iPath Life Sciences is an industry leader in assessing the specific requirements of your practice, in order to develop an in-office pathology model that optimizes patient care and ancillary revenue opportunities for you and your practice.',
        'iPath Life Sciences assists with the implementation of techniques that will aid your facility in becoming more efficient and effective.',
        `For more detailed assistance in determining whether to build your own laboratory, and what type of laboratory to build, please feel free to click the link to read Wes Moschetto’s 2010 published article:`,
        // <a target="_blank" key={900} href="http://i-pathology.net/doc/which_model.pdf" className="text-button text-block-2">
        //   Which In-Office Model Is Right For You?
        // </a>,
      ],
      pRight: null,
    },
    checkLists: {
      listOne: {
        title: 'Some of the benefits to allowing iPath Life Sciences to assist you in building an autonomous, self-sufficient laboratory, include:',
        intro: null,
        imgCheck: '/images/decor/single-thick-orange.svg',
        content: [
          {
            checkText:  'Personalized assessment of the feasibility, clinical benefits, and requirements of your office, to develop a plan designed to meet your specific needs',
            innerList: null,
          },
          {
            checkText: 'We will help find and negotiate a salary for histotechnologists, transcriptionists, and a staff-pathologist of your choosing, if necessary.',
            innerList: null,
          },
          {
            checkText: 'iPath Life Sciences will assist in the completion of all necessary applications and filings for State and Federal licenses, inspections, and regulatory approvals. We will make sure all HIPAA regulations are met, as well as ensure their continued compliance.',
            innerList: null,
          },
          {
            checkText: 'We ensure the initial set-up of all lab equipment and computer programs (Pathology Reporting Software), as well as provide ongoing maintenance and support.',
            innerList: null,
          },
          {
            checkText: 'As a larger facility, iPath Life Sciences has special pre-negotiated discounted rates on supplies, chemicals, and reagents with industry vendors to which your laboratory would be welcome to take advantage of',
            innerList: null,
          },
          {
            checkText: 'iPath Life Sciences will assist in establishing a billing system, so you get paid for the pathology work you perform, for Medicare, Medicaid and other third party payors.',
            innerList: null,
          },
          {
            checkText: 'iPath Life Sciences has analyzed, gone through demonstrations, and built relationships with several EMR platforms, so we can assist your office in determining which product best fits your specific needs. Whether you prefer eClinical Works, Modernizing Medicine, Advanced MD, NextGen, Clinix MIS, NexTech, or Sage, we have the ability to assist you with choosing the right brand for you, and work with your office under the new Healthcare regulations and the “Meaningful Use” criteria.',
            innerList: null,
          },
        ],
      },
    },
    tailText: {
      title: 'Our Commitment',
      list: [
        'Adherence to all applicable state and federal anti-kickback and self-referral statutes as well as Stark Laws, including but not limited to 42 U.S.C. § 1320a-7b(b) (Anti-Kickback Laws) and 42 U.S.C. § 1395nn (“Stark Laws”) via personal services and management contracts safe harbor under 42 C.F.R. § 1001.952(d) and 42 U.S.C. 1395nn(e)(3), respectively.',
        'iPath Life Sciences will assure continued compliance with the Terms of Accreditation for the Agency for Health Care Administration (AHCA), through comprehensive Quality Control and Quality Assurance measures.',
      ],
    },
  },

  theme: {
    themeColor: 'orange',
    pageTitle: 'ProvPathology',
    mainColor: '#ff5858',
    btnColor: '#ff5858',
    mainGrad: 'linear-gradient(95deg, #080808 15%, #3f1c52 45%, #f01fff 75%, #44015f 100%) 95% / 200% 100%;',
    secColor: ' #b70203',
    thirdColor: '#ffdede',
    forthColor: '#fef3f4',
    dropColor: '#fad8d8ff',
  },
};
