export const hereditaryHeart = {
  pageTitle: 'Heart',
  sliderArea: {
    title: ['Hereditary Heart', 'Disease Test'],
    subtitle: 'Understand how inherited heart conditions can affect your health.',
    description: [
      'Heart disease is often the result of poor lifestyle choices, but genetic heart disease is , to changes in genes, which we inherit from our parents and can pass down to our children.',
      'With genetic testing we can help you by determining your risk factors based on your DNA, so you can take steps now, to reduce complications in the future.',
    ],
    img: '/images/pages/heart-hero.svg',
  },

  PicTextCTOSpecial: {
    img: '/images/pages/heart-genomics-2.svg',
    imgMobile: '/images/pages/heart-genomics-mobile.svg',
    regularTitle: 'Managing a heart condition is possible if you know about it.',
    largeTitle: null,
    subtitle: 'Finding out early if you have or are prone to a chronic or mild heart condition could save your life.',
    p: [null],
  },

  benefitsHeart: {
    title: 'Benefits of our Hereditary Heart Test include:',

    list: [
      'World-Class 24/7 Customer Support',
      'Qualifies you for future screening for other conditions',
      'Cost covered by many medical insurance plans',
      'Quick reporting turnaround times',
      'Clear, accurate, easy to understand reports',
      'Professional follow-up support with a genetic counsellor',
    ],
  },

  whoNeedsThis:{
    sectionTitle:{
      bigTitle: 'Who needs this test?',
      subtitle: 'Nearly half of all U.S. adults already have or will develop some type of cardiovascular disease',
      description: 'Getting unique insight into inherited heart conditions can help you and your healthcare provider build a heart health plan that can benefit your future health',
    },

    checkLists: {
      listOne: {
        title: null,
        intro:[
          'Inherited heart conditions are passed on through families and are caused by a change or mutation in one or more of our genes. Most people who inherit these conditions don’t know they are at risk and don’t suffer symptoms until it’s too late.',
          'Left unchecked and untreated, inherited heart conditions can lead to serious problems such as coronary heart disease, heart attacks, sudden cardiac arrest, or even heart failure.',
          'Individuals who qualify for the test include:',
        ],
        imgCheck: '/images/decor/single-thick-orange.svg',
        content: [
          {
            checkText: 'People with a personal history of cardiac diseases.',
            innerList: [
              'Thickened or thinned heart muscle (Cardiomyopathy)',
              'Irregular heart rate (Arrhythmia)',
              'Affected with aortic aneurysm (Thoracic, Abdominal)',
              'Atherosclerosis',
              'Coronary Artery Disease (CAD)',
              'Born with any Heart Defects (Congenital Heart Defects)',
              'Valvular Heart Disease',
              'Vascular disease (Blood Vessel Disease)',
            ],
          },
          {
            checkText: 'People who have experienced the following:',
            innerList: [
              'Sudden or early heart attack',
              'Cardiac Arrest',
              'Heart failure or a transplant',
              'Elevated or high cholesterol levels',
              'Stroke',
              'Unstable Angina',
              'Had a pacemaker installed',
            ],
          },
        ],
      },
    }
  },

  howItWorks: {
    sectionTitle: {
      bigTitle:'How it works',
      description: 'We’re driven by our commitment to deliver exceptional experiences to our customers. We value our strong customer relationships and are defined by them.',
    },
    steps: {
      counterTitle: 'Step',
      steps: [
        {
          stepTitle: 'Do you qualify?',
          stepDesc: 'Fill in our intake questionnaire, online or via phone. We will assess your request and share it with your Primary Care to conduct a consult with you. If the physician approves your request, we will send you a kit in the mail, at no charge.',
        },
        {
          stepTitle: 'Receive Your Test Kit',
          stepDesc: 'Once you receive your kit, follow the instructions inside and administer the test on yourself. You can also visit your local health care provider and have them conduct it for you.',
        },
        {
          stepTitle: 'Send Kit To Lab',
          stepDesc: 'Place your sample back in the kit. Make sure the pre-paid sticker is placed outside of the kit, and drop it off in your mailbox for pickup. ﻿',
        },
        {
          stepTitle: 'Processing & Report',
          stepDesc: 'Get results sent to you and your physician via email as well as a hard copy sent to your house.',
        },
      ],
    },
  },

  picTextPayZero: {
    img: '/images/pages/heart-patient.png',
    imgMobile: '/images/pages/heart-patient.png',
    regularTitle: 'Most insured patients pay $0',
    largeTitle: null,
    specialTitle: null,
    subtitle: null,
    p: [
      'iPath Life Sciences tests are covered by Medicare and most major insurers.',
      'Only 4% of patients have insurance plans which do not have coverage for our services. More than 96% of iPath Life Sciences patients have no out-of-pocket cost for screening.',
    ],
  },

  faqArea: {
    sectionTitle: {
      bigTitle: 'Frequently Asked Questions'
    },
    accordionBS:{
      items: [
        {
          title: 'Is iPath Life Sciences’s Genetic Testing Covered by Health Insurance?',
          text: 'In most cases, your health insurance or medicare will cover the cost of testing if genetic testing is recommended to you by your doctor. If you are interested in ordering genetic tests, you should contact your insurance company beforehand and ask about coverage.',
        },
        {
          title: 'What Does iPath Life Sciences Do To Protect My Privacy?',
          text: 'iPath Life Sciences guarantees that we will not share your or your family analysis information with ANY third party without your explicit permission. We do not provide information to law enforcement without a valid court-ordered request. We support the Genetic Information Nondiscrimination Act (GINA) and other laws that protect individuals from being discriminated against based on their genetics.',
        },
        {
          title: 'When will I get results?',
          text: 'Our goal is to provide you with an accurate genetic test result in a reasonable time period. While most testing periods have a relatively consistent timeframe, a number of factors contribute to estimations for time of delivery, such as the size of the gene and the complexity of the interpretation. Our average turnaround time for genetic testing is usually 3-4 weeks.',
        },      
        {
          title: 'Can I cancel if I want to?',
          text: 'If for any reason throughout the process, you decide not to move forward with our tests, iPath Life Sciences will cancel your request immediately.',
        }
      ]
    },
  },

  theme: {
    themeColor: 'orange',
    pageTitle: 'Heart',
    mainColor: '#ff5858',
    btnColor: '#ff5858',
    mainGrad:
      'linear-gradient(95deg, #080808 15%, #3f1c52 45%, #f01fff 75%, #44015f 100%) 95% / 200% 100%;',
    secColor: ' #b70203',
    thirdColor: '#ffdede',
    forthColor: '#fef3f4',
  },
};
