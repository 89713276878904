import React from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import Video from './components/Video';
import { FiArrowRight } from 'react-icons/fi';
import { StyledCallToAction } from './styles/StyledCallToAction';

const callToActionData = {
  title: 'iPath Life Sciences is Proud to Offer Molecular Diagnostic Testing!',
  subtitle:
    'iPath Life Sciences can now provide healthcare professionals a more accurate method for prescribing medications, while providing individual patients with a safe, simple and affordable opportunity to explore the medical secrets stored in their personal genetic profile.',
  btnText: 'Learn More',
};

const scrollOptions = {
  animateIn: 'fadeInUp',
  animateOut: 'fadeInOut',
  animateOnce: true,
};
const CallToAction = ({ bgColor }) => {
  return (
    <StyledCallToAction>
      <div className="rwt-callto-action-area rn-section-gap" style={{ backgroundColor: bgColor }}>
        <div className="wrapper">
          <div className="rn-callto-action clltoaction-style-default style-5">
            <div className="container">
              <div className="row row--0 align-items-center content-wrapper theme-shape">
                <div className="col-lg-12">
                  <div className="inner">
                    <div className="content text-center">
                      <ScrollAnimation {...scrollOptions}>
                        <h2 className="title">{callToActionData.title}</h2>
                      </ScrollAnimation>
                      <ScrollAnimation {...scrollOptions}>
                        <h6 className="subtitle">{callToActionData.subtitle}</h6>
                      </ScrollAnimation>
                      <ScrollAnimation {...scrollOptions}>
                        <Video />
                      </ScrollAnimation>
                      <ScrollAnimation {...scrollOptions}>
                        <div className="call-to-btn text-center">
                          <Link className="btn-default btn-icon" to="/genomics/cancer">
                            {callToActionData.btnText}{' '}
                            <i className="icon">
                              <FiArrowRight />
                            </i>
                          </Link>
                        </div>
                      </ScrollAnimation>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledCallToAction>
  );
};

export default CallToAction;
