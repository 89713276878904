export const tppcontaent = [
  {
    paragraphs: [
      <span className='toUpperCase'>WELCOME TO iPath Life Sciences, LLC (“COMPANY”) WEBSITE. THE COMPANY IS BASED IN LAKELAND, FL.</span>,
      <>
        YOUR ACCESS OF THIS OR ANY OTHER COMPANY-ASSOCIATED WEBSITE (COLLECTIVELY, “WEBSITE”) OR ANY INFORMATION AND ANY OTHER FEATURE THEREIN IS SUBJECT TO THESE TERMS AND CONDITIONS (THE “TERMS”).
        YOU ACKNOWLEDGE THAT THE COMPANY MATERIALS AND INFORMATION IS PROPER CONSIDERATION FOR YOUR AGREEMENT TO THESE TERMS.
      </>,
      <>
        BY ACCESSING THE WEBSITE IN ANY WAY — INCLUDING WITHOUT LIMITATION, BY BROWSING, ACTING OR FAILING TO ACT ON, OR DIRECTLY OR INDIRECTLY USING ANY INFORMATION FOUND HEREIN, OR SUBMITTING
        INFORMATION TO THE COMPANY — YOU AGREE AND ARE BOUND TO THE TERMS, DISCLAIMERS, CONDITIONS, POLICIES AND NOTICES CONTAINED IN THIS TERMS PAGE, INCLUDING, BUT NOT LIMITED TO, DISCLAIMERS OF
        WARRANTIES, DAMAGE AND REMEDY LIMITATIONS AND A CHOICE OF TEXAS LAW. IF YOU DO NOT ACCEPT OR ABIDE BY THESE TERMS, YOU MUST LEAVE THE WEBSITE AND DISCONTINUE ITS FURTHER USE.
      </>,
      <>
        The Company may, in its sole discretion, at any time and for any reason, with or without notice, revise and update these Terms. Any such modification will become effective upon posting of an
        updated version of these Terms. It is your responsibility to review these Terms on a regular basis to ensure you are familiar with its most recent version. If you continue to use the Website
        after any such modification has been made, you thereby signify your acceptance of the Terms as modified.
      </>,
      <>
        Please note that these Terms are valid for (and apply to) the Website and all social media activities of, or on behalf of, Company. Accordingly, the Terms apply to this Website and any other
        internet material/page administered, owned, managed, contributed to, or inspired by Company. It also includes any Company activity on, without limitation, WhatsApp, LinkedIn, YouTube, Google,
        Yahoo, and any other media/social platforms as well as any other separate form of marketing/media content – and whether as text, audio, video, books, or brochures that currently exist or may
        exist in future (collectively the, <strong>“Company Materials”</strong>). The terms <strong>“We,” “Us</strong>,” and <strong>“Our”</strong> refer to the Company. “You” refers to you, as a user
        of our Website, Information, Company Materials or our Services (as defined in these Terms). By accessing, acting or failing to act on, or directly or indirectly using our Website, Information,
        Company Materials or our Services, You acknowledge and agree to these Terms.
      </>,
    ],
  },
  {
    title: <>Introduction</>,
    paragraphs: [
      <>
        The contents of this Website, our Company Materials and Services are not intended to provide, without limitation, medical advice. Any information, idea, claim or statement (collectively,{' '}
        <strong>“Information”</strong>) cannot be construed by You (or anyone) as taking the place of either, among others, your treating physician’s or medical professional’s or your own ability to
        evaluate the Information whether pursuant to a rational evaluation basis or as part of a medical professional’s, among others, diagnostic and treatment clinical decisions. The Information,
        Company Materials and Services are comprised of, among others, ideas, suggestions, techniques, resources, and other materials, which are provided only as general information and may not be
        construed by anyone to constitute health, medical, or any other professional advice, opinion, diagnosis, prescription, or treatment.
      </>,
      <>
        For these reasons, you may not rely on Information and the Company shall not be held responsible or liable for misunderstanding or misuse of any Information in this Website, any Company
        Materials or as may be relevant to any Services. The Company is not responsible for any loss, damage, or injury caused, or alleged to be caused, directly or indirectly by any action, inaction
        or behavior brought on because or related in any way by any Information on the Website, any Company Materials or as may be germane to any Services. This general disclaimer is explicitly
        important if you act, fail to act or engage in any behavior on any Information — that you may understand or interpret to explicitly, implicitly or directly or indirectly relate to future
        events or results and such events or results do not materialize.
      </>,
      <>
        Of course, we hope that you find that the contents of this Website and Company Materials to be beneficial. Nonetheless, you must agree that future events or results may or will vary from
        person to person. Neither the Company or its owners, employees or agents can guarantee any future outcome that may result from, or be related to, any Information contained in the Website or
        any Company Materials or relevant to any Services. We are not liable for factual or other inaccuracies, any of which may differ in different peoples’ minds, regarding any Information contained
        in the Website or Company Materials or as relevant to any Services
      </>,
      <>
        Please be aware that any Information presented in the Website, Company Materials or as may be germane to any Services are only illustrative, anecdotal and testimonial examples. If any, such
        Information may only be understood by you to reflect only what a specific individual’s experience has been in relation to Information, Company Materials or as germane to Services. Any future
        event or result mentioned that have been experienced by certain individuals (that is, having been achieved by some) may not to be construed as (or be expected to be) the outcome or result that
        you may achieve. You must understand that the Information, Company Materials and Services reflect only what we believe about these matters and, therefore, same may not be literally true. We
        fully encourage you to use your own reasoning and that of your healthcare professional that you trust in evaluating, acting or failing to act upon or relying on any Information, Company
        Materials or Services.
      </>,
    ],
  },
  {
    title: <>General Disclaimer</>,
    paragraphs: [
      <>
        You understand by viewing this Website, the Company Materials and Services you agree that all healthcare application of Information, Company Materials or Services is a matter is to be
        considered anecdotal with respect to the extent of their benefits, outcomes, results, effectiveness and even risks all of which are matters relegated to You and Your medical professional. We
        do not take the place of your Medical Professional, accordingly, any Information, Company Materials and Services are not intended to be understood or represent that they are or can be used to
        prevent, cure, treat or even diagnose any, among others, medical or psychological condition, issue or problem nor is any part of (directly or indirectly) meant to be understood as a substitute
        for you seeking the advice, services, treatment options and results that are (or may be) available from licensed professionals. The Company strongly advises that you seek professional advice
        regarding any decision to act or fail to act all as may be in your best interest pursuant to such professional advice. All Information, Company Materials (including, testimonials or examples)
        or Services referred to on this Website and elsewhere cannot and do not constitute a warranty, prediction, or guarantee regarding the results or outcome of any individual, including You, using
        (or relying on) same for any particular issue, condition, problem, or purpose. While the Company posts all Information, Company Materials and as germane to Services (including links and other
        resources) in good faith, their validity, accuracy, completeness, effectiveness, or usefulness cannot be guaranteed.
      </>,
    ],
  },
  {
    title: <>Medical Disclaimer</>,
    paragraphs: [
      <>
        If you are experiencing a medical emergency, call 911 to get medical help. Do not rely on any Information, Company Materials and/or Services contained on (or relevant) to this Website for
        assistance or guidance in regard to your imminent, urgent medical needs. This Information, Company Materials and/or Services presented in (or relevant to) the Website do not constitute medical
        advice and nothing contained on this Website is intended to constitute the practice of medicine. The Company does not guarantee that any Information contained on this Website reflects and
        represents the latest scientific and medical research status. In particular, the Information contained on this Website is meant to be general guidance and it shall not be deemed or understood
        to attempt to heal, cure, treat, or suggest treatment for a particular health condition you may experience or have questions about. Your medical healthcare and any diagnosis and treatment is a
        matter between you and your licensed professional healthcare provider. The content on this Website is not written and has not been approved by licensed medical doctors or medical
        professionals. The sole purpose of this Website is to provide general educational information. The Information, Company Materials and/or Services contained on (or relevant to) this Website is
        not intended or implied to be a substitute for professional medical advice, diagnosis, or treatment. You may not ignore, disregard professional medical advice or delay seeking professional
        medical treatment because of something you have seen, read, or heard, or accessed through the Website or as relevant to any Information or Company Materials.{' '}
      </>,
    ],
  },
  {
    title: <>HIPAA Disclaimer</>,
    paragraphs: [
      <>
        To fully serve You and to utilize certain functions of our Website technology, we may need to collect certain protected health information (PHI) from you. In order to comply with the Health
        Insurance Portability and Accountability Act of 1996 (HIPPA), we will treat your PHI as confidential information and we will not disclose this information to anyone other than as necessary to
        provide you with products and services. We take strict steps to ensure there are technical, physical and administrative safeguards in place to keep your protected health information (PHI)
        safe. Please see additionally, our Website’s <strong>Notice of Privacy Practices Pursuant to the Health Insurance Portability and Accountability Act of 1996 (HIPAA).</strong>
      </>,
    ],
  },
  {
    title: <>Privacy Statement</>,
    paragraphs: [
      <>
        We are committed to maintaining robust privacy protections for Website users. Our Privacy Policy (the “Privacy Policy”) is designed to help You understand how we collect, use and safeguard any
        information You provide to Us and to assist You in making informed decisions when using Information, Company Materials or Services contained on (or relevant to) this Website. The term
        “Computer” refers to and includes without limitation any computing or communications (including cell phones) device or manner that is, or may be, used to access the internet or the Website.
        “Services” refers to the Company’s services accessed via the Website. The Privacy Policy applies whether you visit this Website to search, research, browse, or for purposes of self-education,
        noting that generally, we use relevant information to respond to your questions or requests, to improve the Website, to communicate with you about our relationship, and for other proper
        business purposes.
      </>,
    ],
  },
  {
    title: <>Information We Collect</>,
    paragraphs: [
      <>
        We may collect “Non-Personal Information” and “Personal Information.” Non-Personal Information includes information that cannot be used to personally identify You, such as anonymous usage
        data, general demographic information we may collect, referring/exit pages and URLs, platform types, preferences You submit and preferences that are generated based on the data You submit and
        number or other markersof “clicks.” Personal Information includes your email, name, phone number, which You may decide to submit to us through the registration process at the Website, if any.
      </>,
      <>
        We may use so-called cookies and web beacons. In this context, computer cookies are data sets sent by (or related to) our Website and are downloaded and stored on your Computer. Cookies
        connect your Computer device with Company‘s computer/electronic system(s) and they allow us to track and monitor how You use our Website.{' '}
        <strong>You have an option to opt out by turning off the cookie acceptance function on your Computer.</strong> Other cookies may be stored to your Computer by external vendors or third parties
        when our Website uses software, sponsored links, or adverts. Company is not responsible for You visiting external links.
      </>,
      <>
        Whether You visit this Website to search, research, browse, or for purposes of self-education, Company may collect general information about the patterns in which You use our Website (such as
        topics of interests, the duration of your visit, the type of web browser used, your IP address, the route that led You to visit our Website, the Website that You visited when You left our
        Website, your CPU speed, clickstreams, and traffic patterns). Company may use this information to better understand a visitor’s interest in order to adjust and improve our Website. Generally,
        we use this information to respond to your questions or requests, to improve the Website, to communicate with You about our relationship, and for other business purposes.
      </>,
    ],
  },
  {
    title: <>Information Collected via Technology</>,
    paragraphs: [
      <>
        To activate the Services, You may need to submit Personal Information in addition to your email address. To use the Services thereafter, You may need to submit further Personal Information.
        However, in an effort to improve the quality of the Services, we track information provided to Us by your browser or by our software application when You view or use the Services, such as the
        website You came from (known as the “referring URL”), the type of browser You use, the Computer from which You connected to the Service, the time and date of access, and other information that
        does not personally identify You. We track this information using cookies, or small text files which include an anonymous unique identifier. Cookies are sent to a user’s browser from our
        servers and are stored on the user’s computer hard drive. Sending a cookie to a user’s browser enables Us to collect Non-Personal Information about that user and keep a record of the user’s
        preferences when utilizing our Services, both on an individual and aggregate basis. Company may use both persistent and session cookies; persistent cookies remain on your Computer after You
        close your session and until You delete them, while session cookies expire when You close your browser.
      </>,
      <>
        Disclaimer: We reinforce that a patient can choose any lab company of their choice when performing genetic testing. Our Company acknowledged the receipt of your interest in genetic testing and
        has the capability to perform the test and at the same time, we give you, patients and doctors, the right to choose which lab performs the test.
      </>,
    ],
  },
  {
    title: <>Information You Provide Us By Registering for an Account, If Applicable</>,
    paragraphs: [
      <>
        In addition to the information provided automatically by your browser when You visit the Website, to become registered on the Website or a user of Services, You will create a personal profile.
        You can create a profile by registering relevant to Services and entering your email address, and creating a user name and a password. By registering, You are authorizing Us to collect, store
        and use your email address in accordance with this Privacy Policy.
      </>,
    ],
  },
  {
    title: <>Minor’s/Children’s Privacy</>,
    paragraphs: [
      <>
        The Website and the Services are not directed to anyone under the age of 18. The Website does not knowingly collect or solicit information from anyone under the age of 18, or allow anyone
        under the age of 18 to sign up for the Services. In the event that we learn that we have gathered personal information from anyone under the age of 18 without the consent of a parent or
        guardian, we will delete that information as soon as possible. If You believe we have collected such information, please contact us at compliance@ipathlifesciences.com.
      </>,
    ],
  },
  {
    title: <>How We Share Information</>,
  },
  {
    title: <>1.&nbsp;&nbsp;Personal Information</>,
    paragraphs: [
      <>
        Except as otherwise stated in this Privacy Policy, we do not sell, trade, rent or otherwise share for marketing purposes your Personal Information with third parties without your consent. We
        do share Personal Information with vendors who are performing services for the Company (unless subject to a Business Associate Agreement and as allowed by law), such as the servers for our
        email communications who are provided access to user’s email address for purposes of sending emails from Us. Those vendors use your Personal Information only at our direction and in accordance
        with our Privacy Policy or applicable law.
      </>,
      <>
        In general, the Personal Information You provide to Us is used to help Us communicate with You. For example, we use Personal Information to contact users in response to questions, solicit
        feedback from users, provide technical support, and inform users about promotional offers. If You share personal information by filling out forms or sending the Company an e-mail, we may
        utilize this information to contact You and archive your information. Notably, we do not share personal information with unauthorized third parties, nor do we sell personally identifiable
        information to advertising companies. We may, however and compliant with all privacy laws, share personal information with our IT partners and entities in charge of maintaining and marketing
        this Website or other third parties who perform services on our behalf any of which may be located outside of the United States. In any case, the Company may share personal information in
        compliance with a court order or subpoena, or if a government agency or investigatory body properly so requests.
      </>,
      <>
        We may share Personal Information with outside parties if we have a good-faith belief that access, use, preservation or disclosure of the information is reasonably necessary to meet any
        applicable legal process or enforceable governmental request; to enforce applicable Terms of Service, including investigation of potential violations; address fraud, security or technical
        concerns; or to protect against harm to the rights, property, or safety of our users or the public as required or permitted by law.{' '}
        <strong>We very strongly recommend to only share general information about yourself or any other matter without providing specifics or any confidential information.</strong> Even though we
        strive to protect the Website against third party intrusion such as hackers and computer malware, we cannot guarantee full protection of your personal identifying information.
      </>,
    ],
  },
  {
    title: <>2.&nbsp;&nbsp;Non-Personal Information</>,
    paragraphs: [
      <>
        In general, we use Non-Personal Information to help Us improve the Services and customize the user experience. We also aggregate Non-Personal Information in order to track trends and analyze
        use patterns on the Website. This Privacy Policy does not limit in any way our use or disclosure of Non-Personal Information and we reserve the right to use and disclose such Non-Personal
        Information to our partners, advertisers and other third parties at our discretion.
      </>,
      <>
        In the event we undergo a business transaction such as a merger, acquisition by another company, or sale of all or a portion of our assets, your Personal Information may be among the assets
        transferred. You acknowledge and consent that such transfers may occur and are permitted by this Privacy Policy, and that any acquirer of our assets may continue to process your Personal
        Information as set forth in this Privacy Policy. If our information practices change at any time in the future, we will post the policy changes to the Website so that You may opt out of the
        new information practices. We suggest that You check the Website periodically if You are concerned about how your information is used
      </>,
    ],
  },
  {
    title: <>How We Protect Information</>,
    paragraphs: [
      <>
        We implement security measures designed to protect your information from unauthorized access. Your account is protected by your account password and we urge You to take steps to keep your
        personal information safe by not disclosing your password and by logging out of your account after each use. We further protect your information from potential security breaches by
        implementing certain technological security measures including encryption, firewalls and secure socket layer technology. However, these measures do not guarantee that your information will not
        be accessed, disclosed, altered or destroyed by breach of such firewalls and secure server software. By using our Service, You acknowledge that You understand and agree to assume these risks.
      </>,
    ],
  },
  {
    title: <>Your Rights Regarding The Use Of Your Personal Information</>,
    paragraphs: [
      <>
        You have the right at any time to prevent Us from contacting You for marketing purposes. When we send a promotional communication to a user, the user can opt out of further promotional
        communications by following the unsubscribe instructions provided in each promotional e-mail. You can also indicate that You do not wish to receive marketing communications from Us. Please
        note that notwithstanding the promotional preferences You indicate by either unsubscribing or opting out, we may continue to send You administrative emails including, for example, periodic
        updates to our Privacy Policy.
      </>,
    ],
  },
  {
    title: <>Links To Other Websites</>,
    paragraphs: [
      <>
        As part of the use of the Website or the provision of the Services, we may provide links to or compatibility with other websites or applications. However, we are not responsible for the
        privacy practices employed by those websites or the information or content they contain. This Privacy Policy applies solely to information collected by us through the Website and the Services.
        Therefore, this Privacy Policy does not apply to your use of a third-party website accessed by selecting a link on our Website or as related to our Services. To the extent that You access or
        use the Services through or on another website or application, then the privacy policy of that other website or application will apply to your access or use of that Website or application. We
        encourage our users to read the privacy statements of other websites before proceeding to use them.
      </>,
    ],
  },
  {
    title: <>Changes To Our Privacy Policy</>,
    paragraphs: [
      <>
        The Company reserves the right to change this policy and our Terms of Service at any time. We will notify You of significant changes to our Privacy Policy by sending a notice to the primary
        email address specified in your account or by placing a prominent notice on our Website. Significant changes will go into effect 30 days following such notification. Non-material changes or
        clarifications will take effect immediately. You should periodically check the Website and this privacy page for updates
      </>,
    ],
  },
  {
    title: <>Contact Us</>,
    paragraphs: [
      <>
        If You have any questions regarding this Privacy Policy or the practices of this Website, please contact us by sending an email to our Compliance Program team at
        compliance@ipathlifesciences.com. If You do not want the Company to contact You, or if You want the Company to discontinue any use of, or if You wish to correct, any information that You have
        provided, please contact us at to compliance@ipathlifesciences.com. At this email address You can also request to opt out of marketing emails.
      </>,
    ],
  },
  {
    title: <>No Representations</>,
    paragraphs: [
      <>
        This Website, the Company Materials, any Information and as germane to any Services is/are offered by Company on an “as is” basis. With respect to the Website and any Information contained
        therein, Company makes no representations or warranties in any form or of any kind, express or implied, including but not limited to those of merchantability, fitness for a particular purpose,
        or non-infringement — whether any such guarantees, warranties, conditions and representations would arise under state or federal law, commercial use, or otherwise. You agree that your use is
        at your own risk and that under no circumstance shall Company be liable for damages of any kind. Although Company strives in good faith to correctly issue the Information, Company Materials
        and as related to its Services, Company cannot guarantee that all information is accurate and as such, the Company makes not warranties or representations as to its accuracy.
      </>,
      <>
        By using this Website, the reader and the audience agree that Company and its owners, contractors, members, officers, employees, agents, or affiliates shall not be liable, directly or
        indirectly, under any theory of common law, statute, whether contractual in nature or torts, whether under a theory of negligence or otherwise, to the reader, the audience, or anyone else for
        any claims, losses or damages, whether direct, indirect, special punitive, consequential, incidental or otherwise in nature, or any other losses, costs, or expenses of any kind (which expenses
        include legal fees, expert fees or any other costs) which may arise, directly or indirectly, though or related to the access to, use of or browsing of this Website even if Company has been
        advised of such damages or from your reliance on this Website or any materials, resources, or information accessed through this Website. We make no representations and we assume no
        responsibility for the accuracy of the Information contained on or made available through this Website.
      </>,
      <>
        We recommend you confirm any information contained on or through this Website with a duly licensed physician. We do not recommend, endorse, approve or make any representation about the
        suitability, accuracy, or appropriateness of any specific device, test, product, procedure, surgery, treatment, health opinion, medical providers or other information that may be contained on
        or made available through this Website.
      </>,
      <>
        Company and the Website shall never be liable for any interruption in Internet service or Website functionality and makes no warranty that the Website or Services will uninterrupted,
        error-free, timely or secure, or that the Website will meet your requirements or expectations. The Website may be subject to limitations, delays, and other problems inherent to the usage of
        electronic devises or communications, including the Internet, and the Website is not responsible for any such limitations, delays, or problems.
      </>,
      <>
        You may only access the Website through authorized means. You agree that by use of the Website, You assume all risk of damage to your Computer hardware or software including loss of data that
        may result from your use thereof, including the risk of damage from computer viruses. You acknowledge that the entire and sole risk of using the Website remains completely with You to the
        maximum extent allowed by applicable law.
      </>,
    ],
  },
  {
    title: <>Copyright</>,
    paragraphs: [
      <>
        Company reserves all rights. You may read, view, listen to, download, and print any part of this Website for your own personal, non-commercial use. Any other distribution, usage, reproduction,
        or transmission of the contents and information on this Website without prior approval of Company is prohibited. Company{' '}
        <strong>
          does not grant any express or implied rights to the information or content provided on this Website under any trademark, copyrights, trade secret, or intellectual property rights.
        </strong>{' '}
        The reader is permitted to read, view, listen to, and print as well as to store, distribute, and share the content from this Website under the conditions that doing so (1) is for your personal
        usage and not for any commercial gain, usage, or distribution, (2) the content is not modified, changed, amended, altered, or manipulated in any way, and (3) these Terms are included with any
        distribution.
      </>,
    ],
  },
  {
    title: (
      <>
        <span className='dot'>Links</span>
      </>
    ),
    paragraphs: [
      <>
        Links from this Website may lead to other websites. Such links are offered for the visitor’s guidance and convenience to provide further information. Company does not necessarily sponsor,
        endorse, or otherwise agree with or approve such linked materials and Company does not control or guarantee the existence, relevance, completeness, or accuracy of any information contained in
        or accessed by such links. By accessing this Website, You agree that Company has not reviewed all the sites liked to the Website and is not responsible for the content of any off-Website
        websites or any other website linked to this Website. If You link to any other third-party website, You do so at your own risk.
      </>,
    ],
  },
  {
    title: <>Limitation Of Liability</>,
    paragraphs: [
      <>
        In no event shall the owner of this Website, Company or its partners or affiliates or contributors to this Website be liable for any loss or injury, or any damages, whether direct, indirect,
        special, punitive, incidental, exemplary, consequential, or otherwise, whether based on breach of contract, tort (including negligence), product liability or otherwise, resulting from your
        access to Website. You hereby waive any and all such claims against the owner of this Website, Company or its partners or affiliates or contributors to this Website. You agree that the
        restrictions set forth above are material elements of this agreement, and that the Website and its information would not be provided to You in the absence of such limitations. Some state laws
        may restrict this limitation of liability.
      </>,
    ],
  },
  {
    title: <>Non-Waiver</>,
    paragraphs: [<>Company’s failure to insist upon strict enforcement of any provision of these Terms shall not be construed as a waiver of any provision or right.</>],
  },
  {
    title: <>Governing Law</>,
    paragraphs: [
      <>
        These Terms shall be governed by and construed in accordance with the laws of the State of <strong>Florida</strong>, without giving effect to any principles of conflicts of law. Any dispute
        between You and Company or between You and the owner of this Website or between You and the owner of this Website and Company regarding the content or use of this Website shall be resolved by
        arbitration under the Commercial Rules of the American Arbitration Association with all hearings held in <strong>Polk County</strong>, in the English language, and shall not be joined with
        any dispute that any other person or entity may have with Company or the owner of Website. If any provision of these Terms may be deemed to be unlawful, void or for any reason unenforceable,
        then that provision shall be deemed severable from these Terms all of which shall not affect the validity or enforceability of any remaining portion of these Terms.
      </>,
    ],
  },
  {
    title: (
      <>
        <span className='dot'>Entire Agreement</span>
      </>
    ),
    paragraphs: [
      <>
        These Terms form a binding contract between You and the respective Website’s owners and operators and there are no other contracts or agreements between You and us. These Terms do not create
        rights in or for any third-party individual or entity.
      </>,
    ],
  },
];
