import React from 'react';
import Typed from 'react-typed';
import BuyTestBtn from './BuyTestBtn';

const HeroBannerPicText = ({ content, theme, pageTitle, btn }) => {
  return (
    <div className="hero-banner-wrapper">
      <div className="hero-left-col">
        <h1 className="title heading-1">
          <Typed strings={[content.title[0]]} typeSpeed={50} showCursor={false} />
          <br />
          {content.title[1] ? (
            <Typed strings={[content.title[1]]} typeSpeed={50} showCursor={false} />
          ) : null}
        </h1>
        <h4 className="subtitle heading-4">{content.subtitle}</h4>
        {content.description.map((p, i) => (
          <p key={i} className="description text-block-2">
            {p}
          </p>
        ))}
        {btn ? <BuyTestBtn theme={theme} link="/order-test" /> : null}
      </div>

      <div className="hero-right-col">
        <div className="img-wrapper">
          <img className="fadeIn" src={content.img} alt="Banner Images" />
        </div>
      </div>
    </div>
  );
};

export default HeroBannerPicText;
