import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Copyright from './components/Copyright';
import { layoutContent as content } from './content/layout';


const Layout = ({ children, noFooter }) => {
  return (
    <>
      <main className='page-wrapper'>
        <Header btnStyle='round' HeaderSTyle='header-not-transparent' content={content.header} />
        {children}
        {noFooter ? <Copyright /> : <Footer content={content.footer} />}
      </main>
    </>
  );
};

export default Layout;
