import React from 'react';
import { FaSistrix } from 'react-icons/fa';
import Layout from '../partials/Layout';

const UnderConstruction = () => {
  return (
    <Layout noFooter={true}>
      <div className="error-area ptb--200 ptb_sm--60 ptb_md--80">
        <div className="container">
          <div className="row align-item-center">
            <div className="col-lg-12">
              <div className="error-inner">
                {/* <h1></h1> */}
                <h2 className="title">This page is under construction!</h2>
                <p className="description">Check back later. Thanks!</p>
                <div className="view-more-button">
                  <a className="btn-default" href="/">
                    Go Back Home
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UnderConstruction;
