import React from 'react';
import HeroBannerPicText from '../../components/HeroBannerPicText';
import PureArticle from '../../components/PureArticle';
import PicText from '../../components/PicText';
import SectionTitle from '../../components/SectionTitle';
import OrderedList from '../../components/OrderedList';
import CheckList from '../../components/CheckList';
import Steps from '../../components/Steps';
import AccordionBS from '../../components/AccordionBS';
import BuyTestBtn from '../../components/BuyTestBtn';
import * as Styled from './styles/StyledPharmacogenomics';

//01-SECTION
export const HeroBannerPharmacogenomics = ({ content, theme, pageTitle, btn }) => {
  return (
    <Styled.HeroBannerPharmacogenomics theme={theme}>
      <div className="container">
        <HeroBannerPicText content={content} />
      </div>
    </Styled.HeroBannerPharmacogenomics>
  );
};

//02-SECTION
export const PicTextCTOSpecial = ({ content, theme, btn, bgColor }) => {
  return (
    <Styled.PicTextCTOSpecial style={{ backgroundColor: bgColor }} theme={theme}>
      <div className="rn-section-gap">
        <div className="container">
          <PicText content={content} btn={btn} theme={theme} />
        </div>
      </div>
    </Styled.PicTextCTOSpecial>
  );
};

//03-SECTION
export const PharmacogenomicsExplained = ({ content, theme, bgColor }) => {
  return (
    <Styled.PharmacogenomicsExplained theme={theme} style={{ backgroundColor: bgColor }}>
      <div className="rn-section-gap ">
        <div className="container">
          <PureArticle theme={theme} content={content} />
        </div>
      </div>
    </Styled.PharmacogenomicsExplained>
  );
};

//04-SECTION
export const BenefitsMedication = ({ theme, content, bgColor }) => {
  return (
    <Styled.BenefitsMedication theme={theme} bgColor={bgColor} style={{ backgroundColor: bgColor }}>
      <div className="rn-section-gap">
        <div className="container">
          <OrderedList theme={theme} content={content} />
        </div>
      </div>
    </Styled.BenefitsMedication>
  );
};

//05-SECTION
export const WhoNeedsThisNew = ({ content, theme, bgColor }) => {
  return (
    <Styled.WhoNeedsThis theme={theme}>
      <div className="rn-section-gap" style={{ backgroundColor: bgColor }}>
        <div className="container">
          <div className="section-wrapper">
            {/* Column 1 */}
            <div className="col-1">
              <SectionTitle {...content.sectionTitle} />
            </div>
            {/* Column 2 */}
            <div className="col-2">
              <CheckList {...content.checkLists.listOne} checkList={null} />
              <CheckList {...content.checkLists.listTwo} checkList={null} />
              <BuyTestBtn theme={theme} link={content.btn.link} />
            </div>
          </div>
        </div>
      </div>
    </Styled.WhoNeedsThis>
  );
};

//06-SECTION
export const HowItWorks = ({ theme, content, bgColor }) => {
  return (
    <Styled.HowItWorks theme={theme} style={{ backgroundColor: bgColor }}>
      <div className="rn-section-gap">
        <div className="container">
          <SectionTitle {...content.sectionTitle} />
          <Steps {...content.steps} />
        </div>
      </div>
    </Styled.HowItWorks>
  );
};

//07-SECTION
export const PicTextPayZero = ({ content, theme, btn, bgColor, mergeHero }) => {
  return (
    <Styled.PicTextPayZero style={{ backgroundColor: bgColor }} theme={theme}>
      <div className="rn-section-gap">
        <div className="container">
          <PicText content={content} theme={theme} btn={btn} />
        </div>
      </div>
    </Styled.PicTextPayZero>
  );
};

//08-SECTION
export const FaqArea = ({ content, bgColor, theme }) => {
  return (
    <Styled.FaqArea style={{ backgroundColor: bgColor }} theme={theme} bgColor={bgColor}>
      <div className="rn-section-gap">
        <div className="container">
          <div className="faq-wrapper">
            <SectionTitle {...content.sectionTitle} />
            <AccordionBS {...content.accordionBS} bgColor={bgColor} />
          </div>
        </div>
      </div>
    </Styled.FaqArea>
  );
};
