import styled from 'styled-components';
import device from '../../../styles/mediaSizes';

export const StyledTeamArea = styled.div`
  .rn-box-card,
  .team-style-default {
    margin-top: 75px;
    .inner {
      /* background: linear-gradient(180deg, var(--color-lessdark) 20%, rgba(19, 19, 19, 0.4));
      -webkit-backdrop-filter: blur(4px) opacity(0.95);
      backdrop-filter: blur(4px) opacity(0.95);
      border-radius: var(--radius-big); */

      .thumbnail {
        max-width: 150px;
        max-height: 150px;
        border-radius: 100%;
        border: 6px solid #fff;
        top: -75px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        min-width: 150px;
        min-height: 150px;
        overflow: hidden;
        img {
          /* border-radius: 100%; */
        }
      }

      .content {
        text-align: center;
        padding: 20px;
        padding-top: 85px;
        padding-bottom: 35px;

        .title {
          font-size: 25px;
          line-height: 1.4;
          font-weight: 700;
          margin-bottom: 25px;
        }
   
        .subtitle {
          font-size: 20px;
          line-height: 1.4;
          margin-bottom: 15px;
          display: inline-block;
        }
        .team-form {
          display: block;
          font-size: 14px;
          margin-bottom: 12px;
          letter-spacing: 0.6px;
          font-style: italic;

          img {
            padding-right: 10px;
            max-height: 18px;
          }
        }
        .description {
          margin-bottom: 0;
          padding: 0 5%;
        }
      }

      .social-icon {
        li {
          a {
            color: #acacac;
          }
        }
      }
    }

    &.style-two {
      margin-top: 30px;
      .inner {
        position: relative;
        padding-top: 35px;
        min-height: 525px;
        .thumbnail {
          top: 0;
          position: static;
          left: inherit;
          transform: none;
          min-width: 150px;
          min-height: 150px;
          margin: 0 auto;
        }
        .content {
          padding-top: 10px;
          .bottom-section{
            position: absolute;
            bottom: 5rem;
            left: 50%;
            transform: translate(-50%);
            width: 100%;
          }
        }
      }
    }
  }

  .meet{
    display: block;
    font-size: 0.5em;
    margin-bottom: 0;
  }

  .heading-5 {
    margin-top: 9rem;
    text-align: center;
  }
  
  .heading-4{
    width: 100%;
  }

  .desc{
    width: 60%;
    margin: auto;
    @media ${device.sm}{
      width: auto;
    }
  }

  .partner {
    width: 70%;
    text-align: center;
    margin: auto;
    @media ${device.sm}{
      width: 100%;
    }
  }

  //TEAM POPUP
  .team-popup-inner-wrapper {
    position: relative;
    z-index: 105;
    display: flex;
    width: 100%;
    height: 46.45vw;
    max-height: 669px;
    max-width: 1185px;
    border-radius: 6px;
    background-color: #fff;
    ul {
      list-style: none;
      margin-bottom: 30px;
      padding-left: 0;
    }
    ul li {
      color: #342a47;
    }
  }

  .team-popup-text-wrapper {
    display: flex;
    max-width: 55%;
    min-width: 55%;
    margin: 2.77vw 5.2vw 5.55vw;
    flex-direction: column;
    justify-content: space-between;
  }

  .team-popup-profession-text {
    margin-bottom: 15px;
    font-family: Lora, sans-serif;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
  }

  .team-popup-paragraph {
    margin-bottom: 0;
  }

  .team-popup-paragraph a {
    font-family: Prompt, sans-serif;
    color: #4557ce;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
  }

  .team-popup-paragraph a:hover {
    text-decoration: underline;
  }

  .popup-orange-line {
    width: 4px;
    height: 59%;
    background-color: #feb25c;
  }

  .popup-purple-line {
    width: 4px;
    height: 41%;
    background-color: #4557ce;
  }

  .team-popup-image {
    width: 56%;
    max-width: 46%;
    min-width: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .team-popup-logos-container {
    display: flex;
    margin-top: 0;
  }

  .team-popup-logo-wrapper {
    position: relative;
    margin-right: 23px;
  }
  .team-popup-logo.hover {
    position: absolute;
    left: 0;
    top: auto;
    right: 0;
    bottom: 0;
    opacity: 0;
    -webkit-transition: opacity 350ms;
    transition: opacity 350ms;
  }

  .team-popup-logo.hover:hover {
    opacity: 1;
  }

  .team-popup-close {
    position: absolute;
    left: auto;
    top: -86px;
    right: 17px;
    bottom: auto;
    font-size: 70px;
    font-weight: 100;
    color: #fff;
    cursor: pointer;
  }

  .team-popup-close:hover {
    top: -80px;
    color: #800a7f;
    font-size: 65px;
  }

  .team-popup-text-inner-wrapper {
    overflow-y: auto;
    display: block;
    height: 26vw;
    max-height: 340px;
    align-items: center;
    padding-right: 5px;
  }

  .team-popup-text-inner-wrapper::-webkit-scrollbar {
    width: 2px;
  }

  .team-popup-text-inner-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px #800a7f;
    box-shadow: inset 0 0 1px #800a7f;
    border-radius: 10px;
  }

  .team-popup-text-inner-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  }

  @media ${device.lg} {
    .team-popup-inner-wrapper {
      width: 90%;
      margin: auto;
      height: 70.07vw;
      max-height: 600px;
    }

    .team-popup-profession-text {
      margin-bottom: 30px;
    }
    .team-popup-logos-container {
      margin-top: 4vw;
    }
  }

  @media ${device.md} {
    .team-popup-inner-wrapper {
      width: 90%;
      margin: auto;
      height: 58.07vw;
      max-height: 446px;
    }

    .team-popup-text-wrapper {
      margin: 40px 29px 57px 43px;
    }

    .team-popup-profession-text {
      font-size: 12px;
      line-height: 17px;
    }

    .team-popup-paragraph,
    .team-popup-paragraph a {
      font-size: 10px;
      line-height: 18px;
    }

    .team-popup-logos-container {
      margin-top: 0;
    }

    .team-popup-logo-wrapper {
      width: 17px;
      height: 17px;
      margin-right: 18px;
    }

    .team-popup-logo.hover {
      top: 3px;
    }
  }

  @media ${device.ms} {
    .team-popup-inner-wrapper {
      height: 152.8vw;
      max-height: 578px;
    }
    .team-popup-text-wrapper {
      display: flex;
      max-width: none;
      min-width: 0;
      margin: 41px 42px 42px;
      flex-direction: column;
    }
    .team-popup-image {
      display: none;
    }
    .team-popup-logos-container {
      margin-top: auto;
    }
    .team-popup-lines-wrapper {
      display: none;
    }
    .team-popup-text-inner-wrapper {
      height: 91.73vw;
      max-height: 344px;
    }

    .team-popup-paragraph p {
      font-size: 12px;
    }
  }
`;
