// Layouts Variation
const device = {
  xlg: 'only screen (max-width: 1919px)',
  mlg: 'only screen and (max-width: 1199px)',
  lg: 'only screen and (max-width: 1025px)',
  md: 'only screen and (max-width: 991px)',
  ms: 'only screen and (max-width: 767px)',
  sm: 'only screen and (max-width: 575px)',
  xs: 'only screen and (max-width: 479px)',
  laptop: 'only screen and (min-width: 1200px) and (max-width: 1599px)'
}

export default device;