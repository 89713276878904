export const tocContent = [
  { 
     paragraphs: [
         <>To achieve our Compliance Mandate, we require that ethical and compliant business practices be our top priority. Accordingly, we commit to upholding the highest standards of ethics and compliance in our relationships with anyone that receives any service or product we provide and further with all <strong>Third Parties</strong> including treating physicians, contractors, vendors and the public.</>
      ]
  },
  { 
     paragraphs: [
         <>In order to promote and preserve our Compliance Mandate, we have established our Compliance Program and its Policies and practices (collectively, our <strong>“Policies”</strong>). Our Policies apply to all Company employees and agents and Third Parties, including contractors and vendors. Moreover, our Policies are designed to provide anyone that works with us with guidance regarding compliance with <strong>Laws</strong>, regulations and our compliance standards. We regularly communicate with everyone regarding our Policies and our compliance standards to ensure that they are consciously aware of, and adhere to, our Compliance Mandate. Our employees, agents and contractors must annually certify their agreement to abide by our Policies and compliance standards. </>
      ]
  },
  {
      paragraphs:[
          <>Our Compliance Policies also mandate specific anti-retaliation provisions that protect anyone that brings forward any concern, question or problem. We encourage open communication about any, without limitation, legal or compliance matter and we are committed to protect anyone providing a good faith report of inappropriate business practices or any kind of <strong>Misconduct.</strong> In addition to our internal reporting channels, our Company employees, agents and any Third Party also have the option to report any matter to our compliance email compliance@ipathlifesciences.com and separately to anonymously report any matter through our Third-Party managed compliance hotline. Retaliation against anyone making any report, directly or through the compliance email or hotline, or participating in an investigation is strictly forbidden and will result in disciplinary action.</>
      ]
  },
  {
      paragraphs:[
          <>In addition to our compliance Policies, we provide ongoing training and education targeted to employee roles and functions. Training is provided in person or online and addresses compliance topics including, but not limited to, <strong>Health Care Laws</strong>, conflicts of interest, and privacy of <strong>Health Care Information</strong>. Everyone’s participation in, and completion of, required training is tracked on a regular basis. We also require periodic <strong>Certifications of Compliance</strong> by employees and contractors regarding their adherence to Health Care Laws and Company Policies among other compliance matters. These compliance practices are designed to supplement our Policies and provide topic-specific guidance to everyone that works with us. </>
      ]
  },
  {
      paragraphs:[
          <>To meet our Compliance Mandate, our executive <strong>Management</strong> recognizes the importance of appropriate compliance oversight and other risks in conducting our operations and meeting our commitments. While our executive Management maintains ultimate responsibility for the Company’s ethical operations in compliance with all Laws, a key element under our Company's Compliance Program will be the <strong>Compliance Advisory Board.</strong> The Compliance Advisory Board will take an active role in overseeing compliance risk and in assisting our executive Management with addressing specific matters, including competitive, legal, regulatory, operational and financial matters. The Compliance Advisory Board is expected to be composed of members of our executive Management, our <strong>Compliance Officer</strong> and subject matter experts. It is responsible for the review of our potential and identified compliance and business risks and will make regular reports to executive Management as needed or advisable.
          Finally, as part of our Compliance Mandate, the Company and our Personnel are forbidden from counseling any Patient in all matters that are solely proper as between the Patient and his or her treating physician.  In short, our Company does not meddle with Clinical Decisions.  As such, the Company provides diagnostic tests to Patients strictly consistent with Health Care Laws and pursuant to the treating physician’s, for example, certification of medical necessity.
          </>
      ]
  },
  {
      paragraphs:[
          <>To process a diagnostic test, we require that the independent treating physician attest to the following in our diagnostic test forms: “By my signature, I certify that I am the patient’s treating physician and that the tests selected are medically necessary and not for screening. I have properly consulted with my patient about the nature and purpose of the testing to be performed (and to permit the laboratory to perform the test, retain results as relevant for internal quality assurance/operations purposes, de-identify and use or disclose de-identified results for sanctioned research or other purposes, and release test results to the relevant payor as needed for reimbursement purposes) and pursuant to my established physician-patient relationship, seek the testing for my patient’s specific medical condition as consistent with my diagnostic, management and/or treatment plan. I have proper training and expertise regarding my patient’s medical condition and order this testing based on my patient’s medical necessity, my independent professional judgment, and consistent with the standard of care. I have not been directly or indirectly incentivized by anyone nor receive any improper renumeration for ordering this test. My professional license is in good standing and I am not subject to any disciplinary or legal proceeding. I remain solely responsible for ordering this diagnostic test and utilizing any result in my continuing care and treatment of my patient.  My patient’s medical records that establish medical necessity for this testing are attached to this order form. The Laboratory’s Terms of Service, HIPAA Privacy Notice, and <u className='fw-600 text-primary'><a href='/compliance-program'>Compliance Statement and Program Policies</a></u> are fully incorporated into this order form for all purposes.”</>
      ]
  },
  {
      paragraphs:[
          <span className='toUpperCase'>OUR COMPANY OUR PERSONNEL ARE RESPONSIBLE FOR ADHERENCE TO THE CORPORATE COMPLIANCE PROGRAM AND ALL APPLICABLE LAWS. IF YOU HAVE ANY QUESTION REGARDING THE COMPLIANCE PROGRAM OR ANYTHING CONTAINED IN OUR WEBSITE, PLEASE CONTACT US AT OUR COMPLIANCE REPORTING EMAIL COMPLIANCE@IPATHLIFESCIENCES.COM.</span>
      ]
  },
  
];