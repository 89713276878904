import styled from 'styled-components';
import device from './mediaSizes';

export const GeneralComponentsStyle = styled.div`
  .hero-banner-wrapper {
    height: 91vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .hero-left-col {
      width: 50%;

      .heading-1 {
      }
      .heading-4 {
        color: #5f5a6a;
        width: 415px;
        min-width: 415px;
      }
    }
    .hero-right-col {
      width: 50%;
    }
    @media ${device.lg} {
      & {
        height: 60vh;
        border: none;
        .hero-left-col {
          width: 50%;
          .heading-1 {
            margin-bottom: 10px;
          }
          .heading-4 {
            width: auto;
            min-width: auto;
          }
        }
        .hero-right-col {
          width: 50%;
        }
      }
    }
    @media ${device.md} {
    }
    @media ${device.sm} {
      & {
        margin: 60px 0;
        flex-direction: column-reverse;
        height: auto;
        border: none;
        .hero-left-col {
          width: 100%;
          .heading-1 {
            margin-bottom: 10px;
          }
          .heading-4 {
            width: auto;
            min-width: auto;
          }
        }
        .hero-right-col {
          width: 100%;
          margin-bottom: 25px;
        }
      }
    }
  }

  .video-wrapper {
    video {
      width: 80%;
      display: block;
      margin: 0 auto;
      max-width: 800px;
      box-shadow: -1rem 0 6rem -2rem #2d2d2d;
    }
  }

  .pure-article-wrapper {
    .title-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .decorated-title-wrapper {
        img {
        }
        .heading-2.decorated {
          padding-left: 65px;
          margin-bottom: 90px;
          border-left: solid 1px ${({ theme }) => theme.dropColor};
        }
      }
      .heading-2.regular {
        margin-bottom: 90px;
        text-align: left;
      }
    }
    .article-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .article-left-col {
        width: 48%;
      }
      .article-right-col {
        width: 48%;
        padding-right: 45px;
      }
    }
    .side-text-wrapper {
      margin-bottom: 20px;
      .side-text-light {
        color: #cbbad9;
        font-family: Prompt, sans-serif;
        font-size: 24px;
        line-height: 34px;
        font-weight: 300;
      }
      .side-text {
        color: #5f5a6a;
        font-family: Prompt, sans-serif;
        font-size: 24px;
        line-height: 34px;
        font-weight: 300;
      }
    }
    .bold-text {
      font-weight: 500;
      font-size: 20px;
    }
    .tail-text {
      margin-top: 50px;
    }
    @media ${device.lg} {
    }
    @media ${device.md} {
      & {
        .title-wrapper {
          .decorated-title-wrapper {
            img {
            }
            .heading-2.decorated {
              padding-left: 30px;
              padding-right: 65px;
              margin-bottom: 55px;
            }
          }
          .heading-2.regular {
          }
        }

        .article-wrapper {
          .article-right-col {
            padding-right: 35px;
          }
          .article-left-col {
            padding-right: 35px;
          }
          .side-text-wrapper {
            .side-text-light,
            .side-text {
              font-size: 14px;
              line-height: 24px;
            }
          }

          .bold-text {
            font-weight: 500;
            font-size: 17px;
          }
        }

        .tail-text {
          margin-top: 25px;
        }
      }
    }
    @media ${device.sm} {
      & {
        .title-wrapper {
          .decorated-title-wrapper {
            img {
              display: none;
            }
            .heading-2.decorated {
              padding-left: 0;
              margin-bottom: 35px;
              border: none;
            }
          }
          .heading-2.regular {
          }
        }

        .article-wrapper {
          .article-left-col {
            width: 100%;
            padding-right: 0;
            margin-bottom: 12px;
          }
          .article-right-col {
            width: 100%;
            padding-right: 0;
          }
          .side-text-wrapper {
            .side-text-light,
            .side-text {
              font-size: 14px;
              line-height: 20px;
              font-weight: 300;
            }
          }
          .bold-text {
            font-weight: 500;
            font-size: 13px;
          }
        }
        .tail-text {
          margin-top: 25px;
        }
      }
    }
  }

  .button-wrapper {
    .button-1 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      height: 65px;
      border-radius: 8px;
      background: ${({ bg }) => bg};
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      letter-spacing: 0.5px;
      padding: 0 40px;
      transition-duration: 300ms;
      &:hover {
        box-shadow: 0 0 20px 5px rgba(251, 83, 67, 0.2);
        color: #fff;
        background-color: ${({ hover }) => hover} !important;
        background: ${({ hover }) => hover} !important;
        text-decoration: none;
      }
      .icon {
        position: relative;
        top: -1px;
        padding-left: 4px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    @media ${device.md} {
      & {
        .button-1 {
          height: 45px;
          font-size: 14px;
          padding: 0 15px;
          .icon {
            svg {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
    @media ${device.sm} {
      & {
        .button-1 {
          height: 45px;
          font-size: 12px;
          line-height: 42px;
          padding: 0 15px;
          width: 100%;
          margin-right: auto;
          margin-left: auto;
          .icon {
            svg {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
  }

  .pic-text-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 -15px;
    .pic-col {
      width: 46.6%;
      .inner-img {
        border-radius: 9px;
      }
      .regular-img {
        display: inline-block;
      }
      .mobile-img {
        display: none;
      }
    }
    .text-col {
      width: 50%;
      .text-group {
        margin-bottom: 35px;
        .heading-1 {
        }
        .heading-2 {
        }
        .heading-4 {
          color: #5f5a6a;
        }
      }
    }
    @media ${device.sm} {
      & {
        margin: 0;
        .pic-col {
          width: 100%;
          margin-bottom: 35px;
          .inner-img {
          }
          .regular-img {
            display: none;
          }
          .mobile-img {
            display: inline-block;
          }
        }
        .text-col {
          width: 100%;
          .text-group {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .divider-wrapper {
    margin-bottom: 50px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    .divider-line-group {
      width: 100%;
      .divider-line-1 {
        position: relative;
        margin: 0 auto;
        background-color: #b3b0b7;
        width: 70%;
        height: 2px;
      }
    }
    .divider-text {
      position: absolute;
      background-color: ${({ bgColor }) => bgColor};
      bottom: -12px;
      padding: 0 25px;
      letter-spacing: 3px;
      font-weight: 700;
      font-size: 16px;
      color: ${({ theme }) => theme.mainColor};
    }

    @media ${device.lg} {
    }
    @media ${device.md} {
      & {
        margin-bottom: 50px;
        .divider-line-group {
          width: 100%;
          .divider-line-1 {
          }
        }
        .divider-text {
          bottom: -11px;
          font-size: 13px;
        }
      }
    }
    @media ${device.sm} {
      & {
        margin-bottom: 35px;
        .divider-line-group {
          width: 100%;
          .divider-line-1 {
            width: 100%;
          }
        }
        .divider-text {
          bottom: -9px;
          font-size: 12px;
          padding: 0 15px;
        }
      }
    }
  }

  .section-title-wrapper {
    margin-bottom: 30px;
    .subtitle {
      color: #5f5a6a;
      margin: 0;
    }
    .description {
      margin-top: 25px;
    }

    @media ${device.md} {
      & {
        margin-bottom: 30px;
        .heading-1 {
          margin-bottom: 15px;
        }
        .heading-4 {
          color: #5f5a6a;
          margin: 0;
        }
      }
    }
  }

  .check-list-wrapper {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    .check-list-title {
    }
    .check-list-intro {
      margin-bottom: 15px;
    }
    .check-list-item {
      /* display: flex;
      align-items: center; */
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      .check-list-main {
        display: flex;
        .check-list-item-img {
          display: inline-block;
          align-self: start;
          padding-top: 0.5em;
          margin-right: 10px;
          width: 18px;
          margin-top: 3px;
          margin-left: 4px;
        }
        .check-list-text {
          font-weight: 600;
          color: #342a47;
        }
      }
      .inner-list {
        list-style: none;
        margin: 0;
        padding: 0;
        &.list-border {
          border-left: 2px solid ${({ theme }) => theme.mainColor};
        }
        .inner-list-item {
          display: flex;
          align-items: center;
          margin-top: 0;
          margin-bottom: 10px;
          line-height: 1.7;
          width: 100%;
          &:before {
            content: '⚫';
            padding-right: 20px;
            font-size: 6px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    @media ${device.lg} {
      & {
        margin-bottom: 30px;
        &:last-child {
        }
        .check-list-title {
        }
        .check-list-intro {
        }
        .check-list-item {
          &:last-child {
          }
          .check-list-item-img {
            padding-top: 0.2em;
          }
          .check-list-text {
          }
          .inner-list {
            &.list-border {
            }
            .inner-list-item {
              &:before {
              }
              &:last-child {
              }
            }
          }
        }
      }
    }
    @media ${device.md} {
      & {
        .check-list-intro {
        }
        .check-list-item {
          .check-list-item-img {
          }
          .check-list-text {
          }
          .inner-list {
            &.list-border {
            }
            .inner-list-item {
              &:before {
              }
            }
          }
        }
      }
    }
    @media ${device.sm} {
      & {
        margin-bottom: 30px;
        &:last-child {
        }
        .check-list-title {
        }
        .check-list-intro {
        }
        .check-list-item {
          &:last-child {
          }
          .check-list-item-img {
          }
          .check-list-text {
          }
          .inner-list {
            margin-bottom: 30px;
            &.list-border {
            }
            .inner-list-item {
              &:before {
                padding-right: 12px;
                font-size: 5px;
              }
              &:last-child {
              }
            }
          }
        }
      }
    }
  }

  .numbered-list-wrapper {
    margin-bottom: 30px;
    .numbered-list-title {
      margin-bottom: 15px;
    }
    .numbered-list-intro {
      margin-bottom: 30px;
    }
    .numbered-list {
      display: flex;
      max-width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      .numbered-list-item {
        position: relative;
        display: flex;
        width: 100%;
        margin-bottom: 26px;
        justify-content: flex-start;
        align-items: start;
        &:last-child {
          margin-bottom: 0;
        }
        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
        .numbered-list-item-title {
        }
        .numbered-list-item-circle {
          display: flex;
          width: 34px;
          height: 34px;
          max-height: 34px;
          max-width: 34px;
          min-height: 34px;
          min-width: 34px;
          margin-right: 15px;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.thirdColor};
          .numbered-list-item-number {
            color: ${({ theme }) => theme.secColor};
            font-size: 18px;
            font-weight: 500;
          }
        }
        .numbered-list-item-text {
        }
      }
    }

    @media ${device.lg} {
      & {
        .numbered-list-title {
        }
        .numbered-list-intro {
        }
        .numbered-list {
          .numbered-list-item {
            &:last-child {
              margin-bottom: 0;
            }
            p {
              &:last-child {
              }
            }
            .numbered-list-item-title {
            }
            .numbered-list-item-circle {
              width: 28px;
              height: 28px;
              max-height: 28px;
              max-width: 28px;
              min-height: 28px;
              min-width: 28px;
              margin-right: 15px;
              .numbered-list-item-number {
              }
            }
            .numbered-list-item-text {
            }
          }
        }
      }
    }
    @media ${device.md} {
      & {
        .numbered-list-title {
        }
        .numbered-list-intro {
        }
        .numbered-list {
          .numbered-list-item {
            &:last-child {
              margin-bottom: 0;
            }
            p {
              &:last-child {
              }
            }
            .numbered-list-item-title {
            }
            .numbered-list-item-circle {
              width: 25px;
              height: 25px;
              max-height: 25px;
              max-width: 25px;
              min-height: 25px;
              min-width: 25px;
              margin-right: 15px;
              .numbered-list-item-number {
                font-size: 16px;
              }
            }
            .numbered-list-item-text {
            }
          }
        }
      }
    }
    @media ${device.sm} {
      & {
        .numbered-list-title {
        }
        .numbered-list-intro {
        }
        .numbered-list {
          .numbered-list-item {
            &:last-child {
              margin-bottom: 0;
            }
            p {
              &:last-child {
              }
            }
            .numbered-list-item-title {
            }
            .numbered-list-item-circle {
              width: 21px;
              height: 21px;
              max-height: 21px;
              max-width: 21px;
              min-height: 21px;
              min-width: 21px;
              margin-right: 10px;
              .numbered-list-item-number {
                font-size: 14px;
              }
            }
            .numbered-list-item-text {
            }
          }
        }
      }
    }
  }

  .ordered-list-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .ordered-list-title {
      max-width: 750px;
      margin-bottom: 40px;
    }
    .ordered-list {
      margin-left: 18px;
      width: 100%;
      .ordered-item-wrapper {
        position: relative;
        display: flex;
        height: 69px;
        padding-right: 25%;
        padding-left: 5.92%;
        padding-top: 1.5%;
        padding-bottom: 1.5%;
        align-items: center;
        border-left: 3px solid ${({ theme }) => theme.thirdColor};
        background-color: #fff;
      }
      .ordered-item-number-wrapper {
        position: absolute;
        left: -23.5px;
        top: auto;
        right: auto;
        bottom: auto;
        display: flex;
        width: 44px;
        height: 44px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.thirdColor};
        .ordered-item-number {
          color: ${({ theme }) => theme.mainColor};
          font-size: 24px;
          font-weight: 100;
        }
      }
      .ordered-item-wrapper.odd {
        justify-content: space-between;
        background-color: #f9fafc;
      }
    }
    @media ${device.lg} {
      & {
        width: 90%;
        margin: 0 auto;
        .ordered-list-title {
        }
        .ordered-list {
          margin-left: 18px;
          .ordered-item-wrapper {
            position: relative;
            display: flex;
            height: 69px;
            padding-right: 25%;
            padding-left: 5.92%;
            padding-top: 1.5%;
            padding-bottom: 1.5%;
            align-items: center;
            border-left: 3px solid ${({ theme }) => theme.thirdColor};
            background-color: #fff;
          }
          .ordered-item-number-wrapper {
            position: absolute;
            left: -17px;
            width: 35px;
            height: 35px;
            background-color: ${({ theme }) => theme.thirdColor};
            .ordered-item-number {
              font-size: 16px;
            }
          }
          .ordered-item-wrapper.odd {
          }
        }
      }
    }
    @media ${device.md} {
      & {
        width: 100%;
        margin: 0 auto;
        .ordered-list-title {
        }
        .ordered-list {
          .ordered-item-wrapper {
            padding-top: 2%;
            padding-bottom: 2%;
            min-height: 60px;
            height: auto;
          }
          .ordered-item-number-wrapper {
            position: absolute;
            left: -17px;
            width: 30px;
            height: 30px;
            background-color: ${({ theme }) => theme.thirdColor};
            .ordered-item-number {
              font-size: 14px;
            }
          }
          .ordered-item-wrapper.odd {
          }
        }
      }
    }
    @media ${device.sm} {
      & {
        width: 100%;
        margin: 0 auto;
        /* margin-left: 8px; */
        .ordered-list-title {
          margin-left: 0;
        }
        .ordered-list {
          width: 100%;
          .ordered-item-wrapper {
            padding-left: 8%;
            padding-right: 10%;
          }
          .ordered-item-number-wrapper {
            position: absolute;
            left: -17px;
            width: 30px;
            height: 30px;
            background-color: ${({ theme }) => theme.thirdColor};
            .ordered-item-number {
              font-size: 14px;
            }
          }
          .ordered-item-wrapper.odd {
          }
        }
      }
    }
  }

  .steps-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .step {
      width: 23%;
      .step-title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 60px;
        .step-counter-title {
          color: ${({ theme }) => theme.secColor};
          width: 50%;
          margin: 0;
        }
        .step-counter-title-line {
          height: 1px;
          width: 45%;
          background-color: ${({ theme }) => theme.secColor};
        }
      }
      .step-title {
      }
      .step-description {
        width: 85%;
      }
    }
    @media ${device.lg} {
      & {
        .step {
          .step-title-wrapper {
            .step-counter-title {
            }
            .step-counter-title-line {
            }
          }
          .step-title {
          }
          .step-description {
          }
        }
      }
    }
    @media ${device.md} {
      & {
        .step {
          width: 48%;
          margin-bottom: 30px;
          &:nth-last-of-type(-n + 2) {
            margin-bottom: 0;
          }
          .step-title-wrapper {
            margin-bottom: 30px;
            .step-counter-title {
            }
            .step-counter-title-line {
              display: none;
            }
          }
          .step-title {
          }
          .step-description {
            width: 85%;
          }
        }
      }
    }
    @media ${device.sm} {
      & {
        .step {
          width: 100%;
          &:nth-last-of-type(n + 2) {
            margin-bottom: 30px;
          }
          .step-title-wrapper {
            margin-bottom: 15px;
            .step-counter-title {
            }
            .step-counter-title-line {
            }
          }
          .step-title {
          }
          .step-description {
            width: 100%;
          }
        }
      }
    }
  }

  .bullet-list-table-wrapper {
    .bullet-list-table-title {
      font-size: 18px;
    }
    .bullet-list-table-item {
      display: flex;
      flex-wrap: wrap;
      padding: 35px 35px;
      .item-title-col {
        padding-right: 10px;
        width: 20%;
        .item-title {
          font-family: 'Prompt', sans-serif;
        }
        .item-subtitle {
          width: 80%;
        }
      }
      .item-list-col {
        width: 80%;
        .item-list {
          margin: 0;
          width: 80%;
          .item {
            margin-top: 0;
            margin-bottom: 10px;
            line-height: 1.7;
            font-weight: 400;
            &:last-child {
              margin: 0;
            }
          }
        }
      }
      &:nth-child(even) {
        background: ${({ theme }) => theme.thirdColor};
      }
    }
    .tail-text {
      margin-top: 50px;
      margin-bottom: 0;
    }

    @media ${device.lg} {
    }
    @media ${device.md} {
    }
    @media ${device.sm} {
      & {
        .bullet-list-table-title {
          font-size: 14px;
        }
        .bullet-list-table-item {
          .item-title-col {
            width: 100%;
            .item-title {
            }
            .item-subtitle {
              margin-bottom: 14px;
              width: 100%;
            }
          }
          .item-list-col {
            width: 100%;
            .item-list {
              width: 100%;
              .item {
                margin-top: 0;
                margin-bottom: 10px;
                line-height: 1.7;
                font-weight: 400;
                &:last-child {
                  margin: 0;
                }
              }
            }
          }
          &:nth-child(even) {
            background: ${({ theme }) => theme.thirdColor};
          }
        }
        .tail-text {
          margin-top: 50px;
          margin-bottom: 0;
        }
      }
    }
  }

  .faq-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .section-title-wrapper {
      margin-bottom: 60px;
      .title {
        margin: 0;
      }
    }
    .accordion {
      width: 65%;
      .accordion-item {
        border: none;
        border-bottom: #dee2e6 1px solid;
        .accordion-header {
          .accordion-button {
            margin: 0;
            padding: 18px 0;
            &:after,
            &.collapsed:after {
              height: 1.1em;
              width: 1.1em;
              background-size: contain;
            }
            &.collapsed:after {
              background-image: url(/images/decor/faq_open_${({ theme }) => theme.themeColor}.svg);
            }
            &:after {
              background-image: url(/images/decor/faq_close_${({ theme }) => theme.themeColor}.svg);
            }
            &:focus {
              box-shadow: none;
            }
            &:focus:not(.collapsed) {
              box-shadow: none;
            }
            &:not(.collapsed) {
              color: ${({ theme }) => theme.mainColor};
            }
          }
        }
        .accordion-body {
          padding-left: 30px;
          padding-top: 30px;
          padding-bottom: 60px;
        }
      }
    }
    @media ${device.lg} {
      & {
        .section-title-wrapper {
          margin-bottom: 45px;
        }
        .accordion {
          width: 75%;
          .accordion-item {
            border: none;
            border-bottom: #dee2e6 1px solid;
            .accordion-header {
              .accordion-button {
                margin: 0;
                padding: 18px 0;
                &:after,
                &.collapsed:after {
                }
                &:after {
                }
                &.collapsed:after {
                }
                &:focus {
                  box-shadow: none;
                }
                &:focus:not(.collapsed) {
                  box-shadow: none;
                }
                &:not(.collapsed) {
                  background: #fff;
                  color: ${({ theme }) => theme.mainColor};
                }
              }
            }
            .accordion-body {
              padding: 12px 28px;
            }
          }
        }
      }
    }
    @media ${device.md} {
      & {
        .section-title-wrapper {
          margin-bottom: 45px;
        }
        .accordion {
          width: 75%;
          .accordion-item {
            border: none;
            border-bottom: #dee2e6 1px solid;
            .accordion-header {
              .accordion-button {
                margin: 0;
                padding: 18px 0;
                &:after,
                &.collapsed:after {
                }
                &:after {
                }
                &.collapsed:after {
                }
                &:focus {
                  box-shadow: none;
                }
                &:focus:not(.collapsed) {
                  box-shadow: none;
                }
                &:not(.collapsed) {
                }
              }
            }
            .accordion-body {
              padding: 12px 28px;
            }
          }
        }
      }
    }
    @media ${device.sm} {
      & {
        .section-title-wrapper {
          margin-bottom: 20px;
          .title {
            font-size: 24px;
          }
        }
        .accordion {
          width: 100%;
          .accordion-item {
            .accordion-header {
              .accordion-button {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: nowrap;
                &:after,
                &.collapsed:after {
                  margin-left: 20px;
                }
                &:after {
                }
                &.collapsed:after {
                }
                &:focus {
                  box-shadow: none;
                }
                &:focus:not(.collapsed) {
                  box-shadow: none;
                }
                &:not(.collapsed) {
                  background: #fff;
                  color: ${({ theme }) => theme.mainColor};
                }
              }
            }
            .accordion-body {
              padding: 12px 28px;
            }
          }
        }
      }
    }
  }

  .page-hero-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding-top: 120px;
    .page-hero-title-wrapper {
      position: relative;
      padding-bottom: 185px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .title {
        text-align: center;
      }
      .subtitle {
        width: 80%;
        text-align: center;
        margin: auto;
      }
    }
    .page-hero-img-wrapper {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 0;
      transform: translate(0, 50%);
      margin: auto;
      margin-top: 35px;
      margin: auto;
      .page-hero-main-img {
        display: block;
        position: static;
        max-height: 339px;
        border-radius: 6px;
        margin: auto;
      }
      .page-hero-decor-img {
        position: absolute;
        bottom: 0;
        transform: translate(0, 50%);
        max-height: 75px;
        display: block;
        margin: auto;
      }
    }
    @media ${device.lg} {
      & {
        position: relative;
        padding-top: 80px;
        .page-hero-title-wrapper {
          padding-bottom: 185px;
          .title {
          }
          .subtitle {
          }
        }
        .page-hero-img-wrapper {
          bottom: 0;
          transform: translate(0, 50%);
          .page-hero-main-img {
            max-height: 339px;
          }
          .page-hero-decor-img {
            position: absolute;
            bottom: -60px;
            display: block;
            margin: auto;
          }
        }
      }
    }
    @media ${device.md} {
      & {
        position: relative;
        padding-top: 45px;
        .page-hero-title-wrapper {
          padding-bottom: 180px;
          .title {
            margin-bottom: 25px;
          }
          .subtitle {
            width: 70%;
          }
        }
        .page-hero-img-wrapper {
          bottom: 0;
          transform: translate(0, 50%);
          .page-hero-main-img {
            max-height: 300px;
          }
          .page-hero-decor-img {
            max-height: 100px;
            bottom: -54px;
          }
        }
      }
    }
    @media ${device.sm} {
      & {
        position: relative;
        padding-top: 45px;
        .page-hero-title-wrapper {
          padding-bottom: 100px;
          .title {
            margin-bottom: 15px;
          }
          .subtitle {
            width: 90%;
          }
        }
        .page-hero-img-wrapper {
          bottom: 0;
          transform: translate(0, 50%);
          .page-hero-main-img {
            max-height: 170px;
          }
          .page-hero-decor-img {
            max-height: 45px;
            bottom: -22.5px;
          }
        }
      }
    }
  }
`;
