import React from 'react';

const Video = () => {
  return (
    <video
      style={{ marginTop: '10rem', marginBottom: '5rem' }}
      controls
      width="80%"
      poster="/images/video/iPath_video_poster.png"
    >
      <source src="/images/video/iPath Life Sciences.mp4" type="video/mp4" />
      Sorry, your browser doesn't support embedded videos, but don't worry, you can
      <a href="/images/video/iPath Life Sciences.mp4">download it</a>
      and watch it with your favorite video player!
    </video>
  );
};

export default Video;
