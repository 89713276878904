import React from 'react';
import SEO from '../../partials/components/SEO';
import Layout from '../../partials/Layout';
import PageScrollTop from '../../utils/PageScrollTop';
import * as Section from './sections/providers/ProvPathology';
import { GeneralComponentsStyle } from '../styles/GeneralComponentsStyle';

//CONTENT
import { provPathology as content } from './sections/providers/content/provPathology';

const ProvPathology = () => {
  const { theme, pageTitle } = content;

  return (
    <Layout>
      <PageScrollTop>
        <GeneralComponentsStyle theme={theme} bgColor={'#f4f5f9'}>
          <SEO title="Pathology" />
          <Section.PageHeroProvPathology content={content.pageHeroProvPathology} bgColor={'#f4f5f9'} />
          <Section.OurServices theme={theme} content={content.ourServices} bgColor={'#fff'} pageTitle={pageTitle} btn={false} mergeHero="merge-hero" />
          <Section.BeginWorkingTogether theme={theme} content={content.beginWorkingTogether} bgColor={'#f9f9f9'} />
          <Section.InOfficePathologyModels theme={theme} content={content.inOfficePathologyModels} bgColor={'#f4f5f9'} pageTitle={pageTitle} btn={false} />
        </GeneralComponentsStyle>
      </PageScrollTop>
    </Layout>
  );
};

export default ProvPathology;
