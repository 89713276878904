import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RiErrorWarningLine } from 'react-icons/ri';
import ThankYouPage from './ThankYouPage';
import ApiError from './ApiError';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa';
import { providerFormSchema } from '../../../validations/ProviderValidation';
import { content } from '../content/providerFormControl';
import { axiosEndPointCall } from '../../../utils/axiosEndPointCall';

function ProvidersForm() {
  const [submitted, setSubmitted] = useState({status: false, msg: null});
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [apiError, setApiError] = useState({ status: false, error: null });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onBlur', resolver: yupResolver(providerFormSchema) });

  const onSubmit = async values => {
    console.log(values);
    setLoadSpinner(true);

    const serverResponse = await axiosEndPointCall({
      // This route hasn't been created yet on the backend.
      url: 'https://ipathlifesciences.com/email/providers',
      data: values,
    });

    if (serverResponse === 'error') {
      return setApiError({
        status: true,
        error: 'Please, try back later or contact us at (863) 606-6655',
      });
    }

    reset();
    setSubmitted({status: true, msg: 'You successfully registered in our system, if you have any questions please call us at (954)281-4484'});
    return;
  };

  if (submitted.status) return <ThankYouPage msg={submitted.msg}/>;
  if (apiError.status) return <ApiError error={apiError.error} />;

  return (
    <>
      <form action="" onSubmit={handleSubmit(onSubmit)}>
        <h1 className="heading-2">Providers or Clinics</h1>
        <p className="text-block-2">
          Fill in your details below to Order Tests as a Healthcare Provider/Clinic. This lets you
          or your Clinic get access to kits for your patients.
        </p>
        {content.formStructure.map((field, index) => {
          switch (field.el) {
            case 'input':
              return (
                <div
                  key={index}
                  className={`${
                    field.attributes.className ? field.attributes.className : field.attributes.name
                  } form-group `}
                >
                  <label name={field.attributes.name} id={field.attributes.name}>
                    {field.label}
                  </label>
                  <input {...register(field.attributes.name)} {...field.attributes} />
                  <div className="error-msg">
                    {errors[field.attributes.name] ? <RiErrorWarningLine /> : null}
                    {errors[field.attributes.name]?.message}
                  </div>
                </div>
              );
            case 'select':
              return (
                <div key={index} className={`${field.attributes.name} form-group `}>
                  <label name={field.attributes.name} id={field.attributes.name}>
                    {field.label}
                  </label>
                  <select
                    defaultValue={''}
                    {...register(field.attributes.name)}
                    {...field.attributes}
                  >
                    {field.options.map(option => {
                      return (
                        <option key={option.value} value={option.value} disabled={option.disabled}>
                          {option.content}
                        </option>
                      );
                    })}
                  </select>
                  <div className="error-msg">
                    {errors[field.attributes.name] ? <RiErrorWarningLine /> : null}
                    {errors[field.attributes.name]?.message}
                  </div>
                </div>
              );
            case 'checkbox':
              return (
                <div key={index} className={`${field.attributes.name} form-group `}>
                  <input {...register(field.attributes.name)} {...field.attributes} />
                  <label name={field.attributes.name} htmlFor={field.attributes.name}>
                    {field.label}
                  </label>
                  <div className="error-msg">
                    {errors[field.attributes.name] ? <RiErrorWarningLine /> : null}
                    {errors[field.attributes.name]?.message}
                  </div>
                </div>
              );
            default:
              return null;
          }
        })}

        <div className="form-group">
          <button className="btn-default">
            Submit Form
            <span className={loadSpinner ? 'btn-loading spinner' : 'spinner'}>
              <FaSpinner />
            </span>
          </button>
        </div>

        <div className="form-group"></div>
      </form>
    </>
  );
}

export default ProvidersForm;
