import React from 'react';
import Logo from './Logo';
import Nav from './Nav';
import { FiX } from 'react-icons/fi';

const MobileMenu = ({ content, show, closeMobileMenu }) => {
  return (
    <div className={`popup-mobile-menu ${show ? 'active' : ''}`}>
      <div className="inner">
        <div className="header-top">
          <Logo
            // image={`${process.env.PUBLIC_URL}/images/nav_footer/log_iPathLifeSciences.png`}
            image2={`${process.env.PUBLIC_URL}/images/nav_footer/log_iPathLifeSciences.png`}
          />
          <div className="close-menu">
            <span className="close-button" onClick={closeMobileMenu}>
              <FiX />
            </span>
          </div>
        </div>
        <Nav content={content} closeMobileMenu={closeMobileMenu} />
        <a href="tel:+18665711383" className="call-to-action-mobile">
          <div className="content">
            <p className="assist-text">We can assist you:</p>
            <div className="phone">
              <img src="/images/nav_footer/dropdown-menu-call-bubble.svg" />
              <p className="phone"> (866) 571-1383 </p>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};
export default MobileMenu;
