import React from 'react';

const OrderedList = ({ content, bgColor, theme }) => {
  return (
    <div className="ordered-list-wrapper">
      <h2 className="heading-2 ordered-list-title">
        {content.title}
      </h2>
      <div className="ordered-list">
        {content.list.map((itemText, index) => {
          if ((index + 1) % 2 !== 0) {
            return (
              <div key={index} className="ordered-item-wrapper">
                <div className="ordered-item-number-wrapper">
                  <div className="ordered-item-number">{index + 1}</div>
                </div>
                <div className="ordered-list-item-text text-block-2">{itemText}</div>
              </div>
            );
          } else {
            return (
              <div key={index + 10} className="ordered-item-wrapper odd">
                <div className="text-block-2">{itemText}</div>
                <div className="ordered-item-number-wrapper">
                  <div className="ordered-item-number">{index + 1}</div>
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default OrderedList;
