import { useEffect } from "react";
import { withRouter } from 'react-router-dom';

const PageScrollTop = ({children}) => {
  useEffect(() => {
      window.scrollTo(0, 0);
  }, []);
  return children;
};

export default withRouter(PageScrollTop);