import styled from 'styled-components';
import device from '../../../styles/mediaSizes';

export const StyledChooseMethod = styled.div`
  .text-block-2{
    margin-bottom: 55px;
  }

  .text-button{
    margin-top: 55px;
    display: inline-block;
  }


  .no-footer{
    min-height: 58vh;
    @media ${device.lg}{
      min-height: 68vh;
    }
  }


  .button-group{
    width: 100%;
  }

  @media ${device.sm} {
    .counter-image-decor{
      margin-bottom: 50px;
    }  
  }

`