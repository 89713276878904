import React from 'react';
import styled from 'styled-components';
import device from '../../pages/styles/mediaSizes';

const StyledAbidingByLinks = styled.div`
  & {
    width: 25%;
    margin-top: 40px;
    .logos-wrapper {
      display: flex;
      justify-content: space-between;
      .logo {
        display: inline-block;
        max-width: 110px;
        max-height: 30px;
      }
    }
  }

  @media ${device.lg} {
    & {
      width: 40%;
      .logos-wrapper {
        display: flex;
        justify-content: space-between;
        .logo {
          display: inline-block;
          max-width: 110px;
          max-height: 30px;
        }
      }
    }
  }

  @media ${device.md} {
    & {
      width: 50%;
      .logos-wrapper {
        display: flex;
        justify-content: space-between;
        .logo {
          display: inline-block;
          max-width: 110px;
          max-height: 30px;
        }
      }
    }
  }

  @media ${device.sm} {
    & {
      width: 100%;
      .logos-wrapper {
        display: flex;
        justify-content: space-between;
        .logo {
          display: inline-block;
          max-width: 110px;
          max-height: 30px;
        }
      }
    }
  }
`;

const AbidingByLinks = () => {
  return (
    <StyledAbidingByLinks className="featured-logos">
      <div className="logos-wrapper">
        <div className="logo-wrapper">
          <img src="/images/partners/CLIA.png" loading="lazy" alt="CLIA-logo" className="logo" />
        </div>
        <div className="logo-wrapper">
          <img src="/images/partners/CMS.png" loading="lazy" alt="CMS-logo" className="logo" />
        </div>
        <div className="logo-wrapper">
          <img src="/images/partners/HIPAA.png" loading="lazy" alt="HIPAA-logo" className="logo" />
        </div>
      </div>
    </StyledAbidingByLinks>
  );
};

export default AbidingByLinks;
