import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";

export const teamArea = [
  {
    card: {
      img: "/images/team/NicholasALancia.png",
      name: ["Nicholas A. Lancia", "M.D."],
      position: "Pathologist",
      specialty: ["Board-Certified in Anatomic Pathology", "Board-Certified in Clinical Pathology"],
      location: "FL, USA",
      description: "a Curriculum  Vitae",
      turnOnSocial: false,
      socialNetwork: [
        {
          icon: <FiFacebook />,
          url: "#",
        },
        {
          icon: <FiTwitter />,
          url: "#",
        },
        {
          
          icon: <FiInstagram />,
          url: "#",
        },
      ],
    },

    popup: {
      img: "/images/team/NicholasALancia.png",
      name: "Nicholas A. Lancia, M.D",
      position: "Pathologist",
      specialty: "Board-Certified in Anatomic Pathology, Board-Certified in Clinical Pathology",
      desc: [
        {
          listTitle: "Education",
          list: [
            "Bachelor of Science in Integrated Life Sciences June 2000 – Sept. 2002 Kent State University, Kent, OH Graduated Magna cum Laude",
            "Doctor of Medicine Sept. 2002 – May 2006 Northeast Ohio Medical University, Rootstown, OH Combined B.S./M.D. program with Kent State University Formerly called Northeastern Ohio Universities College of Medicine"
          ],
        },
        {
          listTitle: "Residency",
          list: [
            "Combined AP/CP Residency July 2006 – June 2010 University of Florida / Shands Medical Center, Gainesville, FL",
            "Training experience at both UF/Shands Medical Center and Veterans’ Hospital."
            ],
        },
        {
          listTitle: "Fellowship",
          list: [
            "Gastrointestinal Pathology Fellowship July 2013 – May 2014 Ameripath GI Institute, Oakwood Village, OH",
            "GI fellowship included lectures and sign-out responsibilities. Personal case volume of 5,000 cases plus non-gyn cytology. Institutional annual case volume of over 35,000."
          ]
        },
        {
          listTitle: "Strengths",
          list: [
            "Gastrointestinal Pathology, Liver Pathology, Digital Pathology, Medical Contracting, Clinical Laboratory Medicine, Laboratory Administration (ASCP LMU Graduate), Quality Assurance"
          ]
        }

      ],
    },
  },

  {
    card: {
      img: "/images/team/SarahStaceyTorres.png",
      name: ["Sarah Stacey Torres"],
      position: "Histotechnologist Supervisor",
      specialty: ["ASCP Board Certified",
"Licensed-Laboratory Professional"],
      location: "FL, USA",
      description: "a Curriculum  Vitae",
      turnOnSocial: false,
      socialNetwork: [
        {
          icon: <FiFacebook />,
          url: "#",
        },
        {
          icon: <FiTwitter />,
          url: "#",
        },
        {
          icon: <FiInstagram />,
          url: "#",
        },
      ],
    },

    popup: {
      img: "/images/team/SarahStaceyTorres.png",
      name: "Sarah Stacey Torres",
      position: "Histotechnologist Supervisor",
      specialty: null,
      desc: [
        {
          listTitle: "Education",
          list: [
            "Bachelor’s in health services administration, Kaiser University 2012",
            "Associate of Science in Histotechnology, Kaiser University",
          ],
        },
        {
          listTitle: "Certification",
          list: ["Clinical Laboratory Supervisor"],
        },
      ],
    },
  },

  // {
  //   card: {
  //     img: "/images/team/kamino.jpg",
  //     name: ["Hideko Kamino", "M.D."],
  //     position: "Pathologist",
  //     specialty: ["Board-Certified Pathologist", "Board-Certified Dermatopathologist"],
  //     location: "FL, USA",
  //     description: "a Curriculum  Vitae",
  //     turnOnSocial: false,
  //     socialNetwork: [
  //       {
  //         icon: <FiFacebook />,
  //         url: "#",
  //       },
  //       {
  //         icon: <FiTwitter />,
  //         url: "#",
  //       },
  //       {
  //         icon: <FiInstagram />,
  //         url: "#",
  //       },
  //     ],
  //   },

  //   popup: {
  //     img: "/images/team/kamino.jpg",
  //     name: "Hideko Kamino, M.D.",
  //     position: "Pathologist/ Dermatopathologist",
  //     specialty: "Board-Certified Pathologist/ Board-Certified Dermatopathologist",
  //     desc: [
  //       {
  //         listTitle: "Education",
  //         list: [
  //           "UCLA (Pathology), Residency Training 1983-1984",
  //           "Mount Sinai Medical Center (Pathology), Residency Training 1981-1983",
  //           "NYU Medical Center (Dermatopathology), Clinical Fellowships 1978-1979",
  //           "Univ. Nacional Autonoma De Mexico, Medical Education 1963-1970",
  //         ],
  //       },
  //       {
  //         listTitle: "Board Certification",
  //         list: ["Dermatopathology 1985", "Anatomic & Clinical Pathology 1984"],
  //       },
  //       {
  //         listTitle: "Associations",
  //         list: [
  //           "Past President of the American Society of Dermatopathology",
  //           "Editorial Board of the American Journal of Dermatopathology"
  //         ],
  //       },
  //       {
  //         listTitle: "Research Interests",
  //         list: [
  //           "Malignant Melanoma and Melanocytic Nevi Fibrohistocytic Lesions of the Skin",
  //         ],
  //       }
  //     ],
  //   },
  // },

  // {
  //   card: {
  //     img: "/images/team/JeanneAckerman.jpg",
  //     name: ["Jeanne Ackerman", "M.D."],
  //     position: "Pathologist",
  //     specialty: ["Board-Certified Pathologist", " "],
  //     location: "FL, USA",
  //     description: "a Curriculum  Vitae",
  //     turnOnSocial: false,
  //     socialNetwork: [
  //       {
  //         icon: <FiFacebook />,
  //         url: "#",
  //       },
  //       {
  //         icon: <FiTwitter />,
  //         url: "#",
  //       },
  //       {
  //         icon: <FiInstagram />,
  //         url: "#",
  //       },
  //     ],
  //   },

  //   popup: {
  //     img: "/images/team/JeanneAckerman.jpg",
  //     name: "Jeanne Ackerman, M.D.",
  //     position: "Pathologist",
  //     specialty: "Board-Certified Pathologist",
  //     desc: [
  //       {
  //         listTitle: "Education",
  //         list: [
  //           "Bachelor of Science Wheaton College Wheaton, IL 1982-1986",
  //           "King’s College University of London Faculty of Biophysics London, UK 1984-1985",
  //           "Doctor of Medicine University of South Florida College of Medicine Tampa, FL 1987-1992",
  //           "Resident Physician Department of Pathology and Laboratory Medicine University of South Florida College of Medicine Tampa, FL 1992-1997",
  //           "Chief Resident Department of Pathology and Laboratory Medicine University of South Florida College of Medicine Tampa, FL 1996-1997",
  //           "Fellow, Pediatric Pathology Department of Pathology and Laboratory Medicine University of South Florida College of Medicine Tampa, FL 1/1998-12/1998",
  //         ],
  //       },
  //       {
  //         listTitle: "Board Certification",
  //         list: ["Anatomic and Clinical Pathology, May 1998", "Pediatric Pathology, August 1999"],
  //       },
  //     ],
  //   },
  // },
];
