import React from 'react';
import { Link } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';
import {GeneralComponentsStyle} from '../../styles/GeneralComponentsStyle';

const BuyTestBtn = ({ theme, text, link }) => {
  return (
    <GeneralComponentsStyle bg={theme.btnColor} hover={theme.secColor}>
      <div className="button-wrapper">
        <Link className="button-1" to={link}>
          {text ? text : 'Request A Test Kit Now'}
          <i className="icon">
            <FiArrowRight />
          </i>
        </Link>
      </div>
    </GeneralComponentsStyle>
  );
};

export default BuyTestBtn;
