import React from 'react';

const CardInfo = ({ card, index }) => {
  return (
    <div key={index} className="col-lg-4 col-md-6 col-12">
      <div className="rn-address">
        <div className="icon">{card.icon}</div>
        <div className="inner">
          <h4 className="title">{card.title}</h4>
          {card.info.map((contact, index) => {
            return (
              <p key={index}>
                <a href={contact.href} target={contact.target}>
                  <b>{contact.type}</b> {contact.data}
                </a>
                {contact.desc}
              </p>
            );
          })}
        </div>
        <p>{card.desc}</p>
      </div>
    </div>
  );
};

export default CardInfo;
