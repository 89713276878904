import React from 'react';

const NumberedList = ({ content, title, intro, items }) => {
  if(content){
    return (
      <div className="numbered-list-wrapper">
        {content.numberedList.listTitle ? (
          <h3 className="title heading-5"> {content.numberedList.listTitle} </h3>
        ) : null}
        {typeof content.intro === 'string' ? <p className="check-list-intro text-block-2">{content.intro}</p> : content.intro}
        <div className="list">
          {content.numberedList
            ? content.numberedList.items.map((numberedListItem, index) => {
                return (
                  <div key={index} className={numberedListItem.title ? 'titled-item' : 'item'}>
                    <div className="circle">
                      <div className="number">{index + 1}</div>
                    </div>
                    <div className="title-group">
                      {numberedListItem.title ? (
                        <h5 className="item-title">{numberedListItem.title}</h5>
                      ) : null}
                      <p className="description text-block-3">{numberedListItem.desc}</p>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    );
  }
  return (
    <div className="numbered-list-wrapper">
      {title ? (
        <h3 className="numbered-list-title heading-5"> {title} </h3>
      ) : null}
      {typeof intro === 'string' ? <p className="numbered-list-intro text-block-2">{intro}</p> : intro}
      <div className="numbered-list">
        {items
          ? items.map((numberedListItem, index) => {
              return (
                <div key={index} className= 'numbered-list-item'>
                  <div className="numbered-list-item-circle">
                    <div className="numbered-list-item-number">{index + 1}</div>
                  </div>
                  <div className="numbered-list-item-title-group">
                    {numberedListItem.title ? (
                      <h5 className="numbered-list-item-title">{numberedListItem.title}</h5>
                    ) : null}
                    <p className="numbered-list-item-text text-block-3">{numberedListItem.desc}</p>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default NumberedList;


