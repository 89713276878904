import React from 'react';
import CardInfo from './components/CardInfo';
import ContactForm from './components/ContactForm';
import GoogleMapStyle from './components/GoogleMap';
import SectionTitle from './components/SectionTitle';
import { content } from '../content/contactPage';
import { StyledContact } from './styles/StyledContact';

const sectionProps = {
  textAlign: 'text-center',
  radiusRounded: '',
  // subtitle: "Contact Form",
  title: 'The Fastest Way To Get Results.',
  description: '',
};

const ContactInfo = ({bgColor}) => {
  return (
    <StyledContact>
      <div className="rn-section-gap" style={{backgroundColor: bgColor}}>
        <div className="container">
          <div className="row">
            <SectionTitle {...sectionProps} />
            {content.infoCards.map((card, index) => {
              return <CardInfo key={index} card={card} index={index} />;
            })}
          </div>
        </div>
      </div>
    </StyledContact>
  );
};

export default ContactInfo;
