import React from 'react';

const tableList = ({title, table, tailText})=>{
  return(
    <div className="bullet-list-table-wrapper">
      <h3 className="bullet-list-table-title">{title}</h3>
      {table
        ? table.map((row, i) => {
            return (
              <div key={i} className="bullet-list-table-item">
                <div className="item-title-col">
                  <h5 className="item-title heading-5">{row.title}</h5>
                  {
                    row.subtitle ? 
                    <p className="item-subtitle text-block-3">{row.subtitle}</p> :
                    null
                  }
                </div>
                <div className="item-list-col">
                  <ul className="item-list">
                    {row.list.map((item, i) =>
                      (<li key={i} className="text-block-3 item"> {item} </li>)
                    )}
                  </ul>
                </div>
              </div>
            );
          })
        : null}
      {/* Tail Text */}
      <div className="tail-text-wrapper">
        {tailText ?
          tailText.map((p, i) =>
            typeof p === 'string' ? (
              <p 
              key={i} 
              className= "tail-text-text text-block-2"> 
                {p} 
              </p>) : (p)
          ) 
        : null}
      </div>
    </div>
  )
}

export default tableList;