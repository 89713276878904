import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const SectionTitle = ({
  bigTitle,
  smallTitle,
  subtitle,
  description,
  animated,
  title = bigTitle || smallTitle
}) => {
  
  const scrollOptions = () => {
    if (animated) {
      return {
        animateIn: 'fadeInUp',
        animateOut: 'fadeInOut',
        animateOnce: true,
      };
    }
    return {
      animateIn: 'no-animation',
      animateOut: 'no-animation',
      animateOnce: true,
    };
  };
  
  return (
    <div className="section-title-wrapper">
      <ScrollAnimation {...scrollOptions()}>
        {typeof title === 'string' ? (
          bigTitle ? (
            <h2 className="title heading-1">{bigTitle}</h2>
          ) : (
            <h2 className="title heading-2">{smallTitle}</h2>
          )
        ) : (
          title
        )}
      </ScrollAnimation>
      <ScrollAnimation {...scrollOptions()}>
        {subtitle ? <h4 className="subtitle heading-4">{subtitle}</h4> : null}
      </ScrollAnimation>
      <ScrollAnimation {...scrollOptions()}>
        {description ? <p className="description text-block-2">{description}</p> : null}
      </ScrollAnimation>
    </div>
  );
};
export default SectionTitle;
