import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { hipaaContent } from './HipaaContent';
import { StyledPolicies } from './styles/StyledPolicies';
import Layout from '../../partials/Layout';

const Hipaa = () => {
  useEffect(() => {
    document.title = 'Privacy policy';
  }, []);

  return (
    <Layout>
      <StyledPolicies className="p_policy">
        <Container className="p_policy-s-1 pt-xs-2p5 pt-md-2p5 pb-xl-10 container">
          <Row>
            <Col className="pb-5" xs={12} xl={5}>
              <h2 className="pb-5 heading-1">
                <strong>HIPAA Notice</strong>
              </h2>
              {/* <h1 className='pb-5' ><strong>Privacy Policy</strong></h1> */}
              <p className="toUpperCase">
                NOTICE OF PRIVACY PRACTICES PURSUANT TO THE HEALTH INSURANCE PORTABILITY AND
                ACCOUNTABILITY ACT OF 1996 (HIPAA)
              </p>
              <p className="toUpperCase">
                THIS NOTICE DESCRIBES AND INFORMS YOU HOW YOUR MEDICAL INFORMATION MAY BE USED AND
                DISCLOSED. SPECIFICALLY, HOW YOUR MEDICAL INFORMATION MAY BE:
              </p>
              <p className="toUpperCase">
                (A) PROCESSED, USED OR DISCLOSED, AND (B) ACCESED BY YOU.
              </p>
              <p className="toUpperCase">
                PLEASE TAKE THE TME TO READ THIS PAGE CAREFULLY. WE ARE AVAILABLE TO YOU FOR ANY
                QUESTIONS, COMMENTS OR CONCERNS
              </p>
              <p className="fc-primary_medium">
                <small className="fw-500">Last Revision: 20-Aug-2023</small>
              </p>
            </Col>
            <Col xs={12} xl={7}>
              {hipaaContent.map(text => {
                return (
                  <>
                    {text.title && <h3 className="pb-xs-1p0 pb-md-2p0 fw-600">{text.title}</h3>}
                    {text?.paragraphs &&
                      text.paragraphs.map(p => {
                        return <p className="pb-2p0">{p}</p>;
                      })}
                  </>
                );
              })}
            </Col>
          </Row>
        </Container>
      </StyledPolicies>
    </Layout>
  );
};

export default Hipaa;
