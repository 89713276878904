import React from 'react';
import SEO from '../../partials/components/SEO';
import Layout from '../../partials/Layout';
import PageScrollTop from '../../utils/PageScrollTop';
import * as Section from './sections/individuals/HereditaryCancer';
import { GeneralComponentsStyle } from '../styles/GeneralComponentsStyle';

//CONTENT
import { hereditaryCancer as content } from './sections/individuals/content/hereditaryCancer';

const HereditaryCancer = () => {
  const { theme, pageTitle } = content;

  return (
    <Layout>
      <PageScrollTop>
        <GeneralComponentsStyle theme={theme} className={pageTitle}>
          <SEO title="Hereditary Cancer Test" />
          <Section.HeroBannerCancer theme={theme} content={content.sliderArea} pageTitle={pageTitle} />
          <Section.ArticleVideo theme={theme} content={content.anInsightThatIsUnmatched} bgColor={'#7c30a7'} />
          <Section.PicTextCTO theme={theme} content={content.picTextCTO} bgColor={'#fff'} btn={true} />
          <Section.RisksOfCancer theme={theme} content={content.risksOfCancer} bgColor={'#f4f5f9'} />
          <Section.RunInYourFamily theme={theme} content={content.runInYourFamily} bgColor={'#fff'} />
          <Section.PicTextHelpOfGenetics theme={theme} content={content.picTextHelpOfGenetics} bgColor={'#f8f8f8'} btn={false} />
          <Section.BenefitsOfTesting theme={theme} content={content.benefitsOfTesting} pageTitle={pageTitle} bgColor={"#f4f5f9"} btn={false} />
          <Section.HowItWorks theme={theme} content={content.howItWorks} bgColor={'#fdfdfe'} />
          <Section.PicTextPrivacy theme={theme} content={content.picTextPrivacy} bgColor={'#f4f5f9'} btn={false} />
          <Section.FaqArea theme={theme} content={content.faqArea} bgColor={'#f8f8f8'} />
        </GeneralComponentsStyle>
      </PageScrollTop>
    </Layout>
  );
};

export default HereditaryCancer;
