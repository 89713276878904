import React from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

const ApiError = ({ error }) => {
  const scrollOptions = {
    animateIn: 'fadeInUp',
    animateOut: 'fadeInOut',
    animateOnce: true,
  };
  return (
    <div className='container d-flex justify-content-center align-items-center flex-column' style={{ height: '50vh', width: '90%', textAlign: 'center' }}>
      <ScrollAnimation {...scrollOptions}>
        <h1 className='heading-1'>Ops! Something Went Wrong</h1>
      </ScrollAnimation>
      <ScrollAnimation {...scrollOptions}>
        <p className='text-block-2 mb-5'> {error} </p>
        {/* <p className='text-block-2'>Try Back Later, Please</p> */}
      </ScrollAnimation>
      <ScrollAnimation {...scrollOptions}>
        <Link className='btn-default' to='/'>
          Back to Homepage
        </Link>
      </ScrollAnimation>
    </div>
  );
};

export default ApiError;
