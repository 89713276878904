import React from 'react';

export const hereditaryCancer = {
  pageTitle: 'Cancer',

  sliderArea: {
    sectionName: 'hero-cancer',
    title: ['Hereditary ', 'Cancer Test'],
    subtitle: 'Up to 15% of most cancers are due to genetic mutations, or changes, that are passed down from parents.',
    description: [null],
    img: '/images/pages/cancer-hero.svg',
  },

  anInsightThatIsUnmatched: {
    textLight: true,
    decorImg: '/images/decor/decor-drop-purple.svg',
    decoratedTitle: 'An Insight That Is Unmatched',
    sideText: [
      'The adage "it runs in the family" is well known to us all. Much of what makes us who we are physically originates from our DNA at birth.',
      'Knowledge of your genetic data and predisposition to cancers, gives an insight that is unmatched. It also helps diagnosis and prevention against life threatening illnesses for your patients—which is available knowledge',
    ],
    pLeft: [
      <p key="special-1" className="bold-text text-light text-block-2">
        Genes Can Alter Over Time.
      </p>,
      'DNA alterations are the root cause of all malignancies. DNA mutations are common, as the changes can happen when our cells split to create new cells. Fortunately, our bodies automatically repair these alterations. Although occasionally the mutation is not corrected, and it is reproduced when the cell divides. If the mutation is damaged and impairs a vital process, cancer may develop in that new cell.',
      <p key="special-2" className="bold-text text-light text-block-2">
        There are two types of gene changes that are important in cancer:
      </p>,
      <p key="special-1.5" className="text-light text-block-2">
        <i>Random Changes:</i>
      </p>,
      'Errors that happen by chance in DNA and continue to occur as the cell divides. These occur naturally over time.  Radiation or certain chemicals can also make it more likely that genes will change.',
      <p key="special-1.6" className="text-light text-block-2">
        <i>Inherited Changes:</i>
      </p>,
      'Genetic mutations that a person is born with. While a lot of cancers are caused by random changes, a significant percentage are passed down from parent to child. Some inherited or genetic mutations that are passed down can make it much more likely a patient will acquire cancer at some point in life, get it sooner, or even be prone to get more than one type of cancer in their lifetime.',
    ],
    pRight: [
      <p key="special-3" className="bold-text text-light text-block-2">
        Family Tree.
      </p>,
      'Patients who have a history of a certain diseases in their family can take steps to lower their risk of inheriting that disease. If you have more than one relative with a certain illness, like breast or ovarian cancer, it is possible that it is a hereditary cancer.',
      <p key="special-4" className="bold-text text-light text-block-2">
        Are you at risk of cancer?
      </p>,
      <p key="special-4.2" className="text-light text-block-2">
        {' '}
        <a
          href="https://app.nexgenprecision.com/"
          target="_blank"
          className="text-button text-light"
        >
          iPath Life Sciences Hereditary Cancer Assessment Quiz{' '}
        </a>
        will help figure out if you might need to be tested. By answering a few simple questions,
        you can get the information you need to talk to your physician about the risks of cancer and
        determine if more testing is required.
      </p>,
      'The quiz will find red flags in your own personal history or the history of your immediate family members, you may want to get tested for cancer genes.',
      <p key="special-5" className="bold-text text-light text-block-2">
        How a disease that runs in the family can be passed on?
      </p>,
      'Some inherited conditions are more likely to occur in certain groups of people, just like some families have a higher risk of cancer. People in these groups may have been born with a certain set of genes inherited from their ancestors. If a group of people all have the same genes, and some of those genes have a mutation that can cause a disease, that disease would then have a larger likelihood of developing within that familial group. For example, people with Ashkenazi, or Eastern European Jewish, roots are more likely to have hereditary breast and ovarian cancer syndrome (HBOC).',
    ],
  },

  picTextCTO: {
    img: '/images/pages/cancer-microscope-lab.jpeg',
    imgMobile: '/images/pages/cancer-microscope-lab.jpeg',
    regularTitle: 'At iPath Life Sciences, we believe in being thorough—',
    largeTitle: null,
    specialTitle: null,
    subtitle: null,
    p: [
      'That’s why we’ve developed innovative, genetic cancer testing solutions that analyze a higher number of gene markers than most laboratories.',
      'Our Simple Non-Invasive Buccal Swab Test sets a new standard for accuracy, ease of use, and convenience.',
    ],
  },

  risksOfCancer: {
    textLight: false,
    decorImg: null,
    decoratedTitle: null,
    regularTitle: 'What Are My Risks of Inherited Cancer?',
    sideText: null, //array
    pLeft: [
      "Our patients learn whether they're predisposed to developing one or more cancers.",
      'DNA alterations are the root cause of all malignancies. DNA mutations are common, as the changes can happen when our cells split to create new cells. Fortunately, our bodies automatically repair these alterations. Although occasionally the mutation is not corrected, and it is reproduced when the cell divides. If the mutation is damaged and impairs a vital process, cancer may develop in that new cell.',
      'Genes contain the information your body uses to grow and function. You have 2 copies of each gene, with 1 copy inherited from each parent. Sometimes, particular mutations, or changes, to genes can increase the risk of developing cancer. These mutations may be a sporadic, one-time change that occur during your life. Or, the mutations may be inherited from your parents. When a certain type of cancer seems to be common in a family, doctors may be able to identify the specific genetic change causing the increased risk. These types of genetic changes are called “hereditary cancers” or “inherited cancer syndromes.” Specific laboratory tests can identify these changes before cancer even develops.',
    ],
    pRight: [
      <p key="special-232" className="text-block-2">
        iPath Life Sciences’s <b>Nextseq2000 System</b> is a genetic sequencer from Illumina, that provides
        High quality next-generation sequencing (NGS) technology with a fast turn around on results
        to identify mutations in cancer-causing genes.
      </p>,
      'Patients who have a history of a certain diseases in their family can take steps to lower their risk of inheriting that disease. If you have more than one relative with a certain illness, like breast or ovarian cancer, it is possible that it is a hereditary cancer.',
      'If you have a mutation that makes you more likely to develop cancer, you and your healthcare provider can take the necessary steps to lower your susceptibility to catching the disease. There are several simple, non-invasive ways to reduce your risk of developing cancer, such as earlier and more frequent screenings, taking preventive drugs, and through risk reducing surgeries. Once you know how likely you are to obtain cancer, you and your healthcare provider can decide on the best course of treatment.',
    ],
    tailText: [
      <p key="special-524" className="tail-text text-block-2">
        {' '}
        Take the{' '}
        <a target="_blank" href="https://app.nexgenprecision.com/" className="text-button">
          {' '}
          iPath Life Sciences Hereditary Cancer Assessment Quiz{' '}
        </a>
        to find out if you might be at risk for cancer and could benefit from genetic testing and
        request additional testing by discovering concerns with your personal or family's medical
        history.
      </p>,
    ],
  },

  runInYourFamily: {
    title: 'Does Cancer Run in Your Family?',
    subtitle: [
      'Early detection of cancer traces through genetics testing helps practitioners spot potentially critical cases before they become critical, thereby saving patient lives and that of their families. This same method applies for all other health related illness that could be checked with Genetic Testing',
    ],
    // AFTER REFACTORED DELETE TITLE AND SUBTITLE ABOVE
    divider: { lines: 1, text: 'IMPORTANT' },
    sectionTitle: {
      animated: false,
      bigTitle: null,
      smallTitle: 'Does Cancer Run in Your Family?',
      subtitle: null,
      description:
        'Early detection of cancer traces through genetics testing helps practitioners spot potentially critical cases before they become critical, thereby saving patient lives and that of their families. This same method applies for all other health related illness that could be checked with Genetic Testing',
    },
    tableList: {
      title: 'CANCER CAN BE INHERITED IF:',
      table: [
        {
          title: 'Two or More',
          subtitle: 'A combination of cancers on the same side of the family',
          list: [
            '2 or more: breast / ovarian / prostate / pancreatic cancer',
            '2 or more: colorectal / endometrial / ovarian / gastric / pancreatic / other cancers (i.e., ureter/renal pelvis, biliary tract, small bowel, brain, sebaceous adenomas)',
            '2 or more: melanoma / pancreatic cancer',
          ],
        },
        {
          title: 'Early Age (<50)',
          subtitle: null,
          list: ['Breast cancer', 'Colorectal cancer', 'Endometrial cancer'],
        },
        {
          title: 'Rare Cases',
          subtitle: 'Any one of these rare presentations at any age',
          list: [
            'Ovarian cancer ',
            'Breast: male breast cancer or triple negative breast cancer ',
            'Colorectal cancer with abnormal, ',
            '10 or more gastrointestinal polyps*',
          ],
        },
      ],
      tailText: [
        <p key="special-524" className="tail-text text-block-2">
          {' '}
          ** Ashkenazi Jewish ancestry may have greater risk of contracting hereditary cancer <br />{' '}
          *** The above criteria are based on the latest NCCN guidelines
        </p>,
      ],
    },
  },

  picTextHelpOfGenetics: {
    img: '/images/pages/cancer-help-of-genetics.svg',
    imgMobile: '/images/pages/cancer-help-of-genetics.svg',
    regularTitle: 'With the Help of Genetics',
    largeTitle: null,
    subtitle: 'Quick Action Can Be Taken to Mitigate Cancer Risk and Death',
    p: [
      "30-50% of cancers can currently be prevented by reducing factors that increase a patient's risk. This applies to many of the common health killers in the USA today.",
      'Early detection through accurate testing could mean improved chances for treating and curing cancer along with a reduction in mortality rates, which in turn helps reduce the overall burden on society.',
    ],
  },

  benefitsOfTesting: {
    sectionTitle:{
      bigTitle: 'Benefits of Testing',
      subtitle: 'iPath Life Sciences provides a wide range of genetic testing options.',
    },
    checkLists: {
      listOne: {
        title: null,
        intro: ['Hereditary Cancer Genetic Tests which identify cancer cells within your genes, and then determines your future risk.'],
        imgCheck: '/images/decor/single-thick-purple.svg',
        content: [
          {
            checkText: 'Tests that predict the likelihood of a disease progression and the severity of the subsequent disease.',
            innerList: null,
          },
          {
            checkText: 'Companion Diagnostic Procedures that support treatment plans',
            innerList: null,
          },
        ],
      },
      listTwo: {
        title: "Genetic testing's objectives are to:",
        intro: null,
        imgCheck: '/images/decor/single-thick-purple.svg',
        content: [
          {
            checkText: null,
            innerList: [
              'Provide useful diagnostic information that can be used in specific medical management plans;',
              'Identify any genetic mutations that raises your chance for developing inherited malignancies;',
              'Assist your healthcare provider in determining a prompt and accurate diagnosis;',
              'Assist your healthcare provider in predicting how aggressively a disease will spread;',
              'Assist your healthcare provider make the best possible decisions regarding the treatment of your disease.',
            ],
          },
        ],
      },
    },
    numberedList: {
      title: 'Who Needs to Know Their Genes?',
      intro:
        'Genetic testing could be an important first step for you if you have a family history of cancer, were personally diagnosed with a form of cancer at a young age, or you had/have a rare form of cancer.',
      items: [
        {
          title: null,
          desc: 'You might be told to get checked more often to help find cancer at an earlier, easier-to-treat stage and improve your chances of surviving cancer.',
        },
        {
          title: null,
          desc: "Also, family members who don't have mutations that make them more likely to get cancer might not have to go through unnecessary medical procedures.",
        },
        {
          title: null,
          desc: 'Your doctor or nurse may suggest steps you can take to lower your risk of getting cancer, such as taking medicines or having surgery to lower your risk.',
        },
        {
          title: null,
          desc: 'Your relatives can learn more about the risk they may have inherited and how it may affect them by looking at the results of the tests.',
        },
        {
          title: null,
          desc: 'You and your health care provider can make better choices about how to treat you..',
        },
      ],
    },
    tailText: {
      title: 'Am I someone who should get genetic testing?',
      desc: [
        <p key="special-4.2" className="text-block-2">
          Take the{' '}
          <a target="_blank" href="https://app.nexgenprecision.com/" className="text-button">
            iPath Life Sciences Hereditary Cancer Assessment Quiz
          </a>{' '}
          to find out if you might benefit from hereditary cancer testing.
        </p>,
      ],
    },
  },

  howItWorks: {
    sectionTitle: {
      bigTitle: 'How it works',
      description: 'We’re driven by our commitment to deliver exceptional experiences to our customers. We value our strong customer relationships and are defined by them.',
    },
    steps: {
      counterTitle: 'Step',
      steps: [
        {
          stepTitle: 'Do you qualify?',
          stepDesc:
            'Fill in our intake questionnaire, online or via phone. We will assess your request and share it with your Primary Care to conduct a consult with you. If the physician approves your request, we will send you a kit in the mail, at no charge.',
        },
        {
          stepTitle: 'Receive Your Test Kit',
          stepDesc:
            'Once you receive your kit, follow the instructions inside and administer the test on yourself. You can also visit your local health care provider and have them conduct it for you.',
        },
        {
          stepTitle: 'Send Kit To Lab',
          stepDesc:
            'Place your sample back in the kit. Make sure the pre-paid sticker is placed outside of the kit, and drop it off in your mailbox for pickup. ﻿',
        },
        {
          stepTitle: 'Processing & Report',
          stepDesc:
            'Get results sent to you and your physician via email as well as a hard copy sent to your house.',
        },
      ],
    },
  },

  picTextPrivacy: {
    img: '/images/pages/cancer-privacy-shield.svg',
    imgMobile: '/images/pages/cancer-privacy-shield.svg',
    regularTitle: null,
    largeTitle: 'Privacy',
    subtitle: 'How is my privacy protected?',

    p: [
      'To prevent unauthorized access or disclosure, to maintain data accuracy, and to ensure the appropriate use of information, we have incorporated a number of physical, technical, and administrative security measures.',
      'Your personal information and privacy is always assured in accordance with current technological and industry standards.',
      'In particular, all connections to and from our website are encrypted using Secure Socket Layer (SSL) technology.',
    ],
  },

  faqArea: {
    sectionTitle: {
      bigTitle: 'Frequently Asked Questions'
    },
    accordionBS:{
      items: [
        {
          title: 'What is Genetic Testing?',
          text: 'Genetic testing sequences DNA to identify any different mutations, differences, or anomalies that may result in the development of hereditary conditions. Prior to ordering a genetic test, we recommend that you educate yourself about the procedure, benefits, limitations and possible consequences of a test result by reading about our tests here. When you’ve educated yourself about the tests and essentially obtained permission for testing, you’ve acquired informed consent. This means you can change your mind about wanting the process even after the test sample has already been collected.'
        },
        {
          title: 'Is iPath Life Sciences’s Genetic Testing Covered by Health Insurance?',
          text: 'In most cases, your health insurance or medicare will cover the cost of testing if genetic testing is recommended to you by your doctor. If you are interested in ordering genetic tests, you should contact your insurance company beforehand and ask about coverage.',
        },
        {
          title: 'Does my doctor need to OK this test?',
          text: 'This test can be approved by your Primary Care Physician. We CAN NOT send any of our patients a kit unless a doctor signs off on it being Medically Necessary.',
        },      
        {
          title: 'Can I cancel if I want to?',
          text: 'If for any reason throughout the process, you decide not to move forward with our tests, iPath Life Sciences will cancel your request immediately.',
        }
      ]
    },
    questions: [
      'What is Genetic Testing?',
      'Is iPath Life Sciences’s Genetic Testing Covered by Health Insurance?',
      'Does my doctor need to OK this test?',
      'Can I cancel if I want to?',
    ],
    answers: [
      'Genetic testing sequences DNA to identify any different mutations, differences, or anomalies that may result in the development of hereditary conditions. Prior to ordering a genetic test, we recommend that you educate yourself about the procedure, benefits, limitations and possible consequences of a test result by reading about our tests here. When you’ve educated yourself about the tests and essentially obtained permission for testing, you’ve acquired informed consent. This means you can change your mind about wanting the process even after the test sample has already been collected.',
      'In most cases, your health insurance or medicare will cover the cost of testing if genetic testing is recommended to you by your doctor. If you are interested in ordering genetic tests, you should contact your insurance company beforehand and ask about coverage.',
      'This test can be approved by your Primary Care Physician. We CAN NOT send any of our patients a kit unless a doctor signs off on it being Medically Necessary.',
      'If for any reason throughout the process, you decide not to move forward with our tests, iPath Life Sciences will cancel your request immediately.',
    ],
  },

  theme: {
    pageTitle: 'cancer',
    themeColor: 'purple',
    mainColor: '#4557ce',
    secColor: ' #363b77',
    btnColor: '#4557ce',
    thirdColor: '#ece4fa',
    dropColor: '#b3bcfbff',
  },
};
