import React from 'react';

const Divider = ({ content, lines, text }) => {
  const linesTimes = nOfLines => {
    for (let i = 0; i < nOfLines; i++) {
      return <div className={`divider-line-${i+1}`}></div>
    }
  };
  return (
    <div className="divider-wrapper">
      <div className="divider-line-group">{linesTimes(lines)}</div>
      {text ? <p className="divider-text">{text}</p> : null}
    </div>
  );
};

export default Divider;
