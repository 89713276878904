import styled from 'styled-components';
import React from 'react';
import device from '../../../styles/mediaSizes';

export const StyledContact = styled.div`
  .rn-address {
    margin-top: 30px;
    padding: 40px;
    min-height: 330px;
    border-radius: 10px;
    padding-top: 40px;
    background: #fff;
    padding-bottom: 50px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    transition: 0.3s;
    height: 100%;

    @media ${device.lg} {
      padding: 30px 20px;
    }
    @media ${device.md} {
      padding: 30px 20px;
    }
    @media ${device.sm} {
      padding: 30px 20px;
    }

    .icon {
      color: var(--color-primary);
      border-radius: 100%;
      font-size: 40px;
      display: inline-block;
      margin-bottom: 17px;
      line-height: 6px;
    }
    .inner {
      h4 {
        &.title {
          font-size: 18px;
          font-weight: 700;
        }
      }
      p {
        font-size: 18px;
        margin-bottom: 0;
        @media ${device.lg} {
          font-size: 16px;
        }
        @media ${device.md} {
          font-size: 16px;
        }
        @media ${device.sm} {
          font-size: 16px;
        }
        a {
          color: #acacac;
          transition: 0.3s;
          &:hover {
            color: #4557ce;
          }
        }
      }
    }
    &:hover {
      transform: translateY(-5px);
    }
  }

`;
