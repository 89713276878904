export const hereditaryHeart = {
  pageTitle: "Pathology",
  sliderArea: {
    title: ["Pathology"],
    subtitle: null,
    description: [
      "The field of medicine called pathology offers patients and clinicians diagnostic data. It affects almost every facet of patient care, from cancer diagnosis to the management of chronic diseases through precise laboratory testing.",
      "Pathologists are crucial contributors to a variety of studies, including those that examine the behavior of bacteria and viruses as well as the impact of novel medications in clinical trials."
    ],
    img: "/images/pages/pathology-hero.svg"
  },

  picTextArea: {
    img:"/images/pages/heart-genomics-2.svg",
    imgMobile:"/images/pages/heart-genomics-mobile.svg",
    regularTitle: null,
    largeTitle: null,
    specialTitle: "Managing a heart condition is possible if you know about it.",
    subtitle: "Finding out early if you have or are prone to a chronic or mild heart condition could save your life."
    ,
    p:[null]
  },

  pureArticle: {
    textLight: false,
    decorImg: "/images/decor/decor-drop-orange.svg",
    decoratedTitle: "What is pathology?",
    sideText: null,
    pLeft: ["Pathology is the study or identification of the cause, effect, and amount of disease within a patient. A pathologist studies relevant cells and tissue from the body of a patient, in order to identify disease, determine what causes a disease, and discover to what extent a disease is present in a patient. With a pathology report a physician can determine the best course of treatment for a patient’s Specific disease type and stage. A patient's pathology report impacts nearly all aspects of their care..",
    <p key= "special 1" className="text-block-2 text-bold"><b>How is a pathology exam performed?</b></p>,
    "In order for a pathology report to be generated, a pathologist requires a specimen from a patient to analyze. Examples of a specimen include blood, tissue, or even an organ collected from a patient or a body. There are many ways that a relevant specimen is collected. Urine samples, blood draws, biopsies, and even surgical removal of the required tissue are examples of specimen collection. The pathologist then analyzes the collected tissue and cells with a microscope and compares them to what similar but healthy specimens look like. By comparing the differences between unhealthy and healthy tissue or cells,, a pathologist is able to determine the cause and extent of a disease."],

    pRight: [
      <p key= "special 2" className="text-block-2 text-bold"><b>How does my pathology report influence treatment?</b></p>,
      "The pathology report tells treating physicians the type of disease and the severity of a disease within a patient. Based on this information, a physician will determine the most effective treatment strategy.",
      <p key= "special 3" className="text-block-2 text-bold"><b>How does my pathology report influence treatment?</b></p>,
      "Radiology (imaging) and pathology are different methods used to identify and diagnose disease. Radiology is medical technology that provides images that identify where in a patient’s body a disease may be located but pathology is the actual study of biological tissue that was collected. Radiology can be used to diagnose some diseases, but when possible, it is often followed up by the collection of the suspected diseased tissue via biopsy or surgery so that this tissue can be analyzed by a pathologist. A pathology report will then be able to confirm or deny the differential made by imaging, or a pathology report can determine the diagnosis if one could not be made by images alone."
    ]
  },

  picTextArea: {
    img:"/images/pages/heart-genomics-2.svg",
    imgMobile:"/images/pages/heart-genomics-mobile.svg",
    regularTitle: null,
    largeTitle: null,
    specialTitle: "Lorem ipsum dolor, autem sequi.",
    subtitle: "autem sequi reprehenderit labore consequuntur excepturi voluptatibus omnis similique qui unde."
    ,
    p:[null]
  },

  picTextArea2: {
    img:"/images/pages/choose-test-lab-girl.jpeg",
    imgMobile:"/images/pages/choose-test-lab-girl.jpeg",
    regularTitle: "Lorem ipsum dolor, autem sequi.",
    largeTitle: null,
    specialTitle: null,
    subtitle: null,
    p:[
      "autem sequi reprehenderit labore consequuntur excepturi voluptatibus omnis similique qui unde."
    ]
  },

  orderedList: {
    
    title: ["Benefits of our Hereditary ", "Heart Test include:"],

    list: [
      "World-Class 24/7 Customer Support",
      "Qualifies you for future screening for other conditions",
      "Cost covered by many medical insurance plans",
      "Quick reporting turnaround times",
      "Clear, accurate, easy to understand reports",
      "Professional follow-up support with a genetic counsellor"
    ]
  },

  whoNeedsThis: {
    sectionTitle: "Who needs this test?",
    subH2: "Nearly half of all U.S. adults already have or will develop some type of cardiovascular disease",
    desc:"Getting unique insight into inherited heart conditions can help you and your healthcare provider build a heart health plan that can benefit your future health",
    
    h3: null,
    subH3: [
      "Inherited heart conditions are passed on through families and are caused by a change or mutation in one or more of our genes. Most people who inherit these conditions don’t know they are at risk and don’t suffer symptoms until it’s too late.",
      "Left unchecked and untreated, inherited heart conditions can lead to serious problems such as coronary heart disease, heart attacks, sudden cardiac arrest, or even heart failure."
    ],
    checkLists: [
      {
        intro: "Individuals who qualify for the test include:",
        imgCheck:"/images/decor/single-thick-orange.svg",
        content: [
          {
            checkText:"People with a personal history of cardiac diseases.",
            innerList:[
              "Thickened or thinned heart muscle (Cardiomyopathy)",
              "Irregular heart rate (Arrhythmia)",
              "Affected with aortic aneurysm (Thoracic, Abdominal)",
              "Atherosclerosis",
              "Coronary Artery Disease (CAD)",
              "Born with any Heart Defects (Congenital Heart Defects)",
              "Valvular Heart Disease",
              "Vascular disease (Blood Vessel Disease)"
            ]
          },
          {
            checkText:"People who have experienced the following:",
            innerList:[
              "Sudden or early heart attack",
              "Cardiac Arrest",
              "Heart failure or a transplant",
              "Elevated or high cholesterol levels",
              "Stroke",
              "Unstable Angina",
              "Had a pacemaker installed"
            ]
          }
        ],
      },
    ],
    // interface NumberedList = {listTitle: string, items: [{title: string, desc: string}]}
    numberedList: null
  },
  
  faqArea:{
    questions: [
      "Is iPath Life Sciences’s Genetic Testing Covered by Health Insurance?",
      "What Does iPath Life Sciences Do To Protect My Privacy?",
      "When will I get results?",
      "Can I cancel if I want to?"
    ],
    answers: [
      "In most cases, your health insurance or medicare will cover the cost of testing if genetic testing is recommended to you by your doctor. If you are interested in ordering genetic tests, you should contact your insurance company beforehand and ask about coverage.",
      "iPath Life Sciences guarantees that we will not share your or your family analysis information with ANY third party without your explicit permission. We do not provide information to law enforcement without a valid court-ordered request. We support the Genetic Information Nondiscrimination Act (GINA) and other laws that protect individuals from being discriminated against based on their genetics.",
      "Our goal is to provide you with an accurate genetic test result in a reasonable time period. While most testing periods have a relatively consistent timeframe, a number of factors contribute to estimations for time of delivery, such as the size of the gene and the complexity of the interpretation. Our average turnaround time for genetic testing is usually 3-4 weeks.",
      "If for any reason throughout the process, you decide not to move forward with our tests, iPath Life Sciences will cancel your request immediately."
    ]
  },

  theme:{
    themeColor: "orange",
    pageTitle: "Heart",
    mainColor: "#ff5858",
    btnColor: "#ff5858",
    secColor: " #b70203", 
    thirdColor: "#ffdede",
    forthColor: "#fef3f4",
    fifthColor: "#fad8d8",
    dropColor: "#fad8d8ff"
  }
};
