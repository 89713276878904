import React from 'react';

const Cards = ({ content }) => {
  return (
    <div className="cards-wrapper">
      {content.map((card, i) => {
        return (
          <div key={i} className="card-item">
            <a href={card.linkTo} target="_blank">
              <img
                src={card.cardImg}
                className="card-image"
                alt={card.cardImg.split('/')[card.cardImg.split('/').length - 1]}
              />
              <div className="card-overlay">
              <div className="card-header">
                <svg className="card-arc" xmlns="http://www.w3.org/2000/svg">
                  <path d='M 40 80 c 22 0 40 -22 40 -40 v 40 Z'/>
                </svg>
                <img
                  className="card-thumb"
                  src={card.thumbImg}
                  alt={card.thumbImg.split('/')[card.thumbImg.split('/').length - 1]}
                />
                <div className="card-header-text">
                  <h3 className="card-title"> {card.title} </h3>
                </div>
              </div>
                <p className="card-description text-block-2"> {card.desc} </p>
              </div>
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default Cards;
