import styled from 'styled-components';
import Slider from 'react-slick';
import device from '../../../styles/mediaSizes';

export const StyledSliderArea = styled.div`
  .slider-area {
    background-color: #4557ce0d;
    height: 90vh;
    @media ${device.lg} {
      height: auto;
    }
  }

  .bg_image,
  .bgImagePosition {
    height: 90vh;
    /* background-attachment: fixed !important; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    @media ${device.sm} {
      height: 650px;
    }
  }

  @-webkit-keyframes fadeInUp {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 10%, 0);
      transform: translate3d(0, 10%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 10%, 0);
      transform: translate3d(0, 10%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  .fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
  }

  .slider-image {
    position: relative;
    overflow: hidden;
    height: 90vh;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* border-left: 4px solid #800a889f; */
    @media ${device.lg} {
      height: auto;
    }
  }

  .slider-text-group {
    position: relative;
    left: 100px;
    /* margin: 15%; */
  }

  .slider-dec-img-wrapper {
    position: relative;
    left: -200px;
  }

  .slider-fadeIn {
    overflow: visible;
    position: absolute;
    height: 400px;
    left: 0;
  }

  .slider-title {
    margin-bottom: 25px;
    line-height: 95px;
    font-size: 85px;
    max-width: 450px;
  }

  .slick-current.slick-active .slider-title {
    animation: 2s fadeInUp;
  }

  .slider-subtitle {
    font-family: 'Prompt', sans-serif;
    font-size: 22px;
    color: #5f5a6a;
  }

  .slider-description {
    line-height: 32px;
    font-size: 16px;
    font-weight: 500;
    max-width: 450px;
  }

  .orange-line {
    width: 100%;
    height: 41%;
    background-color: #feb25c;
  }

  .purple-line {
    width: 100%;
    height: 59%;
    background-color: #4557ce;
  }

  .lines-wrapper {
    left: -2px;
    position: absolute;
    width: 8px;
    height: 100%;
    z-index: 10;
  }
`;
