import styled from 'styled-components';
import device from '../../../../styles/mediaSizes';

// 1-SECTION
export const HeroBannerPathology = styled.div`
  & {
    border-bottom: 4px solid #b70203;
  }
`;

// 2-SECTION
export const WhatIsPathology = styled.div``;
