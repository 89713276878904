import * as yup from 'yup';

const phoneRegExp =
  /^$|^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
export const individualFormSchema = yup.object().shape({
  first_name: yup
    .string()
    .required('This field is required')
    .matches(/^[aA-zZ\s]+$/, 'Only letters are allowed for this field')
    .trim(),
  last_name: yup
    .string()
    .required('This field is required')
    .matches(/^[aA-zZ\s]+$/, 'Only letters are allowed for this field')
    .trim(),
  email: yup
    .string()
    .trim()
    .email('Enter a valid email')
    .required('This field is required')
    .matches(/^[^<|>\v]*$/, '"<" or ">" symbols are not allowed'),
  phone: yup
    .string()
    .matches(phoneRegExp, 'Enter a valid phone number')
    .required('This field is required'),
  state: yup.string().required('This field is required'),
  product: yup.string().required('This field is required'),
  trusted_form_cert: yup
    .string()
    .required()
    .oneOf(['accepted'], 'You must accept the terms and conditions'),
  marketing_utm_data: yup.object({
    campaign_ad: yup.string().oneOf(['survey']).required(),
    campaign_ad_set: yup.string().oneOf(['Organic iPath']).required(),
    campaign_method: yup.string().oneOf(['search']).required(),
    campaign_source: yup.string().oneOf(['Organic iPath']).required()
  })
});
