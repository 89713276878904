import React from 'react';
import PageHero from '../../components/PageHero';
import SectionTitle from '../../components/SectionTitle';
import CheckList from '../../components/CheckList';
import NumberedList from '../../components/NumberedList';
import OrderedList from '../../components/OrderedList';
import PureArticle from '../../components/PureArticle';
import TailText from '../../components/TailText';
import * as Styled from './styles/StyledProvPathology';

export const PageHeroProvPathology = ({ theme, content, bgColor }) => {
  return (
    <Styled.PageHeroProvPathology style={{ backgroundColor: bgColor }}>
      <div className="container">
        <PageHero {...content.pageHero} />
      </div>
    </Styled.PageHeroProvPathology>
  );
};

export const OurServices = ({ theme, content, bgColor }) => {
  return (
    <Styled.OurServices style={{ backgroundColor: bgColor }}>
      <div className="rn-section-gap">
        <div className="container">
          <div className="section-main-article">
            <div className="section-col-1">
              <SectionTitle {...content.sectionTitle} />
              <CheckList {...content.checkLists.listOne} />
            </div>
            <div className="section-col-2">
              <NumberedList {...content.numberedList} />
            </div>
          </div>
        </div>
      </div>
    </Styled.OurServices>
  );
};

export const BeginWorkingTogether = ({ theme, content, bgColor }) => {
  return (
    <Styled.BeginWorkingTogether style={{ backgroundColor: bgColor }}>
      <div className="rn-section-gap">
        <div className="container">
          <OrderedList content={content.orderedList} />
        </div>
      </div>
    </Styled.BeginWorkingTogether>
  );
};

export const InOfficePathologyModels = ({ theme, content, bgColor }) => {
  return (
    <Styled.InOfficePathologyModels style={{ backgroundColor: bgColor }}>
      <div className="rn-section-gap">
        <div className="container">
          <div className="section-main-article">
            <div className="section-col1">
              <PureArticle content={content.pureArticle} />
            </div>
            <div className="section-col2">
              <CheckList {...content.checkLists.listOne} />
            </div>
          </div>
          <div className="tail-text-wrapper">
            <h3 className="tail-text-title heading-5">{content.tailText.title}</h3>
            <ol type="A">
              {content.tailText.list.map((text, i) => (
                <li key={i} className="tail-text-list-item text-block-2">
                  {text}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </Styled.InOfficePathologyModels>
  );
};
