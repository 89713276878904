export const hipaaContent = [
  { 
     paragraphs: [
         <>This Notice of Privacy Practices/HIPAA relates to iPath Life Sciences, LLC and applies to its affiliated entities and persons (collectively, the “Company”) except as may relate to any services not amenable to any Department of Health and Human Services (“HHS”) adopted standards or relevant federal or state laws and regulations.</>
      ]
  },
  { 
     title: <>COMPANY’S COMMITMENT TO PROJECT YOUR MEDICAL INFORMATION</>
     , paragraphs: [
         <>Under HIPAA, the Company must maintain the privacy of your confidential and private information. Your private, confidential healthcare information is referred to as “Protected Health Information” (“PHI”), which will include any ePHI. HIPAA requires that the Company notify you of the Company’s PHI privacy practices. The Company is committed to, and follows HIPAA, to protect your PHI and its confidentiality. </>
         ,<>Our HIPAA privacy mandate requires that the Company establish policies and procedures to ensure the Company’s compliance under this (and other federal and state) privacy law. Nonetheless, the Company complies with all legal requirements that allow for, among other things, your access to your information under HIPAA.</> 
      ]
  },
  {
      title:<>COMPANY USE AND DISCLOSURE OF PHI</>
      , paragraphs:[
          <>Pursuant to HIPAA’s standards, please see below the circumstances under which the Company may make use of or disclose your PHI. We note that additional requirements under separate federal or state laws and regulations may limit the Company from the uses and disclosures described.</>
          ,<>IF YOU HAVE ANY QUESTIONS, CONCERN, COMPLAINTS OR REQUESTS, PLEASE SEE THIS PAGE FOR THE RELEVANT COMPANY CONTACT INFORMATION. WE REMAIN COMMITTED TO FOLLOWING HIPAA AND RESPECTING ALL LAWS THAT GOVERN YOUR RIGHTS TO YOUR INFORMATION.</>
          ,<>THE COMPANY MAY USE OR DISCLOSE YOUR PHI UNDER THE FOLLOWING MANDATES, STANDARDS, OR CIRCUMSTANCES</>
      ]
  },
  {
      title:<>A. THE COMPANY MAY, OR MAY BE REQUIRED TO, USE OR DISCLOSE YOUR PHI REGARDING:</>
      ,paragraphs:[]

  },
  {
      title:<>1. YOUR HEALTHCARE TREATMENT</>
      , paragraphs:[
          <>The Company may use or disclose your PHI as it pertains to your treatment — including, disclosure to your treating physician or other relevant healthcare provider. For example, the Company may disclose your PHI to a relevant healthcare professional that provides or coordinates your healthcare services, treatment, products, or prescriptions.</>
      ]
  },
  {
      title:<>2. REIMBURSEMENT/PAYMENT FOR SERVICES</>
      , paragraphs:[
          <>The Company may use or disclose your PHI relevant to the Company’s claims submission and reimbursement for the services the Company provides. In short, the Company must communicate directly or indirectly with payor for the services that we provide.</>
      ]
  },
  {
      title:<>3. THE COMPANY’S HEALTH CARE OPERATIONS</>
      , paragraphs:[
          <>The Company may use or disclose your PHI relevant to the Company’s health care operations. For example, as relevant to necessary evaluations of our testing services, their accuracy, and certain accreditation, management or operations matters. As consistent with all laws, such PHI disclosures may also include for healthcare operations of providers or plans/programs related to your healthcare (e.g., coordination of healthcare/benefits, disease management).</>
      ]
  },
  {
      title:<>4. YOUR APPOINTMENTS AND OTHER REMINDERS</>
      , paragraphs:[
          <>The Company may use or disclose your PHI relevant to your appointments/reminders and other such healthcare relevant benefits or services. For example, the Company may contact you regarding testing services as ordered by your healthcare professional.</>
      ]
  },
  {
      title:<>5. JUDICIAL AND OTHER PROCEEDINGS</>
      , paragraphs:[
          <>The Company may use or disclose your PHI as relevant under specific judicial or other proceedings. For example, in its response to proper demands pursuant to court or other proceeding order, subpoenas, discovery requests or any other proper and legal process.</>
      ]
  },
  {
      title:<>6. A SERIOUS THREAT TO HEALTH OR SAFETY</>
      , paragraphs:[
          <>The Company may use or disclose your PHI in circumstances where the disclosure is necessary to mitigate or prevent imminent and serious threats to the health or safety of a person, the public or in the course of law enforcement’s acts to identify or arrest individuals.</>
      ]
  },
  {
      title:<>7. RESEARCH</>
      , paragraphs:[
          <>The Company may use or disclose your PHI for research purposes. What are known as “Limited Data or Records,” can be seen by researchers in their efforts to identify patients that may be important in research activities, for example, but noting that such researchers may not copy or take possession of any PHI. </>
          ,<>Prior to any such PHI disclosure, the Company expects that one of the following will occur: (1) a “special committee” will be tasked with determining that the relevant research activity will not pose a risk to privacy, including the existence of adequate safeguards in place for any PHI; (2) for PHI of the deceased, assurances are in place that PHI use is limited to the research; or (3) PHI provided shall not directly identify you.</>
      ]
  },
  {
      title:<>8. CERTAIN GOVERNMENT FUNCTIONS</>
      , paragraphs:[
          <>The Company may use or disclose PHI of military personnel/veterans (in certain circumstances) and as may be required by authorized, appropriate government authorities. Similarly, the Company may disclose PHI to authorized, appropriate government officials as may be relevant to national security purposes. Examples include, the protection of the President, in the conduct of intelligence or counter-intelligence gathering or other national security activities. If ever relevant, the Company shall only make such a disclosure as required and in compliance with laws and regulations.</>
      ]
  },
  {
      title:<>9. WORKERS’ COMPENSATION</>
      , paragraphs:[
          <>The Company may use or disclose your PHI as otherwise authorized by laws and regulations, for example, in relation to workers’ compensation programs that regulate work injury or illness benefits.</>
      ]
  },
  {
      title:<>10. PUBLIC HEALTH MATTERS</>
      , paragraphs:[
          <>The Company may use or disclose your PHI relevant to public health. Public health activity disclosures may relate to: (a) a public health authority; for example, relevant to reporting, preventing or controlling injuries, diseases, or disabilities; (b) relevant to reporting births/deaths, child abuse/neglect; (c) the Food and Drug Administration (“FDA”); for example, as relevant to the quality, safety, effectiveness of regulated activities or products — this may include, notifying people of recalled or problems with FDA-regulated products or adverse medication reactions; (d) a person that may have been or is at risk of having been exposed to a disease or condition, and/or may be at risk of transmitting a disease or condition; and, (e) an employer in certain limited circumstances of workplace injury or illness, but all subject to all federal and state laws and regulations.</>
      ]
  },
  {
      title:<>11. ABUSE, NEGLECT OR DOMESTIC VIOLENCE VICTIMS</>
      , paragraphs:[
          <>The Company may be required to use or disclose PHI relevant to abuse, neglect, or domestic violence victims. Pursuant to applicable law and regulation, the Company may disclose PHI to a government agency, authority or function, for example, social services, if the Company reasonably believes that a person is an abuse, neglect, or domestic violence victim.</>
      ]
  },
  {
      title:<>12. REGARDING HEALTHCARE SERVICES OVERSIGHT</>
      , paragraphs:[
          <>The Company may be required to use or disclose PHI to healthcare services oversight activities; for example, for a government agency’s oversight authorized by law which may include civil, criminal or administrative investigations, proceedings, prosecutions or action, including disciplinary, licensure, audits, inspections, audits, licensure/disciplinary actions, or other activities necessary for appropriate oversight of the health care system, government benefit programs, and compliance with regulatory requirements and civil rights laws.</>
      ]
  },
  {
      title:<>B. THE COMPANY MAY, OR MAY BE REQUIRED TO, USE OR DISCLOSE YOUR PHI TO:</>
      , paragraphs:[
          <></>
      ]
  },
  {
      title:<>1. THOSE INVOLVED IN YOUR HEALTHCARE OR ITS PAYMENT</>
      , paragraphs:[
          <>The Company may use or disclose your PHI relevant to such persons that are part of your receipt of healthcare or that pay for your healthcare; for example, a family member. Also, the Company may use or disclose your PHI in certain circumstances; for example, relevant to disaster relief efforts. Further, the Company may use or disclose PHI of minors or incapacitated persons to their parents or legal guardian — subject to all federal and state law standards and requirements.</>
      ]
  },
  {
      title:<>2. BUSINESS ASSOCIATES (AS DEFINED BY LAW)</>
      , paragraphs:[
          <>The Company may use or disclose your PHI to the Company’s Business Associates as required to perform its operations, for example, when such operations are supported by the Business Associate’s services to the Company. </>
          ,<>The Company requires that its Business Associates secure the confidentiality and privacy of your PHI in the same way as the Company. Similarly, if properly requested by a healthcare provider or health plan/program, the Company may disclose PHI to its Business Associates relevant to the performance of health care services – e.g., Medicare business associate needs relevant to medical necessity audits.</>
      ]
  },
  {
      title:<>3. LAW AND LAW ENFORCEMENT</>
      , paragraphs:[
          <>The Company may be required to use or disclose your PHI as otherwise required by federal, state, or local law. Further, the Company may use or disclose your PHI for law enforcement purposes. For example, in response to court orders, warrants, subpoenas/summons, or other proper authorized by law. Similarly, the Company may use or disclose your PHI when it is required as authorized by law, relevant to: (a) the location/identification of suspects, fugitives, material witnesses or missing persons; (b) crime victims; (c) a deceased person; (d) criminal conduct at a Company facility; or (e) emergencies involving a crime, including reporting and location of same or its victims, or the location, identity, description of the perpetrator.</>
      ]
  },
  {
      title:<>4. CORONERS, MEDICAL EXAMINERS, AND FUNERAL DIRECTORS</>
      , paragraphs:[
          <>The Company may be required to use or disclose your PHI as otherwise required by federal, state, or local law germane to a medical examiner/coroner or funeral director. For example, when such disclosure is required for the identification of a decedent, to determine cause of death, or relevant to performing such other duties authorized by laws and regulations.</>
      ]
  },
  {
      title:<>5. PERSONAL REPRESENTATIVE</>
      , paragraphs:[
          <>The Company may be required to use or disclose your PHI as otherwise required, established or allowed by federal, state, or local law to a personal representative, an administrator, executor, or other authorized individual that may be associated with you and your estate.</>
      ]
  },
  {
      title:<>6. A CORRECTIONAL INSTITUTION</>
      , paragraphs:[
          <>The Company may be required to use or disclose the PHI as otherwise required, established or allowed by federal, state, or local law of an inmate or other individual, as may be requested by law enforcement or correctional institution officials and as relevant to health, safety, and security purposes.</>
      ]
  },
  {
      title:<>C. THE COMPANY MAY, OR MAY BE REQUIRED TO, USE OR DISCLOSE YOUR PHI THAT CONSTITUTES DE-IDENTIFIED INFORMATION AND LIMITED DATA SETS</>
      , paragraphs:[
          <>The Company may use/disclose “de-identified” healthcare information. Information that has been “de-identified” constitutes information that has been edited in such a manner that certain identifiers that may have identified you; that is, in a manner that makes it unlikely that the person could be identified from the information. </>
          ,<>Similarly, the Company may disclose “limited health information” which may be contained in a “limited data set.” A “limited data set” contains no information that may directly identify you, such as, your state, city, county or zip code, but certainly not your name, age, phone number or address.</>
      ]
  },
  {
      title:<>OTHER USES AND DISCLOSURES OF PHI</>
      , paragraphs:[
          <>The Company explicitly states that pursuant to any purpose not set out above, the Company will not use or transfer PHI without your explicit authorization; for example, use or disclosure of your PHI by the Company for marketing purposes. In such an example, the Company will not “sell” your PHI; any use or disclosure not allowed for under the law requires your authorization. We note that if you ever have given your authorization for such a use, you retain sole and absolute discretion to revoke such authorization, except as may be relevant to a prior the Company use or disclosure in reliance on your prior consent.</>
      ]
  },
  {
      title:<>INFORMATION BREACH NOTIFICATION</>
      , paragraphs:[
          <>If the Company ever discovers that a breach of unsecured PHI has occurred, the Company is required to notify you of same except in the case that the circumstances of same demonstrates (for example, based on a risk assessment) that the breach presents with a low probability that such PHI has been actually compromised. Nonetheless, a notification will be made without delay, but not later than sixty days subsequent to the breach discovery. The notification includes the facts relevant to the breach and information on mitigating harm.</>
      ]
  },
  {
      title:<>YOUR RIGHTS REGARDING PHI</>
      , paragraphs:[
          <>The HIPAA establishes the following in regard to your PHI rights:</>
      ]
  },
  {
      title:<>A. YOUR RIGHT TO RECEIVE A COPY OF THE COMPANY'S PRIVACY PRACTICES NOTICE</>
      , paragraphs:[
          <>You have a right to receive the Company’s Privacy Practices Notice. You may request the Notice at any time and through the Company’s Privacy Officer at the Company – Attn. Privacy Officer, +1 (863) 606-6655/compliance@ipathlifesciences.com. The Company’s notice is nonetheless posted on its website at www.ipathlifesciences.com.</>
      ]
  },
  {
      title:<>B. YOUR RIGHT TO REQUEST THAT THE COMPANY LIMIT THE USE AND DISCLOSURE OF YOUR PHI</>
      , paragraphs:[
          <>Under the law, you can request that the Company limit: (1) how the Company uses or discloses PHI relevant to any health care operations activity, including regarding treatment or payment; or 2) whether the Company disclosed your PHI to persons involved in your healthcare or for its payment. </>
          ,<>We note that pursuant to your request, the Company will follow the law and consider your request, but you must understand that the Company may in some cases not be required to agree to your request if it is not allowed under the law in the case your request involves disclosure to a health payment program regarding payment or health care operations (and not for treatment) and you have paid for the Company’s services in full and out of pocket. In cases where the Company has agreed to your request, the Company will memorialize agreed-upon restrictions in writing and will comply with our agreement – we further note, that the Company may not so abide under emergency circumstances when such a disclosure is for treatment purposes.</>
      ]
  },
  {
      title:<>C. YOUR RIGHT TO REQUEST CONFIDENTIAL COMMUNICATIONS</>
      , paragraphs:[
          <>You retain the right to request that the Company communicate with you relevant to PHI, for example, via a different address or by a certain communication means. The Company will accommodate any reasonable request to change the mode or address through which we communicate with you.</>
      ]
  },
  {
      title:<>D. YOUR RIGHT TO INSPECT AND RECEIVE A COPY OF YOUR PHI</>
      , paragraphs:[
          <>You or your personal representative have a right to access your Company relevant PHI, for example, your reports ordered by your physician. If you so request, and unless an exception applies, your will receive (within 30 days after the Company’s receipt of your request) a copy of your Company held documentation. An exception may apply when a licensed health care professional determines that such access requested may endanger your or another person life or safety of you or another person. In such a case, the Company may extend our response time for an additional 30 days (but you can expect to be provided in writing, the reason(s) for the delay, and when you will have access). </>
          ,<>You retain the right to your PHI in electronic format if same is reasonably reproducible in such a format. Nonetheless, you retain the right to instruct the Company to send your data to such other person as you may designate in a writing that has your signature and through which you explicitly designate such person. PHI requests may be made: (1) when you visit a the Company relevant facility; (2) by completing the Company’s request form; (3) by contacting the Company’s Privacy Officer, +1 (863) 606-6655/compliance@ipathlifesciences.com.</>
      ]
  },
  {
      title:<>E. YOUR RIGHT TO RECEIVE A DISCLOSURE ACCOUNTING</>
      , paragraphs:[
          <>You have the right to receive and accounting of the instances that the Company has disclosed your PHI. Please note that such an accounting may not include all PHI disclosures, for example, for those disclosures made in writing by you or regarding disclosures prior a date on which the Company was required to comply. </>
          ,<>Your accounting request for PHI disclosures made for purposes other than treatment, payment, or health care operations, will include such disclosures made in the past six years; except in the case your request is for a shorter period. An accounting request for PHI disclosures made for purposes of treatment, payment, or health care operations, will include disclosures made in the past three years; except in the case your request is for a shorter period.</>
      ]
  },
  {
      title:<>F. YOUR RIGHT TO CORRECT OR UPDATE</>
      , paragraphs:[
          <>At any time that you conclude that your PHI includes a mistake, you retain the right to request that the Company correct such mistake. If the Company denies your request to correct or update, the Company will provide to you an explanation in writing setting out the reason(s) for the Company’s decision to deny your request to correct or update.</>
      ]
  },
  {
      title:<u className='fw-600 toUpperCase'>INFORMATION ON HOW TO ACCESS YOUR RIGHTS UNDER THIS NOTICE</u>
      , paragraphs:[
          <>To access your rights under HIPAA/this Notice, send your request in writing to:</>
          ,<>The Company – ATTN. Privacy Officer, at privacy@ipathlifesciences.com.</>
          ,<>Nonetheless, if you wish to contact the Company regarding any billing, claims, or other insurance matter, please contact us at the number or department contact information set out in the documentation you have received from or relevant to the Company.</>
      ]
  },
  {
      title:<u className='fw-600 toUppercase'>HOW TO FILE A COMPLAINT – CONTACTING THE COMPANY</u>
      , paragraphs:[
          <>If you want to file a complaint, or have any questions, either as relevant to the Company’s processing, use or disclosure of your medical information, the preceding information, or our privacy practices, you are encouraged to reach out to compliance@thecompany.com or reach out to us at +1 (863) 606-6655. Separately and additionally, you may send to us any written request, complaint or question at:</>
          ,<>The Company – ATTN. Privacy Officer, at privacy@ipathlifesciences.com.</>
          ,<>Nonetheless, you retain your right to file any complaint with the U.S. Department of Health and Human Services. The Company has a zero-tolerance policy against retaliation for anyone, including you, filing a complaint regarding medical information privacy matters or practices or any other matter.</>
      ]
  },
  {
      title:<u className='fw-600 toUppercase'>CHANGES TO THIS PRIVACY PRACTICES NOTICE</u>
      , paragraphs:[
          <>The Company may change this notice or its privacy policies in the future. If so, such changes may relate to your medical information/PHI that the Company may maintain. At all times, the Company shall act consistent with our notice to you and our privacy policies in effect. If changes to our notice(s) to you or our privacy policies occur, the Company will update this Notice on this Website. For your most current information, please periodically review this Website for any relevant changes.</>
      ]
  },
];