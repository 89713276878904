import styled from 'styled-components';
import device from '../../../styles/mediaSizes';

export const StyledFormArea = styled.div`
  .contact-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    input,
    select,
    textarea {
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      height: auto;
      padding: 0 20px;
      outline: none;
      border: 2px solid  hsla(0, 0%, 100%, 0.1);
      border-radius: 6px;
      transition: 200ms;
  
      &:focus {
        border-color: #800a8899 !important;
        box-shadow: 0 0 0.3rem #800a88;
        background-image: linear-gradient(#ffedfc, #fffaff 6%, #fff 50%, #fffaff 78%, #ffedfc);
      }
  
      &::placeholder {
        color: #5f5a6a;
        opacity: 1;
      }
    }
  
    input,
    select {
      height: 70px;
    }
    textarea {
      min-height: 193px;
      resize: none;
      padding-top: 20px;
    }
  
    .form-group {
      flex-basis: 49%;
      margin-bottom: 10px;
    }
    .subject,
    .message {
      flex-basis: 100%;
    }
  
    label {
      font-family: 'Inter', sans-serif;
      color: #342a47;
      display: block;
      margin-bottom: 6px;
      margin-left: 3px;
      /* font-size: 18px; */
      letter-spacing: 1px;
      font-weight: 500;
    }
  
    select:invalid {
      color: #5f5a6a;
    }
  
    .error-msg {
      color: #cc0000;
      padding-left: 18px;
      margin-top: 8px;
    }
  
    .error-msg svg {
      margin-right: 5px;
      margin-bottom: 3px;
    }
  
    .spinner {
      display: none;
      opacity: 0;
      margin-left: 9px;
    }
  
    .btn-loading.spinner {
      display: inline-block;
      opacity: 1;
      transition: 0.2ms;
      animation: btn-spinner-anime 1s ease infinite;
    }
  }

  @media ${device.sm} {
    .contact-form {
      justify-content: center;
      .form-group {
        flex-basis: 95%;
      }
    }
  }

  // LOAD ANIMATION
  @keyframes btn-spinner-anime {
    from {
      transform: rotate(0turn);
    }

    to {
      transform: rotate(1turn);
    }
  }

  // GOOGLE MAPS
  .google-map-style-1 {
    padding-top: 36.6px;
    width: 100%;
    height: 605px;
    border-radius: 10px;
    div {
      border-radius: 10px;
    }
  }
`;

