import * as yup from 'yup';

const phoneRegExp =
  /^$|^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
export const providerFormSchema = yup.object().shape({
  first_name: yup
    .string()
    .required('This field is required')
    .matches(/^[aA-zZ\s]+$/, 'Only letters are allowed for this field')
    .trim(),
  last_name: yup
    .string()
    .required('This field is required')
    .matches(/^[aA-zZ\s]+$/, 'Only letters are allowed for this field')
    .trim(),
  email: yup
    .string()
    .trim()
    .email('Enter a valid email')
    .required('This field is required')
    .matches(/^[^<|>\v]*$/, '"<" or ">" symbols are not allowed'),
  phone: yup
    .string()
    .matches(phoneRegExp, 'Enter a valid phone number')
    .required('This field is required'),
  fax: yup.string().matches(phoneRegExp, 'Enter a valid phone number'),
  expertise: yup
    .string()
    .required('This field is required')
    .matches(/^[aA-zZ\s]+$/, 'Only letters are allowed for this field')
    .trim(),
  state: yup.string().required('This field is required'),
  npi: yup
    .string()
    .trim()
    .required('This field is required')
    .matches(/^[^<|>\v]*$/, '"<" or ">" symbols are not allowed'),
  accept_terms: yup
    .string()
    .required()
    .oneOf(['accepted'], 'You must accept the terms and conditions'),
});
