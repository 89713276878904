import React from 'react';

const Video = () => {
  return (
    <div
      className= "video-wrapper"
      style={{
        background: 'linear-gradient(to bottom, white 0%, white 50%,#7c30a7 50%,#7c30a7 100%)',
      }}
    >
      <video
        controls
        poster="/images/video/iPath_video_poster.png"
      >
        <source src="/images/video/iPath Life Sciences.mp4" type="video/mp4" />
        Sorry, your browser doesn't support embedded videos, but don't worry, you can
        <a href="/images/video/iPath Life Sciences.mp4">download it</a>
        and watch it with your favorite video player!
      </video>
    </div>
  );
};

export default Video;
