import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import PageScrollTop from './utils/PageScrollTop';

// Pages import Here
import IPathHome from './pages/homepage/iPathMain';
import HereditaryCancer from './pages/service_pages/HereditaryCancer';
import HereditaryHeart from './pages/service_pages/HereditaryHeart';
import Pharmacogenomics from './pages/service_pages/Pharmacogenomics';
import Pathology from './pages/service_pages/Pathology';
import ProvPathology from './pages/service_pages/ProvPathology';
import ProvPharmacogenomics from './pages/service_pages/ProvPharmacogenomics';
import ProvHereditaryCancer from './pages/service_pages/ProvHereditaryCancer';
import Contact from './pages/contact/Contact';
import Hipaa from './pages/policies/Hipaa';
import Compliance from './pages/policies/Compliance';
import TPPolicy from './pages/policies/Terms_and_PPolicy';
import AboutUs from './pages/about_us/AboutUs';
import ChooseMethod from './pages/order_kits/ChooseMethod';
import IndividualTest from './pages/order_kits/IndividualTest';
import ProviderTest from './pages/order_kits/ProviderTest';

// 404
import NotFound from './pages/NotFound';
import UnderConstruction from './pages/UnderConstruction';

// Import Css Here
import './assets/scss/style.scss';
import GlobalStyles from './pages/styles/Global';
import AnimationStyles from './pages/styles/Animations';
import NavPageLinks from './pages/styles/NavPageLinks';

import TagList from './pages/blog/TagList';
import CategoryList from './pages/blog/CategoryList';
// import Author from "./pages/Author";

import BlogDetails from "./pages/blog/BlogDetails";
// Global Variables
const envUrl = process.env.PUBLIC_URL;



const App = () => {
  return (
    <Router basename={'/'} >
      {/* <PageScrollTop> */}
        <GlobalStyles />
        <AnimationStyles />
        <NavPageLinks />
        <Switch>
          {/* homepage */}
          <Route path="/" exact ><IPathHome/></ Route>
          {/* about us */}
          <Route path="/about-us" exact ><AboutUs/></ Route>
          {/* services pages */}
          <Route path="/genomics/pathology" exact ><Pathology/></ Route>
          <Route path="/genomics/pharmacogenomics" exact ><Pharmacogenomics/></ Route>
          <Route path="/genomics/cancer" exact ><HereditaryCancer/></ Route>
          <Route path="/genomics/heart" exact ><HereditaryHeart/></ Route>
          <Route path="/genomics/providers/pathology" exact ><ProvPathology/></ Route>
          <Route path="/genomics/providers/pharmacogenomics" exact ><ProvPharmacogenomics/></ Route>
          <Route path="/genomics/providers/cancer" exact ><ProvHereditaryCancer/></ Route>
          {/* Contact Page */}
          <Route path="/contact" exact ><Contact/></ Route>
          {/* Policies */}
          <Route path="/hipaa" exact ><Hipaa/></ Route>
          <Route path="/compliance" exact ><Compliance/></ Route>
          <Route path="/terms-and-privacy-policy" exact ><TPPolicy/></ Route>
          {/* Choose Method for Test */}
          <Route path="/order-test" exact ><ChooseMethod/></ Route>
          <Route path="/order-test/individuals" exact ><IndividualTest/></ Route>
          <Route path="/order-test/providers" exact ><ProviderTest/></ Route>
          {/* Latest News/Blog */}
          <Route path="/blog-details/:id" exact ><BlogDetails/></ Route>
          <Route path="/category/:slug" exact ><CategoryList/></ Route>
          <Route path="/tag/:slug" exact ><TagList/></ Route>

          {/* Under Construction */}
          <Route path="/under-construction" exact ><UnderConstruction/></ Route>

          {/* 404 Error */}
          <Route ><NotFound/></ Route>
        </Switch>
      {/* </PageScrollTop> */}
    </Router>
  );
};

export default App;
          
// <Route path="/genomics/heart" exact ><HereditaryHeart/></ Route>

// Latest News and Blog views
// import TagList from './pages/TagList';
// import CategoryList from './pages/CategoryList';
// import BlogDetails from './pages/BlogDetails';
// {/* Latest News/Blog */}
// <Route path="/blog-details/:id" exact ><BlogDetails/></ Route>
// <Route path="/category/:slug" exact ><CategoryList/></ Route>
// <Route path="/tag/:slug" exact ><TagList/></ Route>

// import TagList from "./pages/TagList";
// import Author from "./pages/Author";
// import AboutUs from "./pages/AboutUs";
// import CategoryList from "./pages/CategoryList";
// import BlogGridView from "./components/blog/BlogGridView";
// import BlogListView from "./components/blog/BlogListView";
// import BlogGridSidebar from "./components/blog/BlogGridSidebar";
// import BlogListSidebar from "./components/blog/BlogListSidebar";
// import BlogDetails from "./pages/BlogDetails";
// import Error from "./pages/Error";

// <Route path={`${envUrl}/`} component={Splash} exact/>
// {/* Blog Part  */}
// <Route path={`${envUrl}/blog-grid`} exact component={BlogGridView} />
// <Route path={`${envUrl}/blog-list-view`} exact component={BlogListView} />
// <Route path={`${envUrl}/blog-grid-sidebar`} exact component={BlogGridSidebar} />
// <Route path={`${envUrl}/blog-list-sidebar`} exact component={BlogListSidebar} />
// <Route path={`${envUrl}/blog-details/:id`} exact component={BlogDetails} />
// <Route path={`${envUrl}/category/:slug`} exact component={CategoryList} />
// <Route path={`${envUrl}/tag/:slug`} exact component={TagList} />
// <Route path={`${envUrl}/archive/:slug`} exact component={Author}/>
// <Route path={`${envUrl}/about-us`} exact component={AboutUs} />
// <Route path={`${envUrl}/error`} exact component={Error} />


