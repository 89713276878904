export const BannerActivation = {
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 1000,
  autoplaySpeed: 5000,
  dots: true,
  arrows: false,
};
