import React, { useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RiErrorWarningLine } from 'react-icons/ri';
import ThankYouPage from './ThankYouPage';
import ApiError from './ApiError';
import { FaSpinner } from 'react-icons/fa';
import { individualFormSchema } from '../../../validations/IndividualValidation';
import { content } from '../content/individualFormControl';
import { axiosEndPointCall } from '../../../utils/axiosEndPointCall';

function IndividualForm() {
  const [submitted, setSubmitted] = useState({status: false, msg: null});
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [apiError, setApiError] = useState({ status: false, error: null });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onBlur', resolver: yupResolver(individualFormSchema) });

  const onSubmit = async values => {

    setLoadSpinner(true);

    const serverResponse = await axiosEndPointCall({
      url: 'https://www.ipathlifesciences.com/api/email/order-test/individual',
      data: values,
    });

    if (serverResponse === 'error') {
      return setApiError({
        status: true,
        error: 'Please, try back later or contact us at (863) 606-6655',
      });
    }

    reset();

    setSubmitted({status: true, msg: 'Our patient service specialist team will be reaching out to you as soon as possible. If you have any questions please call us at +1 (863) 606-6655'});

    return;
  };

  if (submitted.status) return <ThankYouPage msg={submitted.msg}/>;
  if (apiError.status) return <ApiError error={apiError.error} />;

  return (
    <>
      <form action="" onSubmit={handleSubmit(onSubmit)}>
        <h1 className="heading-1">Individual</h1>
        <p className="text-block-2">Fill in your details below to Order Tests for yourself.</p>
        {content.formStructure.map((field, index) => {
          switch (field.el) {
            case 'input':
              return (
                <div
                  key={index}
                  className={`${
                    field.attributes.className ? field.attributes.className : field.attributes.name
                  } form-group `}
                >
                  {field.attributes.name ? (
                    <label name={field.attributes.name} id={field.attributes.name}>
                      {field.label}
                    </label>
                  ) : null}
                  <input {...register(field.attributes.name)} {...field.attributes} />
                  <div className="error-msg">
                    {errors[field.attributes.name] ? <RiErrorWarningLine /> : null}
                    {errors[field.attributes.name]?.message}
                  </div>
                </div>
              );
            case 'select':
              return (
                <div key={index} className={`${field.attributes.name} form-group `}>
                  <label name={field.attributes.name} id={field.attributes.name}>
                    {field.label}
                  </label>
                  <select
                    defaultValue={''}
                    {...register(field.attributes.name)}
                    {...field.attributes}
                    className="custom-select"
                  >
                    {field.options.map(option => {
                      return (
                        <option key={option.value} value={option.value} disabled={option.disabled}>
                          {option.content}
                        </option>
                      );
                    })}
                  </select>
                  <div className="error-msg">
                    {errors[field.attributes.name] ? <RiErrorWarningLine /> : null}
                    {errors[field.attributes.name]?.message}
                  </div>
                </div>
              );
            case 'checkbox':
              return (
                <div key={index} className={`${field.attributes.name} form-group `}>
                  <input {...register(field.attributes.name)} {...field.attributes} />
                  <label
                    className="text-block-2"
                    name={field.attributes.name}
                    htmlFor={field.attributes.name}
                  >
                    {field.label}
                  </label>
                </div>
              );
            default:
              return null;
          }
        })}

        <div className="form-group">
          <button className="btn-default">
            Submit Form
            <span className={loadSpinner ? 'btn-loading spinner' : 'spinner'}>
              <FaSpinner />
            </span>
          </button>
        </div>

        <div className="form-group"></div>
      </form>
    </>
  );
}

export default IndividualForm;
