import React from 'react';
import { StyledOurCompany } from './styles/StyledOurCompany';

const OurCompany = ({ bgColor, content }) => {

  return (
    <StyledOurCompany className="container" style={{position: "relative"}}>
      <div id={content.id} style={{position: "absolute"}} ></div>
      <h1 className="heading-1">{content.title}</h1>
      <div className="row justify-content-between" style={{ backgroundColor: bgColor }}>
        <div className="about-us-company-title col-md-6 col-sm-12">
          <h3 className="heading-4 col-md-6">{content.subtitle}</h3>
        </div>
        <div className="col-md-6 col-sm-12">
          {content.desc.map((p, i) => {
            return (
              <p key={i} className="text-block-2">{p}</p>
            );
          })}
          <h4>{content.ourMission.title}</h4>
          {content.ourMission.desc.map((p, i) => {
            return (
              <p key={i} className="text-block-2">{p}</p>
            );
          })}
        </div>
      </div>
    </StyledOurCompany>
  );
};

export default OurCompany;
