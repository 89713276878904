import React from "react";
import { Link } from 'react-router-dom';

const phoneNumberFormatter = e => {
  const x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
};

export const content = {
  formStructure: [
    // INPUTS
    {
      el: 'input',
      label: 'First Name',
      attributes: {
        type: 'text',
        name: 'first_name',
        id: 'first_name',
        placeholder: 'John',
        required: true,
      },
    },
    {
      el: 'input',
      label: 'Last Name',
      attributes: {
        type: 'text',
        name: 'last_name',
        id: 'last_name',
        placeholder: 'Doe',
        required: true,
      },
    },
    {
      el: 'input',
      label: 'E-mail Address',
      attributes: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'john@example.com',
        required: true,
      },
    },
    {
      el: 'input',
      label: 'Phone Number:',
      attributes: {
        type: 'text',
        name: 'phone',
        id: 'phone',
        placeholder: '(xxx) xxx-xxxx',
        onChange: phoneNumberFormatter,
        required: false,
      },
    },
    //SELECTS
    {
      el: 'select',
      label: 'Select Your State',
      attributes: {
        name: 'state',
        id: 'state',
        required: true,
      },
      options: [
        {
          value: '',
          content: 'Select your residential state',
          selected: true,
          disabled: true,
        },
        { value: 'AL', content: 'Alabama' },
        { value: 'AK', content: 'Alaska' },
        { value: 'AZ', content: 'Arizona' },
        { value: 'AR', content: 'Arkansas' },
        { value: 'CA', content: 'California' },
        { value: 'CO', content: 'Colorado' },
        { value: 'CT', content: 'Connecticut' },
        { value: 'DE', content: 'Delaware' },
        { value: 'DC', content: 'District Of Columbia' },
        { value: 'FL', content: 'Florida' },
        { value: 'GA', content: 'Georgia' },
        { value: 'HI', content: 'Hawaii' },
        { value: 'ID', content: 'Idaho' },
        { value: 'IL', content: 'Illinois' },
        { value: 'IN', content: 'Indiana' },
        { value: 'IA', content: 'Iowa' },
        { value: 'KS', content: 'Kansas' },
        { value: 'KY', content: 'Kentucky' },
        { value: 'LA', content: 'Louisiana' },
        { value: 'ME', content: 'Maine' },
        { value: 'MD', content: 'Maryland' },
        { value: 'MA', content: 'Massachusetts' },
        { value: 'MI', content: 'Michigan' },
        { value: 'MN', content: 'Minnesota' },
        { value: 'MS', content: 'Mississippi' },
        { value: 'MO', content: 'Missouri' },
        { value: 'MT', content: 'Montana' },
        { value: 'NE', content: 'Nebraska' },
        { value: 'NV', content: 'Nevada' },
        { value: 'NH', content: 'New Hampshire' },
        { value: 'NJ', content: 'New Jersey' },
        { value: 'NM', content: 'New Mexico' },
        { value: 'NY', content: 'New York' },
        { value: 'NC', content: 'North Carolina' },
        { value: 'ND', content: 'North Dakota' },
        { value: 'OH', content: 'Ohio' },
        { value: 'OK', content: 'Oklahoma' },
        { value: 'OR', content: 'Oregon' },
        { value: 'PA', content: 'Pennsylvania' },
        { value: 'RI', content: 'Rhode Island' },
        { value: 'SC', content: 'South Carolina' },
        { value: 'SD', content: 'South Dakota' },
        { value: 'TN', content: 'Tennessee' },
        { value: 'TX', content: 'Texas' },
        { value: 'UT', content: 'Utah' },
        { value: 'VT', content: 'Vermont' },
        { value: 'VA', content: 'Virginia' },
        { value: 'WA', content: 'Washington' },
        { value: 'WV', content: 'West Virginia' },
        { value: 'WI', content: 'Wisconsin' },
        { value: 'WY', content: 'Wyoming' },
      ],
    },
    {
      el: 'select',
      label: 'Test type',
      attributes: {
        name: 'product',
        id: 'product',
        required: true,
      },
      options: [
        {
          value: '',
          content: 'Select test type',
          selected: true,
          disabled: true,
        },

        {
          value: 'PGX',
          content: 'Pharmacogenomics',
        },
        {
          value: 'CGX',
          content: 'Hereditary Cancer Testing',
        },
      ],
    },
    //CHECKBOX
    {
      el: 'checkbox',
      label: <>By Inputting your information, you confirm that you have read our <Link target="_blank" to='/Terms-and-Privacy-Policy'>Terms and conditions</Link> and you fully agree with our <Link target="_blank" to='/Terms-and-Privacy-Policy'>Privacy policy.</Link></>,
      attributes: {
        type: 'checkbox',
        name: 'trusted_form_cert',
        id: 'trusted_form_cert',
        value: 'accepted',
        placeholder: null,
        required: true,
      },
    },
    // MARKETING DATA HIDDEN
    {
      el: 'input',
      label: null,
      attributes: {
        type: 'hidden',
        name: 'marketing_utm_data[campaign_ad]',
        className: 'marketing_hidden',
        value: 'survey',
        id: null,
      },
    },
    {
      el: 'input',
      label: null,
      attributes: {
        type: 'hidden',
        name: 'marketing_utm_data[campaign_ad_set]',
        className: 'marketing_hidden',
        value: 'Organic iPath',
        id: null,
      },
    },
    {
      el: 'input',
      label: null,
      attributes: {
        type: 'hidden',
        name: 'marketing_utm_data[campaign_method]',
        className: 'marketing_hidden',
        value: 'search',
        id: null,
      },
    },
    {
      el: 'input',
      label: null,
      attributes: {
        type: 'hidden',
        name: 'marketing_utm_data[campaign_source]',
        className: 'marketing_hidden',
        value: 'Organic iPath',
        id: null,
      },
    },
  ],
};
