import {contactInfo} from '../../pages/GLOBAL_INFO'

export const layoutContent = {
  header: {
    logo: '',
    nav: [
      {
        type: 'individuals',
        linkName: 'About Us',
        linkTo: '/about-us#top',
        submenu: {
          title: 'About Us',
          desc: 'It is our mission to ensure accurate, timely, comprehensive and validated analytical results to patients and healthcare providers across the globe.',
          cta: {
            href: contactInfo.MAIN_PHONE_LINK,
            phone: contactInfo.MAIN_PHONE,
            slogan: 'We can assist you:',
          },
          links: [
            {
              type: 'internal_link',
              linkName: 'Our Mission',
              linkTo: '/about-us#top',
            },
            {
              type: 'internal_link',
              linkName: 'Meet the Team',
              linkTo: '/about-us#meet-the-team',
            },
          ],
        },
      },
      {
        type: 'providers',
        linkName: 'For Providers',
        linkTo: '/genomics/providers/pathology',
        submenu: {
          title: 'Providers',
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          cta: {
            href: contactInfo.MAIN_PHONE_LINK,
            phone: contactInfo.MAIN_PHONE,
            slogan: 'We can assist you:',
          },
          links: [
            {
              type: 'internal_link',
              linkName: 'Pathology',
              linkTo: '/genomics/providers/pathology',
            },
            {
              type: 'external_link',
              linkName: 'iPath Life Sciences Insurance List',
              linkTo: `${process.env.PUBLIC_URL}/files/insurancelist preview.pdf`,
            },
            {
              type: 'internal_link',
              linkName: 'Online Reporting Portal',
              linkTo: '/under-construction',
            },
            {
              type: 'internal_link',
              linkName: 'In-Office Model',
              linkTo: '/under-construction'
            }
          ],
        },
      },
      {
        type: 'individuals',
        linkName: 'For Patients',
        linkTo: '/genomics/cancer',
        submenu: {
          title: 'Patients',
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          cta: {
            href: contactInfo.MAIN_PHONE_LINK,
            phone: contactInfo.MAIN_PHONE,
            slogan: 'We can assist you:',
          },
          links: [
            {
              type: 'internal_link',
              linkName: 'Pathology',
              linkTo: '/genomics/pathology',
            },
            {
              type: 'internal_link',
              linkName: 'Pharmacogenomics',
              linkTo: '/genomics/pharmacogenomics',
            },
            {
              type: 'internal_link',
              linkName: 'Hereditary Cancer Testing',
              linkTo: '/genomics/cancer',
            },
            {
              type: 'btn',
              linkName: 'Request a Test Kit',
              linkTo: '/order-test/individuals',
            }
          ],
        },
      },
      {
        type: 'individuals',
        linkName: 'Contact Us',
        linkTo: '/contact#top',
        submenu: {
          title: 'Contact Us',
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          cta: {
            href: contactInfo.MAIN_PHONE_LINK,
            phone: contactInfo.MAIN_PHONE,
            slogan: 'We can assist you:',
          },
          links: [
            {
              type: 'internal_link',
              linkName: 'Contact Us Today!',
              linkTo: '/contact#top',
            },
            {
              type: 'internal_link',
              linkName: 'Write Us a Message',
              linkTo: '/contact#write-us-a-message',
            },
          ],
        },
      },
    ],
  },

  footer: {
    items: [
      {
        title: 'About Us',
        links: [
          {
            type: 'internal_link',
            linkTo: '/about-us#top',
            linkName: 'Our Mission',
          },
          {
            type: 'internal_link',
            linkTo: '/about-us#meet-the-team',
            linkName: 'Meet The Team',
          },
        ],
      },
      {
        title: 'For Providers',
        links: [
          {
            type: 'internal_link',
            linkName: 'Pathology',
            linkTo: '/genomics/providers/pathology',
          },
          {
            type: 'external_link',
            linkName: 'iPath Life Sciences Insurance List',
            linkTo: `${process.env.PUBLIC_URL}/files/insurancelist preview.pdf`,
          },
          {
            type: 'internal_link',
            linkName: 'Online Reporting Portal',
            linkTo: '/under-construction',
          },
          {
            type: 'internal_link',
            linkName: 'In-Office Model',
            linkTo: '/under-construction'
          }
        ],
      },
      {
        title: 'For Patients',
        links: [
          {
            type: 'internal_link',
            linkTo: '/genomics/pathology#top',
            linkName: 'Pathology',
          },
          {
            type: 'internal_link',
            linkTo: '/genomics/pharmacogenomics',
            linkName: 'Pharmacogenomics',
          },
          {
            type: 'internal_link',
            linkTo: '/genomics/cancer',
            linkName: 'Hereditary Cancer Testing',
          }
        ],
      },
      {
        title: 'Contact Us',
        links: [
          {
            type: 'internal_link',
            linkTo: '/contact#top',
            linkName: 'Contact Us',
          },
          {
            type: 'internal_link',
            linkTo: '/contact#write-us-a-message',
            linkName: 'Write Us A Message',
          }
        ],
        socialLinks: true,
      },
    ],
  },
};
