import React from 'react';

const TailText = ({title, desc}) => {
  return (
    <div className="tail-text-wrapper">
    {title ? <h3 className="title heading-5"> {title} </h3> : null}
    {desc ?
      desc.map((p, i) =>
        typeof p === 'string' ? 
          (<p key={i} className= "tail-text-text text-block-2">{p}</p>) : (p)
      ) 
    : null}
  </div>
  );
};

export default TailText;