import styled from 'styled-components';
import device from '../../../../styles/mediaSizes';

// 1-SECTION
export const HeroBannerHeart = styled.div`
  & {
    border-bottom: 4px solid #ff5858;
  }
  .hero-banner-wrapper {
    .hero-left-col {
      width: 48%;

      .heading-1 {
      }
      .heading-4 {
      }
    }
    .hero-right-col {
      width: 48%;
    }
    @media ${device.lg} {
      & {
        height: 60vh;
        border: none;
        .hero-left-col {
          width: 50%;
          .heading-1 {
            margin-bottom: 10px;
          }
          .heading-4 {
            width: auto;
            min-width: auto;
          }
        }
        .hero-right-col {
          width: 50%;
        }
      }
    }
    @media ${device.md} {
    }
    @media ${device.sm} {
      & {
        margin: 60px 0;
        flex-direction: column-reverse;
        height: auto;
        border: none;
        .hero-left-col {
          width: 100%;
          .heading-1 {
            margin-bottom: 10px;
          }
          .heading-4 {
            width: auto;
            min-width: auto;
          }
        }
        .hero-right-col {
          width: 100%;
          margin-bottom: 25px;
        }
      }
    }
  }
`;

// 2-SECTION
export const PicTextCTOSpecial = styled.div`
  @media ${device.sm} {
    .rn-section-gap {
      padding: 0 !important;
      .container {
        margin: 0;
        padding: 0;
        max-width: 100%;
      }
    }
  }
  .pic-text-wrapper {
    background-color: ${({ theme }) => theme.forthColor};
    margin: auto;
    border-radius: 9px;
    .pic-col {
      width: 33%;
      .inner-img {
        padding-left: 0;
        border-radius: 0;
        width: 100%;
      }
      .regular-img {
        display: inline-block;
      }
      .mobile-img {
        display: none;
      }
    }
    .text-col {
      width: 64%;
      .text-group {
        .heading-1 {
        }
        .heading-2 {
          color: ${({ theme }) => theme.secColor};
          width: 90%;
        }
        .heading-4 {
          color: ${({ theme }) => theme.secColor};
          width: 90%;
        }
      }
    }
    @media ${device.sm} {
      & {
        padding-bottom: 60px;
        .pic-col {
          width: 100%;
          margin-bottom: 35px;
          .inner-img {
          }
          .regular-img {
            display: none;
          }
          .mobile-img {
            display: inline-block;
          }
        }
        .text-col {
          width: 80%;
          margin: 0 auto;
          .text-group {
            margin-bottom: 0;
            .title {
              margin: 0;
              padding: 0;
              width: 100%;
            }
          }
        }
      }
    }
  }
`;

// 3-SECTION
export const RiskOfCancer = styled.div``;

// 5-SECTION
export const PharmacogenomicsExplained = styled.div``;

// 6-SECTION
export const BenefitsHeart = styled.div``;

// 7-SECTION
export const WhoNeedsThis = styled.div`
  .section-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .col-1 {
      width: 40%;
    }
    .col-2 {
      width: 48%;
      margin-top: 1em;
    }
    @media ${device.lg} {
    }
    @media ${device.md} {
    }
    @media ${device.sm} {
      & {
        .col-1,
        .col-2 {
          width: 100%;
        }
      }
    }
  }
  .check-list-wrapper {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    .check-list-title {
    }
    .check-list-intro {
      margin-bottom: 15px;
    }
    .check-list-item {
      /* display: flex;
      align-items: center; */
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      .check-list-main {
        display: flex;
        .check-list-item-img {
          display: inline-block;
          align-self: start;
          padding-top: 0.5em;
          margin-right: 10px;
          width: 18px;
          margin-top: 3px;
          margin-left: 4px;
        }
        .check-list-text {
          font-weight: 600;
          color: #342a47;
        }
      }
      .inner-list {
        list-style: none;
        margin: 25px 0 25px 15px;
        padding-left: 25px;
        padding-left: 25px;
        &.list-border {
          border-left: 2px solid ${({ theme }) => theme.mainColor};
        }
        .inner-list-item {
          display: flex;
          align-items: center;
          margin-top: 0;
          margin-bottom: 10px;
          line-height: 1.7;
          width: 100%;
          &:before {
            content: '⚫';
            padding-right: 20px;
            font-size: 6px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    @media ${device.lg} {
      & {
        margin-bottom: 30px;
        &:last-child {
        }
        .check-list-title {
        }
        .check-list-intro {
        }
        .check-list-item {
          &:last-child {
          }
          .check-list-item-img {
            padding-top: 0.2em;
          }
          .check-list-text {
          }
          .inner-list {
            &.list-border {
            }
            .inner-list-item {
              &:before {
              }
              &:last-child {
              }
            }
          }
        }
      }
    }
    @media ${device.md} {
      & {
        .check-list-intro {
        }
        .check-list-item {
          .check-list-item-img {
          }
          .check-list-text {
          }
          .inner-list {
            &.list-border {
            }
            .inner-list-item {
              &:before {
              }
            }
          }
        }
      }
    }
    @media ${device.sm} {
      & {
        margin-bottom: 30px;
        &:last-child {
        }
        .check-list-title {
        }
        .check-list-intro {
        }
        .check-list-item {
          &:last-child {
          }
          .check-list-item-img {
          }
          .check-list-text {
          }
          .inner-list {
            margin-bottom: 30px;
            &.list-border {
            }
            .inner-list-item {
              &:before {
                padding-right: 12px;
                font-size: 5px;
              }
              &:last-child {
              }
            }
          }
        }
      }
    }
  }
`;

// 8-SECTION
export const HowItWorks = styled.div`
  .section-title-wrapper {
    margin-bottom: 60px;
    .title {
      text-align: center;
    }
    .description {
      margin: 0 auto;
      text-align: center;
      width: 59%;
    }
    @media ${device.lg} {
    }
    @media ${device.md} {
      & {
        .description {
          width: 90%;
        }
      }
    }
    @media ${device.sm} {
    }
  }
`;

// 9-SECTION
export const PicTextPayZero = styled.div`
  .pic-text-wrapper {
    .pic-col {
      width: 41.6%;
      .inner-img {
        border-radius: 0;
        width: 100%;
      }
      .regular-img {
        display: inline-block;
      }
      .mobile-img {
        display: none;
      }
    }
    .text-col {
      .text-group {
        .heading-1 {
        }
        .heading-2 {
        }
        .heading-4 {
          color: #5f5a6a;
        }
      }
    }

    @media ${device.sm} {
      & {
        .pic-col {
          width: 100%;
          .inner-img {
          }
          .regular-img {
            display: inline-block;
          }
          .mobile-img {
            display: none;
          }
        }
        .text-col {
          width: 100%;
          .text-group {
            .heading-1 {
            }
            .heading-2 {
            }
            .heading-4 {
              color: #5f5a6a;
            }
          }
        }
      }
    }
  }
`;

// 10-SECTION
export const FaqArea = styled.div`
  .faq-wrapper {
    .accordion {
      .accordion-item {
        .accordion-header {
          .accordion-button {
            background: ${({ bgColor }) => bgColor};
            &:after,
            &.collapsed:after {
            }
            &.collapsed:after {
              background-image: url(/images/decor/faq_open_${({ theme }) => theme.themeColor}.svg);
            }
            &:after {
              background-image: url(/images/decor/faq_close_${({ theme }) => theme.themeColor}.svg);
            }
            &:focus {
            }
            &:focus:not(.collapsed) {
            }
            &:not(.collapsed) {
              background: ${({ bgColor }) => bgColor};
              color: ${({ theme }) => theme.mainColor};
            }
          }
        }
        .accordion-body {
        }
      }
    }
    @media ${device.lg} {
      & {
        .section-title-wrapper {
          margin-bottom: 45px;
        }
        .accordion {
          width: 75%;
          .accordion-item {
            border: none;
            border-bottom: #dee2e6 1px solid;
            .accordion-header {
              .accordion-button {
                margin: 0;
                padding: 18px 0;
                &:after,
                &.collapsed:after {
                }
                &:after {
                }
                &.collapsed:after {
                }
                &:focus {
                  box-shadow: none;
                }
                &:focus:not(.collapsed) {
                  box-shadow: none;
                }
                &:not(.collapsed) {
                }
              }
            }
            .accordion-body {
              padding: 12px 28px;
            }
          }
        }
      }
    }
    @media ${device.md} {
      & {
        .section-title-wrapper {
          margin-bottom: 45px;
        }
        .accordion {
          width: 75%;
          .accordion-item {
            border: none;
            border-bottom: #dee2e6 1px solid;
            .accordion-header {
              .accordion-button {
                margin: 0;
                padding: 18px 0;
                &:after,
                &.collapsed:after {
                }
                &:after {
                }
                &.collapsed:after {
                }
                &:focus {
                  box-shadow: none;
                }
                &:focus:not(.collapsed) {
                  box-shadow: none;
                }
                &:not(.collapsed) {
                }
              }
            }
            .accordion-body {
              padding: 12px 28px;
            }
          }
        }
      }
    }
    @media ${device.sm} {
      & {
        .section-title-wrapper {
          margin-bottom: 20px;
          .title {
            font-size: 24px;
          }
        }
        .accordion {
          width: 100%;
          .accordion-item {
            .accordion-header {
              .accordion-button {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: nowrap;
                &:after,
                &.collapsed:after {
                  margin-left: 20px;
                }
                &:after {
                }
                &.collapsed:after {
                }
                &:focus {
                  box-shadow: none;
                }
                &:focus:not(.collapsed) {
                  box-shadow: none;
                }
                &:not(.collapsed) {
                }
              }
            }
            .accordion-body {
              padding: 12px 28px;
            }
          }
        }
      }
    }
  }
`;
