import React from 'react';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import AbidingByLinks from './AbidingdByLinks';

const NavSubmenu = ({ content, closeMobileMenu, hideSubmenu }) => {
  return (
    <>
      <div className="menu-wrapper row align-items-start container">
        <div className="col-lg-4 d-none d-lg-block">
          <h1 className="heading-1">{content.title}</h1>
          {/* <p className="text-block-2">{content.desc}</p> */}
          {/* <AbidingByLinks /> */}
        </div>
        <ul className="col-12 col-lg-4">
          {content.links
            ? content.links.map((link, i) => {
                if (link.type === 'btn') {
                  return (
                    <li key={i} className="btn-wrapper" onClick={hideSubmenu}>
                      <Link
                        style={{ background: '#4557ce' }}
                        className="mt-3 btn-default text-light"
                        to={link.linkTo}
                        onClick={closeMobileMenu}
                      >
                        <span>{link.linkName}</span>
                      </Link>
                    </li>
                  );
                }
                return (
                  <li key={i} onClick={hideSubmenu}>
                    {link.type === 'internal_link' ? (
                      <Link className="text-block-2" to={link.linkTo} onClick={closeMobileMenu}>
                        {link.linkName}
                      </Link>
                    ) : (
                      <a href={link.linkTo} target="_blank">
                        {link.linkName}
                      </a>
                    )}
                  </li>
                );
              })
            : null}
        </ul>
        <div className="col-lg-12 d-none d-lg-block">
          <a href={content.cta.href} className="call-section-wrapper">
            <div className="call-section-content">
              <p className="assist-text">{content.cta.slogan}</p>
              <div className="phone-img-wrapper">
                <img className="phone-image" src="/images/nav_footer/dropdown-menu-call-bubble.svg" alt="call-icon-dark" />
                <img
                  className="phone-image hover"
                  src="/images/call-hover.svg"
                  alt="call-icon-light"
                />
              </div>
              <p className="phone-number"> {content.cta.phone} </p>
              <img className="phone-lady" src="/images/nav_footer/dropdown-menu-call-lady.svg" alt="lady-icon" />
            </div>
            <img
              className="call-section-img-bg"
              src="/images/nav_footer/dropdown-menu-phone-bg.svg"
              alt="phone-icon"
            />
          </a>
        </div>
      </div>
    </>
  );
};

export default NavSubmenu;
