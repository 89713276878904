import React from 'react';
import HeroTitle from './sections/HeroTitle';
import OurCompany from './sections/OurCompany';
import MeetTheTeam from './sections/MeetTheTeam';
import Layout from '../../partials/Layout';
import IPathologyAffordable from './sections/IPathologyIsAffordable';
import PageScrollTop from '../../utils/PageScrollTop';
import { GeneralComponentsStyle } from '../styles/GeneralComponentsStyle';
import * as Section from './sections/AboutUsSections';
// CONTENT
import { aboutUs as content } from './sections/content/aboutUs';

const AboutUs = () => {
  return (
    <Layout>
      <GeneralComponentsStyle>
        {/* <HeroTitle content={content.heroTitle} bgColor={'#f4f5f9'} /> */}
        <Section.PageHeroAboutUs content={content.pageHeroAboutUs} bgColor={'#f4f5f9'} />
        <OurCompany id="our-company" content={content.ourCompany} bgColor={'#fff'} />
        {/* <MeetTheTeam id="meet-the-team" content={content.meetTheTeam} bgColor={'#f4f5f9'} /> */}
        <IPathologyAffordable content={content.iPathologyAffordable} bgColor={'#f8f8f8'} />
      </GeneralComponentsStyle>
    </Layout>
  );
};

export default AboutUs;
