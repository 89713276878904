import React from 'react';
import PageHero from '../../../components/PageHero';
import * as Styled from './styles/StyledAboutUs';

export const PageHeroAboutUs = ({ bgColor, content }) => {
  return (
    <Styled.PageHeroAboutUs style={{ backgroundColor: bgColor }}>
      <div className="container">
        <PageHero {...content.pageHero} />
      </div>
    </Styled.PageHeroAboutUs>
  );
};
