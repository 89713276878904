import React from 'react';

export const homepage = {
  bannerData: [
    {
      image: '/images/pages/home-banner-pathology.jpeg',
      title: 'Pathology.',
      description:
        'iPath Life Sciences has specialized in the diagnosis of disease since 2004.  It is our mission to ensure accurate, timely, comprehensive and validated analytical results to patients and healthcare providers across the globe; while improving diagnostic methodologies through applied research and development.',
      href: '/genomics/pathology',
    },
    {
      image: '/images/pages/home-banner-baby.png',
      title: 'Genomics.',
      description:
        'iPath Life Sciences offers healthcare providers a more accurate method in prescribing medications, while providing patients an affordable opportunity to explore their personal genetic profile.',
      href: '/genomics/cancer',
    },
    {
      image: '/images/pages/home-banner-microscope.jpeg',
      title: 'In-Office Pathology.',
      description:
        'iPath Life Sciences offers expert consultation to healthcare providers interested in building their own In-Office Laboratory. Our “Laboratory Consultants” tailor custom plans that best suit your specific needs.',
      href: '/genomics/providers/pathology',
    },
  ],

  bannerData2: [
    {
      image: '/images/bg/home-banner-pathology.jpeg',
      title: 'Pathology.',
      subtitle: 'Innovative Molecular Diagnostic Testing.',
      description:
        'Determine your genetic predisposition to some of the leading diseases in America. iPath Life Sciences provides easy to use kits that provide fast, accurate and actionable results to help enhance your future health.',
      href: '#',
    },
    {
      image: '/images/bg/bg-image-genomics.png',
      title: 'Genomics.',
      subtitle: 'Stay ahead of the game with precise genetic testing.',
      description:
        'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sed quod autem sequi reprehenderit labore consequuntur excepturi voluptatibus omnis similique qui unde eligendi tempora, ea at, laudantium nostrum minus pariatur quasi!',
      href: '#',
    },
    {
      image: '/images/pages/home-banner-microscope.jpeg',
      title: 'In-Office Pathology.',
      subtitle: 'Models that Optimize Patient Care!',
      description:
        "iPath Life Sciences provides pathology services specializing in emergency or high-risk cases to ensure doctor offices has the priority on identify their patient's needs.",
      href: '#',
    },
  ],

  about: {
    img: '/images/pages/home-Lab-front.jpg',
    title: ' iPath Life Sciences',
    typedOptions: {
      strings: ['Accuracy.', 'Efficiency.', 'Reliability.'],
      typeSpeed: 80,
      backSpeed: 5,
      backDelay: 1000,
      loop: true,
    },
    desc: [
      '24-Hour Turnaround Time',
      'Distinguished Board-Certified Medical Staff',
      'Accepts Most Major Insurance Plans',
      'All Cases Treated Like STAT Cases',
    ],
  },

  mission: {
    title: 'Mission',
    typedOptions: {
      strings: ['Accuracy.', 'Efficiency.', 'Reliability.'],
      typeSpeed: 80,
      backSpeed: 5,
      backDelay: 1000,
      loop: true,
    },
    desc: [
      'iPath Life Sciences has specialized in the diagnosis of disease since 2004.  It is our mission to ensure accurate, timely, comprehensive and validated analytical results to patients and healthcare providers across the globe; while improving diagnostic methodologies through applied research and development.',
    ],

    serviceList: [
      'Dermatopathology',
      'Gastrointestinal Pathology',
      'Urologic Pathology',
      'Gynecologic Pathology',
      'FNA-Cytology',
      'PGx – Pharmacogenomics',
      'CGx – Hereditary Cancer Screenings',
      'Tumor Testing',
    ],
  },

  illuminaVideos: {
    titleSection: {
      bigTitle: 'Learn More about Our Cutting Edge Technology',
      smallTitle: null,
      subtitle: null,
      description: 'Illumina is a leading developer, manufacturer, and marketer of life science tools and integrated systems for large-scale analysis of genetic variation and function. These systems are enabling studies that were not even imaginable just a few years ago, and moving us closer to the realization of personalized medicine.',
    },
    cards: [
      {
        linkTo: 'https://www.youtube.com/watch?v=dBzzXEIVM-A&t=73s',
        cardImg: '/images/pages/home-illumina1.png',
        thumbImg: '/images/partners/ILLUMINA.svg',
        title: 'Healthcare Experts View on Comprehensive Genomic Profiling',
        desc: 'A global genomics leader, Illumina provides comprehensive next-generation sequencing solutions to the research, clinical, and applied markets.',
      },
      {
        linkTo: 'https://www.youtube.com/watch?v=aG7jTzGeLu4&ab_channel=Illumina',
        cardImg: '/images/pages/home-illumina2.png',
        thumbImg: '/images/partners/ILLUMINA.svg',
        title: 'Discover Demonstrated NGS Oncology Workflows',
        desc: 'Next-generation sequencing (NGS) empowers researchers to assess multiple genes in a single test.',
      },
      {
        linkTo: 'https://www.youtube.com/watch?v=xaguct7saTw&list=PLKRu7cmBQlaiQbVkCco7Kre3KkyElP04h&index=7',
        cardImg: '/images/pages/home-illumina3.png',
        thumbImg: '/images/partners/ILLUMINA.svg',
        title: 'Role of Genomics in Identifying Biomarkers for Response and Resistance to Anti-Cancer Immunotherapy',
        desc: 'Dr Eliezer Van Allen, Assistant Professor at Harvard Medical School and Medical Oncologist at the Dana Farber Cancer Institute, discusses his groundbreaking work in the genomics of cancer immunotherapy. ',
      }
    ],
  },
};
