import styled from 'styled-components';
import device from '../../../styles/mediaSizes';

export const PageHeroAboutUs = styled.div`
  .page-hero-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding-top: 120px;
    height: 300px;
    .page-hero-img-wrapper {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 0;
      transform: translate(0, 50%);
      margin: auto;
      margin-top: 35px;
      margin: auto;
      .page-hero-main-img {
        display: block;
        position: static;
        max-height: 300px;
        border-radius: 6px;
        margin: auto;
      }
      .page-hero-decor-img {
        position: absolute;
        bottom: 0;
        transform: translate(0, 50%);
        max-height: 60px;
        display: block;
        margin: auto;
      }
    }
    @media ${device.lg} {
      & {
        position: relative;
        padding-top: 80px;
        .page-hero-title-wrapper {
          padding-bottom: 185px;
          .title {
          }
          .subtitle {
          }
        }
        .page-hero-img-wrapper {
          bottom: 0;
          transform: translate(0, 50%);
          .page-hero-main-img {
            max-height: 339px;
          }
          .page-hero-decor-img {
            position: absolute;
            bottom: 0;
            display: block;
            margin: auto;
          }
        }
      }
    }
    @media ${device.md} {
      & {
        position: relative;
        padding-top: 45px;
        .page-hero-title-wrapper {
          padding-bottom: 180px;
          .title {
            margin-bottom: 25px;
          }
          .subtitle {
            width: 70%;
          }
        }
        .page-hero-img-wrapper {
          bottom: 0;
          transform: translate(0, 50%);
          .page-hero-main-img {
            max-height: 300px;
          }
          .page-hero-decor-img {
            max-height: 80px;
            bottom: 0;
          }
        }
      }
    }
    @media ${device.sm} {
      & {
        position: relative;
        padding-top: 45px;
        height: 200px;
        .page-hero-title-wrapper {
          padding-bottom: 100px;
          .title {
            margin-bottom: 15px;
          }
          .subtitle {
            width: 90%;
          }
        }
        .page-hero-img-wrapper {
          bottom: 0;
          transform: translate(0, 50%);
          .page-hero-main-img {
            max-height: 170px;
          }
          .page-hero-decor-img {
            max-height: 35px;
            bottom: 0;
          }
        }
      }
    }
  }
`;
