import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Logo from './Logo';
import Copyright from './Copyright';
import AbidingByLinks from './AbidingdByLinks';
import ScrollTop from './ScrollTop';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from 'react-icons/fi';
import { StyledFooter } from './styles/StyledFooter';

const Footer = ({ content }) => {
  return (
    <>
      <StyledFooter className="rn-footer footer-style-default variation-two">
        {/* <CallToAction /> */}
        <div className="footer-top">
          <div className="container">
            <div className="footer-wrapper">
              <div className="footer-logo-wrapper ">
                <Logo
                  image={`${process.env.PUBLIC_URL}/images/nav_footer/log_iPathLifeSciences.png`}
                  image2={`${process.env.PUBLIC_URL}/images/nav_footer/log_iPathLifeSciences.png`}
                />
              </div>
              {content.items.map((el, i) => {
                return (
                  <div key={i} className={`footer-item`}>
                    <h5> {el.title}</h5>
                    <ul className="ft-menu link-hover">
                      {el.links.map((link, i) => {
                        return (
                          <li key={i}>
                            {link.type ==='internal_link' ? 
                              <Link to={link.linkTo}>
                                {link.linkName}
                              </Link> :
                              <a href={link.linkTo} target='_blank'>{link.linkName}</a>
                            }
                          </li>
                        );
                      })}
                      {el.socialLinks ? (
                        <ul className="social-icon social-default justify-content-start">
                          <li>
                            <Link to="facebook.com"><FiFacebook /></Link>
                          </li>
                          {/* <li><Link to='twitter.com'><FiTwitter /></Link></li> */}
                          <li>
                            <Link to="instagram.com"><FiInstagram /></Link>
                          </li>
                          <li>
                            <Link to="linkdin.com"><FiLinkedin /></Link>
                          </li>
                        </ul>
                      ) : null}
                    </ul>
                  </div>
                );
              })}
            </div>
            <AbidingByLinks />
          </div>
        </div>
        <Copyright />
      </StyledFooter>
      {/* <ScrollTop /> */}
    </>
  );
};

export default Footer;

// <>
// <footer className='rn-footer footer-style-default variation-two'>
// <CallToAction />
// <div className='footer-top'>
// <div className='container'>
// <div className='row'>
// {/* Start Single Widget  */}
// <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
// <div className='rn-footer-widget'>
// <h4 className='title'>{footerIntemFive.title}</h4>
// <div className='inner'>
// <h6 className='subtitle'>{footerIntemFive.subtitle}</h6>
// <ul className='social-icon social-default justify-content-start'>
// <li>
// <Link to='facebook.com'>
// <FiFacebook />
// </Link>
// </li>
// <li>
// <Link to='twitter.com'>
// <FiTwitter />
// </Link>
// </li>
// <li>
// <Link to='instagram.com'>
// <FiInstagram />
// </Link>
// </li>
// <li>
// <Link to='linkdin.com'>
// <FiLinkedin />
// </Link>
// </li>
// </ul>
// </div>
// </div>
// </div>
// {/* End Single Widget  */}
// </div>
// </div>
// </div>
// </footer>
// <ScrollTop />
// </>

// const footerIntem = footerOne[0];
// const footerIntemOne = footerOne[1];
// const footerIntemTwo = footerOne[2];
// const footerIntemThree = footerOne[3];
// const footerIntemFour = footerOne[4];
// const footerIntemFive = footerOne[5];

// const indexOneLink = footerIntemOne.quicklink;
// const indexTwoLink = footerIntemTwo.quicklink;
// const indexThreeLink = footerIntemThree.quicklink;
