import styled from 'styled-components';
import device from '../../../styles/mediaSizes';

export const StyledIlluminaVideos = styled.div`
  .cards-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 3%;
    padding: 0;
    .card-item {
      position: relative;
      display: block;
      height: 400px;
      border-radius: calc(9 * 1px);
      overflow: hidden;
      text-decoration: none;
      &:hover .card-overlay {
        transform: translateY(0);
        border-radius: calc(40 * 1px) 0 0 0;
      }
      &:hover .card-header {
        transform: translateY(0);
      }
      .card-image {
        display: block;
        margin: 0 auto;
        width: auto;
        height: 100%;
        object-fit: cover;
      }
      .card-header {
        position: relative;
        display: flex;
        align-items: center;
        gap: 1.5em;
        bottom: -2px;
        padding: 2em;
        border-radius: calc(40 * 1px) 0 0 0;
        background-color: #fff;
        transform: translateY(-100%);
        transition: 0.2s ease-in-out;
        .card-arc {
          width: 80px;
          height: 80px;
          position: absolute;
          bottom: 100%;
          right: 0;
          z-index: 1;
          path {
            fill: #fff;
            /* d: path('M 40 80 c 22 0 40 -22 40 -40 v 40 Z'); */
          }
        }
        .card-thumb {
          flex-shrink: 0;
          padding-bottom: 12px;
          width: 70px;
          height: 70px;
          border-radius: 30%;
          object-fit: contain;
        }
        .card-title {
          font-size: 1.5em;
          margin: 0 0 0.1em;
          color: #6a515e;
        }
      }
      .card-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: fit-content;
        z-index: 1;
        background-color: #fff;
        transform: translateY(100%);
        transition: 0.2s ease-in-out;
        .card-description {
          padding: 1em 1em 3em;
          margin: 0;
          overflow: hidden;
        }
      }
    }

    @media ${device.lg} {
      & {
        gap: 2%;
        .card-item {
          height: 350px;
          &:hover .card-overlay {
            transform: translateY(100%);
          }
          &:hover .card-header {
            transform: translateY(-100%);
          }
          .card-image {
          }
          .card-header {
            flex-wrap: wrap;
            gap: 0;
            padding: 0 2em 2em 2em;
            .card-arc {
              path {
              }
            }
            .card-thumb {
              padding-bottom: 0;
              width: 60px;
              height: 40px;
            }
            .card-title {
              font-size: 13px;
            }
          }
          .card-overlay {
            .card-description {
              padding: 1em 1em 3em;
              margin: 0;
            }
          }
        }
      }
    }

    @media ${device.md} {
      & {
        gap: 0;
        flex-wrap: wrap;
        .card-item {
          width: 48%;
          height: 350px;
          margin: auto;
          margin-bottom: 3%;
          &:last-child {
            margin-bottom: 0;
          }
          &:hover .card-overlay {
            transform: translateY(100%);
          }
          &:hover .card-header {
            transform: translateY(-100%);
          }
          .card-image {
          }
          .card-header {
            flex-wrap: wrap;
            gap: 0;
            padding: 0 2em 2em 2em;
            .card-arc {
              path {
              }
            }
            .card-thumb {
              padding-bottom: 0;
              width: 60px;
              height: 40px;
            }
            .card-title {
              font-size: 13px;
            }
          }
          .card-overlay {
            .card-description {
              padding: 1em 1em 3em;
              margin: 0;
            }
          }
        }
      }
    }

    @media ${device.sm} {
      & {
        gap: 0;
        flex-wrap: wrap;
        .card-item {
          width: 100%;
          height: 350px;
          margin: auto;
          margin-bottom: 8%;
          &:last-child {
            margin-bottom: 0;
          }
          &:hover .card-overlay {
            transform: translateY(100%);
          }
          &:hover .card-header {
            transform: translateY(-100%);
          }
          .card-image {
          }
          .card-header {
            flex-wrap: wrap;
            gap: 0;
            padding: 1.5em 2em 2em 2em;
            .card-arc {
              path {
              }
            }
            .card-thumb {
              padding-bottom: 0;
              width: 45px;
              height: 25px;
            }
            .card-title {
              font-size: 12px;
              width: 100%;
            }
          }
          .card-overlay {
            .card-description {
              padding: 1em 1em 3em;
              margin: 0;
            }
          }
        }
      }
    }
  }

  .section-title-wrapper {
    margin-bottom: 75px;
    .heading-1,
    .heading-2 {
      font-size: 50px;
      text-align: center;
      margin-bottom: 15px;
    }
    .heading-4 {
      text-align: center;
    }
    .text-block-2 {
      margin: 0 auto;
      text-align: center;
      width: 80%;
    }

    @media ${device.lg} {
      & {
        margin-bottom: 75px;
        .heading-1,
        .heading-2 {
          font-size: 50px;
          text-align: center;
          margin-bottom: 15px;
        }
        .heading-4 {
          text-align: center;
        }
        .text-block-2 {
          margin: 0 auto;
          text-align: center;
          width: 80%;
        }
      }
    }
    @media ${device.md} {
      & {
        margin-bottom: 55px;
        .heading-1,
        .heading-2 {
        }
        .heading-4 {
        }
        .text-block-2 {
        }
      }
    }
    @media ${device.sm} {
      & {
        margin-bottom: 35px;
        .heading-1 {
          font-size: 38px;
          text-align: center;
          margin-bottom: 15px;
        }
        .heading-2 {
          text-align: center;
          margin-bottom: 15px;
        }
        .heading-4 {
          text-align: center;
        }
        .text-block-2 {
          margin: 0 auto;
          text-align: center;
          width: 100%;
        }
      }
    }
  }
`;
