import React from 'react';
import BuyTestBtn from '../components/BuyTestBtn';

const PicText = ({content, btn, theme})=>{
  const renderTitle = () => {
    if (content.regularTitle) {
      return <h2 className="title heading-2">{content.regularTitle}</h2>;
    }
    if (content.largeTitle) {
      return <h2 className="title heading-1">{content.largeTitle}</h2>;
    }
    return null;
  };
  return(
    <div className="pic-text-wrapper">
    <div className="pic-col">
      <img className="inner-img  regular-img" src={content.img} />
      <img className="inner-img mobile-img" src={content.imgMobile} />
    </div>
    <div className="text-col">
      <div className="text-group">
        {/* TITLE */}
        {renderTitle() ? renderTitle() : null}
        {/* SUBTITLE */}
        {content.subtitle ? (
          <h3 id="pic" className="heading-4"> {content.subtitle} </h3>
        ) : null}
        {/* DESCRIPTION */}
        {content.p.map((p, i) => (
          <p key={i} className="text-block-2"> {p} </p>
        ))}
      </div>
        {btn ? <BuyTestBtn theme={theme} link="/order-test" /> : null}
    </div>
  </div>
  )
}

export default PicText;