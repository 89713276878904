import styled from 'styled-components';
import device from '../../../styles/mediaSizes';

export const StyledOurCompany = styled.div`
  & {
    padding: 300px 0 135px 0;
  }

  h4 {
    margin: 4rem 0 3rem 0;
  }

  @media ${device.lg} {
    & {
      padding: 230px 0 80px 0;
    }
    .heading-1 {
      margin-bottom: 15px;
    }
  }

  @media ${device.md} {
    & {
      padding: 200px 0 80px 0;
    }
    .heading-1 {
      margin-bottom: 15px;
    }
  }

  @media ${device.sm} {
    & {
      padding: 150px 0 50px 0;
    }
    .heading-1 {
      margin-bottom: 15px;
    }
  }
`;