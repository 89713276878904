import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import NavSubmenu from './NavSubmenu';

const Nav = ({ content, closeMobileMenu }) => {
  const [showSubmenu, setShowSubmenu] = useState(null);
  let keepLastIndex;
  const hideSubmenu = index => {
    if (keepLastIndex === index) {
      keepLastIndex = showSubmenu;
      setShowSubmenu(null);
    } else {
      keepLastIndex = showSubmenu;
      setShowSubmenu(index);
    }
  };
  const viewSubmenu = index => {
    setShowSubmenu(index);
  };

  return (
    <ul className="mainmenu">
      {content
        ? content.map((item, i) => {
            return (
              <li key={i} className="has-dropdown">
                {window.innerWidth > 1025 ? (
                  <Link
                    onMouseEnter={() => viewSubmenu(i)}
                    onMouseLeave={() => hideSubmenu(i)}
                    onClick={() => hideSubmenu(i)}
                    to={item.linkTo}
                  >
                    {item.linkName}
                  </Link>
                ) : (
                  <Link
                    onMouseEnter={() => viewSubmenu(i)}
                    onMouseLeave={() => hideSubmenu(i)}
                    onClick={() => hideSubmenu(i)}
                    to="#"
                  >
                    {item.linkName}
                  </Link>
                )}

                <div
                  onClick={hideSubmenu}
                  onMouseEnter={() => viewSubmenu(i)}
                  onMouseLeave={hideSubmenu}
                  className={`${showSubmenu === i ? 'open' : ''} submenu d-flex align-items-center`}
                >
                  <NavSubmenu
                    content={item.submenu}
                    closeMobileMenu={closeMobileMenu}
                    hideSubmenu={hideSubmenu}
                  />
                </div>
              </li>
            );
          })
        : null}
    </ul>
  );
};
export default Nav;
