import React from 'react';
import { Link } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';

const GeneralBtn = ({ type, text, link }) => {
  return (
    <div className="button-group">
      {type === 'internal_link' ?
        <Link className="general-btn" to={link}>
          {text ? text : 'Request A Test Kit Now'}
          <i className="icon">
            <FiArrowRight />
          </i>
        </Link>
      :
        <a className="general-btn" target="_blank" href={link}>
          {text ? text : 'Request A Test Kit Now'}
          <i className="icon">
            <FiArrowRight />
          </i>
        </a>
      }
    </div>
  );
};

export default GeneralBtn;
