import React from 'react';

const AccordionBS = ({ items, bgColor }) => {
  return (
    <div className="accordion" id="faq-cancer">
      {items.map((item, index) => {
        return (
          <div key={index} className="accordion-item">
            <div className="accordion-header" id={`heading-${index}`}>
              <button
                className="accordion-button collapsed heading-5"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${index}`}
                aria-expanded="false"
                aria-controls={`collapse${index}`}
              >
                {item.title}
              </button>
            </div>
            <div
              id={`collapse${index}`}
              className="accordion-collapse collapse"
              aria-labelledby={`heading-${index}`}
              data-bs-parent="#faq-cancer"
              style={{ backgroundColor: bgColor }}
            >
              <div className="accordion-body text-block-2">{item.text}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AccordionBS;