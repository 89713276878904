import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const scrollOptions = {
  animateIn: 'fadeInUp',
  animateOut: 'fadeInOut',
  animateOnce: true,
};
// dangerouslySetInnerHTML={{ __html: title }}
const SectionTitle = ({ subtitle, title, description, textAlign }) => {
  return (
    <div className= {`section-title ${textAlign}`}>
      <ScrollAnimation {...scrollOptions}>
        {typeof title === 'string' ? <h1 className="heading-2 theme-gradient">{title}</h1> : title}
      </ScrollAnimation>
      <ScrollAnimation {...scrollOptions}>
        <h4 className="heading-4">{subtitle}</h4>
      </ScrollAnimation>
      <ScrollAnimation {...scrollOptions}>
        <p className="desc text-block-2">{description}</p>
      </ScrollAnimation>
    </ div>
  );
};
export default SectionTitle;
