import React from 'react';
import { FiArrowRight } from 'react-icons/fi';
import StyledBuyTestBtn from './styles/StyledBuyTestBtn';

const BuyTestBtn = ({ theme, text, link }) => {
  return (
    <div className="button-group">
      <StyledBuyTestBtn bg={theme.btnColor} hover={theme.secColor} className="icon" to={link}>
        {text ? text : 'Request A Test Kit Now'}
        <i className="icon">
          <FiArrowRight />
        </i>
      </StyledBuyTestBtn>
    </div>
  );
};

export default BuyTestBtn;
