import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from 'react-icons/fi';

export const aboutUs = {
  pageHeroAboutUs:{
    pageHero:{
      title: null,
      subtitle: null,
      mainImg: '/images/pages/home-banner-baby.png',
      decorImg: '/images/decor/decor_image_green.svg',
    }
  },
  
  heroTitle: {
    title: null,
    subtitle: null,
    mainImg: { file: '/images/pages/home-banner-baby.png', alt: 'About us intro image' },
    decorImg: { file: '/images/decor/decor_image_green.svg', alt: 'green decoration image' },
  },
  ourCompany: {
    id: 'our-company',
    title: 'Who We Are',
    subtitle:
      'iPath Life Sciences has been servicing patients and healthcare providers domestically and internationally since 2004.',
    desc: [
      'iPath Life Sciences provides custom pathology and genetic testing solutions by tailoring all of our services to your specific needs. Through an integrated and personalized approach, we will bring you the most accurate and timely results possible.',
    ],
    ourMission: {
      title: 'Our Mission',
      desc: [
        'It is the mission of iPath Life Sciences to ensure the most efficient and effective diagnostic patient care possible. This includes producing accurate, timely, comprehensive and completely validated analytical results to physicians and health care providers across the country',
        'iPath Life Sciences will meet these goals by maintaining a 24-hour turnaround time for all biopsies, improving analytical methods through applied research, and promoting continual advancements and development in the fields of pathology and genetic testing.',
      ],
    },
  },
  meetTheTeam: [
    // Dr. Nicholas
    {
      card: {
        img: "/images/team/NicholasALancia.png",
        name: ["Nicholas A. Lancia", "M.D."],
        position: "Pathologist",
        specialty: ["Board-Certified in Anatomic Pathology", "Board-Certified in Clinical Pathology"],
        location: "FL, USA",
        description: "a Curriculum  Vitae",
        turnOnSocial: false,
        socialNetwork: [
          {
            icon: <FiFacebook />,
            url: "#",
          },
          {
            icon: <FiTwitter />,
            url: "#",
          },
          {
            icon: <FiInstagram />,
            url: "#",
          },
        ],
      },
  
      popup: {
        img: "/images/team/NicholasALancia.png",
        name: "Nicholas A. Lancia, M.D",
        position: "Pathologist",
        specialty: "Board-Certified in Anatomic Pathology, Board-Certified in Clinical Pathology",
        desc: [
          {
            listTitle: "Education",
            list: [
              "Bachelor of Science in Integrated Life Sciences June 2000 – Sept. 2002 Kent State University, Kent, OH Graduated Magna cum Laude",
              "Doctor of Medicine Sept. 2002 – May 2006 Northeast Ohio Medical University, Rootstown, OH Combined B.S./M.D. program with Kent State University Formerly called Northeastern Ohio Universities College of Medicine"
            ],
          },
          {
            listTitle: "Residency",
            list: [
              "Combined AP/CP Residency July 2006 – June 2010 University of Florida / Shands Medical Center, Gainesville, FL",
              "Training experience at both UF/Shands Medical Center and Veterans’ Hospital."
              ],
          },
          {
            listTitle: "Fellowship",
            list: [
              "Gastrointestinal Pathology Fellowship July 2013 – May 2014 Ameripath GI Institute, Oakwood Village, OH",
              "GI fellowship included lectures and sign-out responsibilities. Personal case volume of 5,000 cases plus non-gyn cytology. Institutional annual case volume of over 35,000."
            ]
          },
          {
            listTitle: "Strengths",
            list: [
              "Gastrointestinal Pathology, Liver Pathology, Digital Pathology, Medical Contracting, Clinical Laboratory Medicine, Laboratory Administration (ASCP LMU Graduate), Quality Assurance"
            ]
          }
  
        ],
      },
    },
  
    {
      card: {
        img: '/images/team/SarahStaceyTorres.png',
        name: ['Sarah Stacey Torres'],
        position: 'Histotechnologist Supervisor',
        specialty: ["ASCP Board Certified","Licensed-Laboratory Professional"],
        location: 'FL, USA',
        description: 'a Curriculum  Vitae',
        turnOnSocial: false,
        socialNetwork: [
          {
            icon: <FiFacebook />,
            url: '#',
          },
          {
            icon: <FiTwitter />,
            url: '#',
          },
          {
            icon: <FiInstagram />,
            url: '#',
          },
        ],
      },
  
      popup: {
        img: '/images/team/SarahStaceyTorres.png',
        name: 'Sarah Stacey Torres',
        position: 'Histotechnologist Supervisor',
        specialty: null,
        desc: [
          {
            listTitle: 'Education',
            list: [
              'Bachelor’s in health services administration, Kaiser University 2012',
              'Associate of Science in Histotechnology, Kaiser University',
            ],
          },
          {
            listTitle: 'Certification',
            list: ['Clinical Laboratory Supervisor'],
          },
        ],
      },
    },
  ],
  iPathologyAffordable: {
    picTextArea: {
      img: '/images/pages/about-us-lab-tube.jfif',
      imgMobile: '/images/pages/about-us-lab-tube.jfif',
      regularTitle: 'iPath Life Sciences Is Affordable, within Reach and Accurate',
      largeTitle: null,
      specialTitle: null,
      subtitle: null,
      p: [
        'iPath Life Sciences is accredited by the Clinical Laboratory Improvement Amendments of 1988 (CLIA). A CLIA-Certified lab must meet specific quality standards, including qualifications for individuals who perform the test and other standards that ensure the accuracy and reliability of results.',
        'Our Nextseq2000 System is a genetic sequencer from Illumina, that provides High quality next-generation sequencing (NGS) technology with a fast turn around on results.',
        'iPath Life Sciences accepts most major insurance plans, allowing your office the opportunity to consolidate the number of labs you are using. All client billing is managed confidentially and professionally. Our extensive participation list ensures that you can have one primary laboratory that takes care of all you patients’ need.',
      ],
      btn: {type:'external_link', link: `${process.env.PUBLIC_URL}/files/insurancelist preview.pdf`, text: 'Insurance List' },
    },
    abideLogos: {
      title: 'Proud to Work with & Adhere to:',
      logos: [
        [
          {
            img: '/images/partners/CLIA.png',
            alt: 'CLIA-logo',
          },
          {
            img: '/images/partners/OSHA.png',
            alt: 'OSHA-logo',
          },
          {
            img: '/images/partners/ILLUMINA.png',
            alt: 'ILLUMINA-logo',
          },
        ],
        [
          {
            img: '/images/partners/HIPAA.png',
            alt: 'HIPAA-logo',
          },
          {
            img: '/images/partners/CMS.png',
            alt: 'CMS-logo',
          },
          {
            img: '/images/partners/MEDICAID.png',
            alt: 'MEDICAID-logo',
          },
        ],
      ],
    },
  },
};
