import React from 'react';
import SEO from '../../partials/components/SEO';
import Layout from '../../partials/Layout';
import PageScrollTop from '../../utils/PageScrollTop';
import * as Section from './sections/providers/ProvPharmacogenomics';
import { GeneralComponentsStyle } from '../styles/GeneralComponentsStyle';

//CONTENT
import { provPharmacogenomics as content } from './sections/providers/content/provPharmacogenomics';

const ProvPharmacogenomics = () => {
  const { theme, pageTitle } = content;
  return (
    <Layout>
      <PageScrollTop>
        <GeneralComponentsStyle className="general-components" theme={theme}>
          <SEO title="Pharmacogenomics" />
          <Section.PageHeroProvPharmacogenomics content={content.pageHeroPharmacogenomics} bgColor={'#fff'} />
          <Section.PharmacogenomicsResults theme={theme} content={content.pharmacogenomicsResults} bgColor={'#f4f5f9'} pageTitle={pageTitle} btn={false} mergeHero="merge-hero" />
          <Section.MedicationBenefits theme={theme} content={content.medicationBenefits} bgColor={'#fdfdfe'} pageTitle={pageTitle} btn={false} />
        </GeneralComponentsStyle>
      </PageScrollTop>
    </Layout>
  );
};

export default ProvPharmacogenomics;
