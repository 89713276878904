import React from 'react'

export const provHereditaryCancer = {
  pageTitle: 'ProvCancer',

  pageHeroProvCancer: {
    pageHero: {
      title: 'Hereditary Cancer Testing',
      subtitle: null,
      mainImg: '/images/pages/cancer-hero.svg',
      decorImg: null,
    },
  },

  pageHero: {
    title: 'Hereditary Cancer Testing',
    subtitle: null,
    // subtitle: 'At iPath Life Sciences, we’re providing patients with quality genetic testing to inform their health decisions and improve their chances of living a happy and healthier life.',
    mainImg: '/images/pages/cancer-hero.svg',
    decorImg: null
  },

  whatIsGeneticTesting: {
    picText: {
      img: '/images/pages/prov-cancer-gene-girl.jpg',
      imgMobile: '/images/pages/prov-cancer-gene-girl.jpg',
      regularTitle: 'What is genetic testing?',
      largeTitle: null,
      specialTitle: null,
      subtitle: null,
      p: [
        'Genetic testing analyzes or “reads” a person’s genes to look for pathogenic variants or changes that increase that person’s risk of developing a serious health condition over their lifetime.',
        'Studies have estimated between 10 and 15% of individuals may carry a pathogenic variant in a gene that increases their risk of developing cancer, even if they have no family history of cancer.'
      ],
    }
  },

  picTextRobust: {
    title: 'medication documented',
    sectionTitle: 'What is genetic testing?',
    subtitle: null,
    img: '/images/pages/prov-cancer-gene-girl.jpg',
    desc: [
      'Genetic testing analyzes or “reads” a person’s genes to look for pathogenic variants or changes that increase that person’s risk of developing a serious health condition over their lifetime.',
      'Studies have estimated between 10 and 15% of individuals may carry a pathogenic variant in a gene that increases their risk of developing cancer, even if they have no family history of cancer.'
    ],
    showCheckList: null,
    checkLists: null,
    numberedList: null,
  },

  hereditaryCancerTesting: {
    sectionTitle: {
      bigTitle: 'Hereditary Cancer Testing',
      description: null,
    },
    steps: {
      counterTitle: '',
      steps: [
        {
          stepTitle: 'What can a patient’s genetic information tell you about their future health?',
          stepDesc:
            'Hereditary cancer testing is genetic information decoded. Our Hereditary Cancer test analyzes genes associated with inherited cancer syndromes to determine your patient’s lifetime risk of developing cancer based on their genetic profile. This information helps you determine the best next steps in screening and medical management for your patients.',
        },
        {
          stepTitle: 'What steps can you take to help your patients live a long, healthy life?',
          stepDesc:
            'Many genes associated with inherited cancer syndromes have next steps established by the National Comprehensive Cancer Network (NCCN). These next steps may include recommendations for methods and frequencies of screening, surgical options, and medications that may be given to prevent cancer.',
        },
        {
          stepTitle: 'Is genetic testing covered by insurance?',
          stepDesc:
            'Genetic testing is more affordable than ever, and is often covered by insurance carriers. Individual policies can vary; we recommend contacting the insurance company directly to verify coverage',
        },
      ],
    },
  },

  titleArticleCards: {
    title: 'Hereditary Cancer Testing',
    subtitle: null,
    steps: [
      {
        stepTitle: 'What can a patient’s genetic information tell you about their future health?',
        stepDesc:
          'Hereditary cancer testing is genetic information decoded. Our Hereditary Cancer test analyzes genes associated with inherited cancer syndromes to determine your patient’s lifetime risk of developing cancer based on their genetic profile. This information helps you determine the best next steps in screening and medical management for your patients.',
      },
      {
        stepTitle: 'What steps can you take to help your patients live a long, healthy life?',
        stepDesc:
          'Many genes associated with inherited cancer syndromes have next steps established by the National Comprehensive Cancer Network (NCCN). These next steps may include recommendations for methods and frequencies of screening, surgical options, and medications that may be given to prevent cancer.',
      },
      {
        stepTitle: 'Is genetic testing covered by insurance?',
        stepDesc:
          'Genetic testing is more affordable than ever, and is often covered by insurance carriers. Individual policies can vary; we recommend contacting the insurance company directly to verify coverage',
      }
    ]
    },

      faqArea: {
        questions: [
          'Lorem ipsum dolor, sit amet consectetur adipisicing elit?',
          'Lorem ipsum dolor, sit amet consectetur adipisicing elit?',
          'Lorem ipsum dolor, sit amet consectetur adipisicing elit?',
          'Lorem ipsum dolor, sit amet consectetur adipisicing elit?',
        ],
        answers: [
          'sit amet consectetur adipisicing elit. Sed quod autem sequi reprehenderit labore consequuntur excepturi voluptatibus omnis similique qui unde eligendi tempora.',
          'sit amet consectetur adipisicing elit. Sed quod autem sequi reprehenderit labore consequuntur excepturi voluptatibus omnis similique qui unde eligendi tempora.',
          'sit amet consectetur adipisicing elit. Sed quod autem sequi reprehenderit labore consequuntur excepturi voluptatibus omnis similique qui unde eligendi tempora.',
          'sit amet consectetur adipisicing elit. Sed quod autem sequi reprehenderit labore consequuntur excepturi voluptatibus omnis similique qui unde eligendi tempora.',
        ],
      },

      theme: {
        themeColor: "purple",
        pageTitle: "ProvCancer",
        mainColor: "#4557ce",
        btnColor: "#4557ce",
        mainGrad: "linear-gradient(95deg, #080808 15%, #3f1c52 45%, #f01fff 75%, #44015f 100%) 95% / 200% 100%;",
        secColor: " #363b77",
        thirdColor: "#ece4fa",
      }
};


// orderedList: {
//   title: ['Once We Begin Working Together,', 'Your Office Will Benefit From:'],

//   list: [
//     '24-Hour Turnaround on All Results.',
//     'Our 9-Step Quality Assurance Program ensures your slides and reports in the least amount of time, without any error.',
//     'Transcription Services, to record all of your diagnoses.',
//     'Completely Customizable Requisitions, Reports and the ability to “Go Paperless.”',
//     'A Selection of Renowned Board-Certified Dermatopathologists available for consultations.',
//     'A comprehensive menu of over 75 special stains which are stored in-house.',
//     'Personal Laboratory Consultant available 24 hours a day, 7 days a week.',
//     'As well as additional ancillary services, such as Mobile Frozen Section Units available upon your request',
//   ],
// },

  // sliderArea: {
  //   h1: ["Pathology"],
  //   desc: "Understand how inherited heart conditions can affect your health.",
  //   p: [
  //     "Heart disease is often the result of poor lifestyle choices, but genetic heart disease is , to changes in genes, which we inherit from our parents and can pass down to our children.",
  //     "With genetic testing we can help you by determining your risk factors based on your DNA, so you can take steps now, to reduce complications in the future."
  //   ],
  //   img: "/images/pages/heart-hero.svg"
  // },

  // picTextArea: {
  //   img:"/images/pages/heart-genomics-2.svg",
  //   imgMobile:"/images/pages/heart-genomics-mobile.svg",
  //   regularTitle: null,
  //   largeTitle: null,
  //   specialTitle: "Managing a heart condition is possible if you know about it.",
  //   subtitle: "Finding out early if you have or are prone to a chronic or mild heart condition could save your life.",
  //   p:[null]
  // },

  // orderedList: {

  //   title: ["Our Services"],

  //   list: [
  //     'Dermatopathology',
  //     'Surgical Histopathology',
  //     'Gastrointestinal Pathology',
  //     'Urologic Pathology',
  //     'Gynecological Pathology',
  //     'Podiatric Pathology',
  //     'Immunohistochemistry',
  //     'FNA-Cytology',
  //     'Molecular pathology'
  //   ]
  // },

  // picTextArea2: {
  //   img:"/images/pages/heart-patient.png",
  //   imgMobile:"/images/pages/heart-patient.png",
  //   regularTitle: "Most insured patients pay $0",
  //   largeTitle: null,
  //   specialTitle: null,
  //   subtitle: null,
  //   p:[
  //     "iPath Life Sciences tests are covered by Medicare and most major insurers.",
  //     "Only 4% of patients have insurance plans which do not have coverage for our services. More than 96% of iPath Life Sciences patients have no out-of-pocket cost for screening."
  //   ]
  // },
  // howItWorks: {
  //   title: 'How it works',
  //   subtitle:
  //     'We’re driven by our commitment to deliver exceptional experiences to our customers. We value our strong customer relationships and are defined by them.',
  //   steps: [
  //     {
  //       stepTitle: 'Do you qualify?',
  //       stepDesc:
  //         'Fill in our intake questionnaire, online or via phone. We will assess your request and share it with your Primary Care to conduct a consult with you. If the physician approves your request, we will send you a kit in the mail, at no charge.',
  //     },
  //     {
  //       stepTitle: 'Receive Your Test Kit',
  //       stepDesc:
  //         'Once you receive your kit, follow the instructions inside and administer the test on yourself. You can also visit your local health care provider and have them conduct it for you.',
  //     },
  //     {
  //       stepTitle: 'Send Kit To Lab',
  //       stepDesc:
  //         'Place your sample back in the kit. Make sure the pre-paid sticker is placed outside of the kit, and drop it off in your mailbox for pickup.',
  //     },
  //     {
  //       stepTitle: 'Processing & Report',
  //       stepDesc:
  //         'Get results sent to you and your physician via email as well as a hard copy sent to your house.',
  //     },
  //   ],
  // },

  // PureArticle: {
  //   h2: "Pharmacogenomics Explained",
  //   sideText: ["•99% of people have a genetic variant that could impact how they respond to prescribed medications.", "•1 in 4 people are being prescribed a drug for which pharmacogenomic testing results could lead to the changing of either their current dosage or the prescription itself."],
  //   p: [
  //     "Pharmacogenomics is the study of how an individual’s genotype can influence their body’s ability to metabolize different medications and treatments. Pharmacogenomics can aid in the creation of a personalized treatment plan for various conditions. Medications for which dosage guidelines are available following pharmacogenomic testing include but are not limited to medications for cardiac health, cancer management, and treatment of psychiatric disorders.",
  //     <b>Use of pharmacogenomic testing to aid in treatment decisions:</b>,
  //     "Personalized medications and medication dosages for patients can enhance medication effectiveness and decrease adverse side effects by reducing treatment toxicity. By knowing a patient’s genotype, and the possible drug-gene interactions that can occur because of their genotype, a physician can avoid the trial-and-error approach required for so many prescriptions to be effective.",
  //     "Knowledge of an increased genetic risk enables you to work with your healthcare provider to create a personalized plan to prevent or detect cancer at an earlier and more treatable stage. It is typical to see a recurring pattern of cancer across two to three generations, with multiple individuals diagnosed with the same type of cancer(s).",
  //     <b>Pharmacogenomic testing and cancer treatment</b>,
  //     "Various factors influence the effectiveness of cancer treatment. Pharmacogenomic testing may provide relevant dosage and toxicity guidelines for patients. There are numerous chemotherapies for which pharmacogenomic guidelines are available."
  //   ]
  // }