import styled from 'styled-components';
import device from '../../../../styles/mediaSizes';

export const PageHeroProvPharmacogenomics = styled.div``;

export const PharmacogenomicsResults = styled.div`
  .rn-section-gap {
    padding-top: ${135 + 220}px !important;
    @media ${device.lg} {
      padding-top: ${135 + 185}px !important;
    }
    @media ${device.md} {
      padding-top: ${80 + 160}px !important;
    }
    @media ${device.sm} {
      padding-top: ${60 + 85}px !important;
    }
  }

  .section-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .section-col1 {
      width: 50%;
    }
    .section-col2 {
      width: 48%;
    }
  }

  .pure-article-wrapper {
    .title-wrapper {
      .regular {
        font-size: 52px;
      }
    }
    .article-wrapper {
      .article-left-col {
        width: 100%;
      }
      .article-right-col {
      }
    }
    .side-text-wrapper {
      .side-text-light {
      }
      .side-text {
      }
    }
    .bold-text {
    }
    .tail-text {
    }
    @media ${device.lg} {
    }
    @media ${device.md} {
      & {
        .title-wrapper {
          .decorated-title-wrapper {
            img {
            }
            .heading-2.decorated {
            }
          }
          .heading-2.regular {
          }
        }

        .article-wrapper {
          .article-right-col {
          }
          .article-left-col {
          }
          .side-text-wrapper {
            .side-text-light,
            .side-text {
            }
          }

          .bold-text {
          }
        }
        .tail-text {
        }
      }
    }
    @media ${device.sm} {
      & {
        .title-wrapper {
          .decorated-title-wrapper {
            img {
            }
            .heading-2.decorated {
            }
          }
          .heading-2.regular {
          }
        }

        .article-wrapper {
          .article-left-col {
          }
          .article-right-col {
          }
          .side-text-wrapper {
            .side-text-light,
            .side-text {
            }
          }
          .bold-text {
          }
        }
        .tail-text {
        }
      }
    }
  }

  .numbered-list-wrapper {
    margin-top: 25px;
    margin-bottom: 0px;
    .numbered-list-title {
      margin-bottom: 45px;
    }
    .numbered-list-intro {
    }
    .numbered-list {
      .numbered-list-item {
        &:last-child {
        }
        p {
          &:last-child {
          }
        }
        .numbered-list-item-title {
        }
        .numbered-list-item-circle {
          background-color: ${({ theme }) => theme.thirdColor};
          .numbered-list-item-number {
            color: ${({ theme }) => theme.secColor};
          }
        }
        .numbered-list-item-text {
        }
      }
    }

    @media ${device.lg} {
      & {
        .numbered-list-title {
        }
        .numbered-list-intro {
        }
        .numbered-list {
          .numbered-list-item {
            &:last-child {
              margin-bottom: 0;
            }
            p {
              &:last-child {
              }
            }
            .numbered-list-item-title {
            }
            .numbered-list-item-circle {
              width: 28px;
              height: 28px;
              max-height: 28px;
              max-width: 28px;
              min-height: 28px;
              min-width: 28px;
              margin-right: 15px;
              .numbered-list-item-number {
              }
            }
            .numbered-list-item-text {
            }
          }
        }
      }
    }
    @media ${device.md} {
      & {
        .numbered-list-title {
        }
        .numbered-list-intro {
        }
        .numbered-list {
          .numbered-list-item {
            &:last-child {
              margin-bottom: 0;
            }
            p {
              &:last-child {
              }
            }
            .numbered-list-item-title {
            }
            .numbered-list-item-circle {
              width: 25px;
              height: 25px;
              max-height: 25px;
              max-width: 25px;
              min-height: 25px;
              min-width: 25px;
              margin-right: 15px;
              .numbered-list-item-number {
                font-size: 16px;
              }
            }
            .numbered-list-item-text {
            }
          }
        }
      }
    }
    @media ${device.sm} {
      & {
        .numbered-list-title {
        }
        .numbered-list-intro {
        }
        .numbered-list {
          .numbered-list-item {
            &:last-child {
              margin-bottom: 0;
            }
            p {
              &:last-child {
              }
            }
            .numbered-list-item-title {
            }
            .numbered-list-item-circle {
              width: 21px;
              height: 21px;
              max-height: 21px;
              max-width: 21px;
              min-height: 21px;
              min-width: 21px;
              margin-right: 10px;
              .numbered-list-item-number {
                font-size: 14px;
              }
            }
            .numbered-list-item-text {
            }
          }
        }
      }
    }
  }
`;

export const MedicationBenefits = styled.div`
  .section-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .section-col1 {
      width: 45%;
    }
    .section-col2 {
      width: 45%;
    }
  }
  .section-title-wrapper {
    margin-bottom: 60px;
    .title {
      text-align: center;
    }
    .description {
      margin: 0 auto;
      text-align: center;
      width: 59%;
    }
    @media ${device.lg} {
    }
    @media ${device.md} {
      & {
        .description {
          width: 90%;
        }
      }
    }
    @media ${device.sm} {
    }
  }
  .solo-image-wrapper{
    img{
      border-radius: 9px;
    }
  }
`;
