import * as yup from 'yup';

const phoneRegExp = /^$|^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
export const contactSchema = yup.object().shape({
  referer: yup.string().required('Select an option'),
  full_name: yup
    .string()
    .required('This field is required')
    .matches(/^[aA-zZ\s]+$/, 'Only letters are allowed for this field')
    .trim(),
  email: yup
    .string()
    .trim()
    .email('Enter a valid email')
    .required('This field is required')
    .matches(/^[^<|>\v]*$/, '"<" or ">" symbols are not allowed'),
  subject: yup
    .string()
    .trim()
    .required('This field is required')
    .matches(/^[^<|>\v]*$/, '"<" or ">" symbols are not allowed'),
  message: yup
    .string()
    .trim()
    .required('This field is required')
    .matches(/^[^<|>\v]*$/, '"<" or ">" symbols are not allowed'),
  phone: yup.string().matches(phoneRegExp, 'Enter a valid phone number'),
});
