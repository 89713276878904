import styled from 'styled-components';
import device from '../../../../styles/mediaSizes';

// 1-SECTION
export const HeroBannerCancer = styled.div`
  .hero-banner-wrapper {
    .hero-left-col {
      width: 33%;
    }
    .hero-right-col {
      width: 67%;
    }
  }

  @media ${device.sm} {
    .hero-banner-wrapper {
      .hero-left-col {
        width: 100%;
      }
      .hero-right-col {
        width: 100%;
      }
    }
  }
`;

// 2-SECTION
export const ArticleVideo = styled.div`
  p {
    .text-button {
      color: white !important;
    }
  }
`;

// 3-SECTION
export const PicTextCTO = styled.div``;

// 4-SECTION
export const RiskOfCancer = styled.div``;

// 5-SECTION
export const RunInYourFamily = styled.div`
  .divider-wrapper {
    .divider-text {
      background-color: ${({ bgColor }) => bgColor};
      color: ${({ theme }) => theme.mainColor};
    }
  }
  .section-title-wrapper {
    margin-bottom: 50px;
    .heading-1 {
    }
    .heading-2 {
      font-size: 50px;
      line-height: 1.7;
      text-align: center;
      margin-bottom: 15px;
    }
    .heading-4 {
      text-align: center;
    }
    .text-block-2 {
      margin: auto;
      text-align: center;
      width: 80%;
    }

    @media ${device.lg} {
      & {
        .heading-1 {
        }
        .heading-2 {
          margin-bottom: 15px;
          font-size: 50px;
          text-align: center;
        }
        .heading-4 {
          text-align: center;
        }
        .text-block-2 {
          margin: 0 auto;
          text-align: center;
          width: 80%;
        }
      }
    }
    @media ${device.md} {
      & {
        margin-bottom: 50px;
        .heading-1 {
        }
        .heading-2 {
          font-size: 40px;
          line-height: 1.5;
        }
        .heading-4 {
        }
        .text-block-2 {
          width: 90%;
          line-height: 22px;
        }
      }
    }
    @media ${device.sm} {
      & {
        margin-bottom: 35px;
        .heading-1 {
          text-align: center;
          margin-bottom: 15px;
        }
        .heading-2 {
          font-size: 35px;
          text-align: center;
          margin-bottom: 15px;
        }
        .heading-4 {
          text-align: center;
        }
        .text-block-2 {
          margin: 0 auto;
          text-align: center;
          width: 100%;
        }
      }
    }
  }
`;

// 6-SECTION
export const PicTexHelpOfGenetics = styled.div``;

// 7-SECTION
export const BenefitsOfTesting = styled.div`
  .section-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .col-1 {
      width: 48%;
    }
    .col-2 {
      width: 45%;
      margin-top: 1em;
    }
    @media ${device.lg} {
    }
    @media ${device.md} {
    }
    @media ${device.sm} {
      & {
        .col-1,
        .col-2 {
          width: 100%;
        }
      }
    }
  }
`;

// 8-SECTION
export const HowItWorks = styled.div`
  .section-title-wrapper {
    margin-bottom: 60px;
    .title {
      text-align: center;
    }
    .description {
      margin: 0 auto;
      text-align: center;
      width: 59%;
    }
    @media ${device.lg} {
    }
    @media ${device.md} {
      & {
        .description {
          width: 90%;
        }
      }
    }
    @media ${device.sm} {
    }
  }
`;

// 9-SECTION
export const PicTextPrivacy = styled.div`
  .pic-text-wrapper {
    .pic-col {
      width: 41.6%;
      .inner-img {
        border-radius: 0;
        width: 100%;
      }
      .regular-img {
        display: inline-block;
      }
      .mobile-img {
        display: none;
      }
    }
    .text-col {
      .text-group {
        .heading-1 {
        }
        .heading-2 {
        }
        .heading-4 {
          color: #5f5a6a;
        }
      }
    }

    @media ${device.sm} {
      & {
        .pic-col {
          width: 100%;
          .inner-img {
          }
          .regular-img {
            display: inline-block;
          }
          .mobile-img {
            display: none;
          }
        }
        .text-col {
          width: 100%;
          .text-group {
            .heading-1 {
            }
            .heading-2 {
            }
            .heading-4 {
              color: #5f5a6a;
            }
          }
        }
      }
    }
  }
`;

// 10-SECTION
export const FaqArea = styled.div`
  .faq-wrapper {
    .section-title-wrapper {
      .title {
      }
    }
    .accordion {
      .accordion-item {
        .accordion-header {
          .accordion-button {
            background: ${({ bgColor }) => bgColor};
            &:after,
            &.collapsed:after {
            }
            &.collapsed:after {
              background-image: url(/images/decor/faq_open_${({ theme }) => theme.themeColor}.svg);
            }
            &:after {
              background-image: url(/images/decor/faq_close_${({ theme }) => theme.themeColor}.svg);
            }
            &:focus {
            }
            &:focus:not(.collapsed) {
            }
            &:not(.collapsed) {
              background: ${({ bgColor }) => bgColor};
              color: ${({ theme }) => theme.mainColor};
            }
          }
        }
        .accordion-body {
        }
      }
    }
  }
`;
