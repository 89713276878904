import { useState, useRef } from 'react';
import { FiMenu } from 'react-icons/fi';
import Logo from './Logo';
import Nav from './Nav';
import MobileMenu from './MobileMenu';
import useStickyHeader from './useStickyHeader';
//Styles
import { StyledHeader } from './styles/StyledHeader';
//Content

const Header = ({ HeaderSTyle, content }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const closeMobileMenu = () => {
    setShowMobileMenu(prev => !prev);
  };

  const ref = useRef();
  // let [check, setCheck] = useState(true);
  const sticky = useStickyHeader(50);
  // const headerClasses = `header-default ${sticky && check ? 'sticky' : ''}`;
  const headerClasses = `header-default ${sticky ? 'sticky' : ''}`;
  // const { clientHeight } = ref;

  // const checkChange = value => {
  //   setCheck(value);
  // };

  return (
    <StyledHeader>
      <header
        ref={ref}
        className={`rn-header header-default header-left-align ${HeaderSTyle} ${headerClasses}`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-10 col-md-6 col-4 position-static">
              <div className="header-left d-flex">
                <Logo
                  image={`${process.env.PUBLIC_URL}/images/nav_footer/log_iPathLifeSciences.png`}
                  image2={`${process.env.PUBLIC_URL}/images/nav_footer/log_iPathLifeSciences.png`}
                />
                <nav className="mainmenu-nav d-none d-lg-block">
                  <Nav content={content.nav} />
                </nav>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-8">
              <div className="header-right">
                {/* <div className='header-btn'>
                  <a className={`btn-default ${btnStyle}`} href='/genomics/cancer'>
                    GENOMIC
                  </a>
                </div> */}
                <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                  <div className="hamburger">
                    <span className="hamburger-button" onClick={closeMobileMenu}>
                      <FiMenu />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu content={content.nav} show={showMobileMenu} closeMobileMenu={closeMobileMenu} />
    </StyledHeader>
  );
};
export default Header;
