import styled from 'styled-components';
import device from '../../styles/mediaSizes';

export const StyledPolicies = styled.main`
  & {
    margin-top: 50px;
    font-weight: 500;

    & span.dot::before {
      content: '';
      background-color: rgba(45, 45, 45, 1);
      border-radius: 50%;
      height: 10px;
      width: 10px;
      display: inline-block;
      margin-right: 20px;
      vertical-align: 6%;
    }
  }

  .pb-2p0 {
    padding-bottom: 2rem !important;
  }
  .pb-xs-1p0 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-5p0 {
    padding-left: 5rem !important;
  }

  .pt-xs-2p5 {
    padding-top: 2.5rem !important;
  }

  .fw-500 {
    font-weight: 500 !important;
  }

  .fw-600 {
    font-weight: 600 !important;
  }

  .toUpperCase {
    text-transform: uppercase;
  }

  .fc-primary_medium {
    color: rgba(7, 119, 219, 1) !important;
  }

  h3 {
    font-size: 2.2rem;
  }

  h2 {
    font-size: clamp(5.2rem, 6vw, 7rem) !important;
  }

  p {
    font-weight: 500;
  }

  .text-primary {
    color: #007bff !important;
  }

  /* @media ${device.lg} {
    .col,
    .col-1,
    .col-10,
    .col-11,
    .col-12,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-lg,
    .col-lg-1,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-auto,
    .col-md,
    .col-md-1,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-auto,
    .col-sm,
    .col-sm-1,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-auto,
    .col-xl,
    .col-xl-1,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-auto {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  } */

  @media ${device.sm} {
    .pl-xl-5p0 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  }
`;
