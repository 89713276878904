import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { tppcontaent } from './Terms_and_PPContent';
import { StyledPolicies } from './styles/StyledPolicies';
import Layout from '../../partials/Layout';

const TPPolicy = () => {
  useEffect(() => {
    document.title = 'Privacy policy';
  }, []);

  return (
    <Layout>
      <StyledPolicies className="p_policy">
        <Container className="p_policy-s-1 pt-xs-2p5 pt-md-2p5 pb-xl-10">
          <Row>
            <Col className="pb-5" xs={12} xl={5}>
              <h2 className="pb-5">
                <strong>Terms and Conditions & Privacy Policy</strong>
              </h2>
              {/* <h1 className='pb-5' ><strong>Privacy Policy</strong></h1> */}
              <p className="toUpperCase">
                THIS TERMS AND CONDITIONS AND PRIVACY POLICY GOVERNS ALL ACCESS AND USE OF THIS
                WEBSITE. PLEASE READ THE FOLLOWING AGREEMENT AND INFORMATION CAREFULLY. BY ACCESSING
                OR USING THE WEBSITE, YOU AGREE TO BE BOUND BY THESE TERMS.
              </p>
              <p className="fc-primary_medium">
                <small className="fw-500">Last Revision: 20-Aug-2023</small>
              </p>
            </Col>
            <Col xs={12} xl={7}>
              {tppcontaent.map(text => {
                return (
                  <>
                    {text.title && <h3 className="pb-xs-1p0 pb-md-2p0">{text.title}</h3>}
                    {text?.paragraphs &&
                      text.paragraphs.map(p => {
                        return <p className="pb-2p0">{p}</p>;
                      })}
                  </>
                );
              })}
            </Col>
          </Row>
        </Container>
      </StyledPolicies>
    </Layout>
  );
};

export default TPPolicy;
