import React from 'react';
import Layout from '../../partials/Layout';
import { StyledFormPage } from './components/styles/StyledFormPage';
import ProviderForm from './components/ProviderForm';
import PageScrollTop from '../../utils/PageScrollTop';

const ProviderTest = () => {
  return (
    <Layout noFooter={true}>
      <PageScrollTop>
        <StyledFormPage className="container rn-section-gap">
          <div className="row no-footer align-items-center">
            <div className="col-12 col-lg-7">
              <ProviderForm />
            </div>
            <div className='col-12 col-lg-5 d-none d-lg-block'>
              <img src="/images/decor/decor-form-provider-order.svg" />
            </div>
          </div>
        </StyledFormPage>
      </PageScrollTop>
    </Layout>
  );
};

export default ProviderTest;
